import { axiosInstance } from "."; // Import the configured Axios instance

// Base URL for API calls
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';

/** 
 * Added by Dilip Yadav 15-10-2024
 * Saves new Passport details.
 * @param {Object} payload - The Passport details to be saved.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const savePassportDetails = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-passport`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error saving passport details:", error); 
        throw error; 
    }
}

/** 
 * Added by Dilip Yadav 15-10-2024
 * Fetches the list of Passport details for a specific user by ID.
 * @param {string} id - The ID of the user.
 * @returns {Promise<Object>} - The Passport details list.
 */
export const getPassportDetailsList = async (id) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-passport-data/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        console.error("Error fetching passport details list:", error); // Log the error
        throw error; // Rethrow the error
    }
}

/**
 * Added by Dilip Yadav 15-10-2024
 * Soft deletes Passport details by ID.
 * @param {string} id - The ID of the Passport to delete.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const deletePassportDetails = async (id) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/delete-passport/${id}`);
        return response.data; 
    } catch (error) {
        console.error("Error deleting passport details:", error); 
        throw error; 
    }
};

/**
 * Added by Dilip Yadav 15-10-2024
 * Updates Passport details by ID.
 * @param {string} id - The ID of the Passport to update.
 * @param {Object} payload - The updated Passport details.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const updatePassportDetails = async (id, payload) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-passport/${id}`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error updating passport details:", error);
        throw error;
    }
};
