import { axiosInstance } from "."; // Import the configured Axios instance

// Base URL for API calls
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';

/** 
 * Added by Dilip Yadav 12-10-2024
 * Saves new package details.
 * @param {Object} payload - The package details to be saved.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const savePackageDetails = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-packagedetails`, payload);
        return response.data; // Return the response data
    } catch (error) {
        console.error("Error saving package details:", error); // Log the error
        throw error; // Rethrow the error
    }
}

/** 
 * Added by Dilip Yadav 12-10-2024
 * Fetches the list of package details for a specific user by ID.
 * @param {string} id - The ID of the user.
 * @returns {Promise<Object>} - The package details list.
 */
export const getPackageDetailsList = async (id,typeOf) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-package-data/${id}/${typeOf}`);
        return response.data; // Return the response data
    } catch (error) {
        console.error("Error fetching package details list:", error); // Log the error
        throw error; // Rethrow the error
    }
}

/**
 * Added by Dilip Yadav 12-10-2024
 * Soft deletes package details by ID.
 * @param {string} id - The ID of the package to delete.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const deletePackageDetails = async (id) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/delete-packagedetails/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        console.error("Error deleting package details:", error); // Log the error
        throw error; // Rethrow the error
    }
};

/**
 * Added by Dilip Yadav 12-10-2024
 * Updates package details by ID.
 * @param {string} id - The ID of the package to update.
 * @param {Object} payload - The updated package details.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const updatePackageDetails = async (id, payload) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-packagedetails/${id}`, payload);
        return response.data; // Return the response data
    } catch (error) {
        console.error("Error updating package details:", error); // Log the error
        throw error; // Rethrow the error
    }
};
