import {
    RadioGroup,
    FormLabel,
    FormControlLabel,
    Radio,
    Box,
    Button,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormHelperText
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { saveEmbassyAgentDetails } from "../../apicalls/embassyagentDetails"; // Corrected import
import { message } from "antd";
import { useSelector } from "react-redux";

const Form = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";

    const handleFormSubmit = async (values, { resetForm }) => {
        try {
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });

            const response = await saveEmbassyAgentDetails(formData);
            if (response.success) {
                message.success(response.message);
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error("An error occurred. Please try again.");
            console.error(error);
        }
        resetForm();
    };

    const initialValues = {
        embassyAgentName: "",
        phoneNumber: "",
        email: "",
        passportNumber: "",
        passportExpDate: "",
        isActive: "Active",
        vendorId: vendorId,
        embassyId: "",
        birthDate: ""
    };

    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

    const checkoutSchema = yup.object().shape({
        embassyAgentName: yup.string().required("Embassy agent name is required"),
        email: yup.string().email("Invalid email format").required("Email is required"),
        phoneNumber: yup.string().matches(phoneRegExp, "Phone number is not valid").required("Phone number is required"),
        passportNumber: yup.string().required("Passport number is required"),
        isActive: yup.string().oneOf(['Active', 'Inactive']).required("Status is required"),
        embassyId: yup.string().required("Embassy selection is required"),
        birthDate: yup.date().required("Birth date is required").nullable(),
        passportExpDate: yup.date().required("Birth date is required").nullable(),
    });

    return (
        <Box m="20px">
            <Header title="CREATE EMBASSY AGENT" subtitle="Create a New Embassy Agent" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            {['embassyAgentName', 'phoneNumber', 'email', 'passportNumber'].map((field, index) => (
                                <TextField
                                    key={index}
                                    fullWidth
                                    variant="filled"
                                    type={field === 'email' ? "email" : "text"}
                                    label={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values[field]}
                                    name={field}
                                    error={!!touched[field] && !!errors[field]}
                                    helperText={touched[field] && errors[field]}
                                    sx={{ gridColumn: "span 2" }}
                                />
                            ))}
                        </Box>

                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Birth Date"
                                type="date"
                                margin="dense"
                                InputLabelProps={{ shrink: true }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.birthDate}
                                name="birthDate"
                                error={!!touched.birthDate && !!errors.birthDate}
                                helperText={touched.birthDate && errors.birthDate}
                                sx={{ gridColumn: "span 2" }}
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                label="Passport Exp Date"
                                type="date"
                                margin="dense"
                                InputLabelProps={{ shrink: true }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.passportExpDate}
                                name="passportExpDate"
                                error={!!touched.passportExpDate && !!errors.passportExpDate}
                                helperText={touched.passportExpDate && errors.passportExpDate}
                                sx={{ gridColumn: "span 2" }}
                            />

                            <FormControl 
                                fullWidth 
                                variant="filled" 
                                error={!!touched.embassyId && !!errors.embassyId} 
                                sx={{ gridColumn: "span 2", mb: 2 }} 
                            >
                                <InputLabel id="embassy-select-label">Select Embassy</InputLabel>
                                <Select
                                    labelId="embassy-select-label"
                                    value={values.embassyId}
                                    onChange={handleChange}
                                    name="embassyId"
                                    onBlur={handleBlur}
                                >
                                    <MenuItem value="6713c65c889408a7a9dc8bb9">Embassy A</MenuItem>
                                    <MenuItem value="6713c534889408a7a9dc8bb6">Embassy B</MenuItem>
                                    <MenuItem value="6713c534889408a7a9dc8bb7">Embassy C</MenuItem>
                                </Select>
                                {touched.embassyId && errors.embassyId && (
                                    <FormHelperText>{errors.embassyId}</FormHelperText>
                                )}
                            </FormControl>
                        </Box>

                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained">
                                Create New Embassy Agent
                            </Button>
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                onClick={() => resetForm()}
                                sx={{ marginLeft: "10px" }}
                            >
                                Reset
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default Form;
