import { useState, useEffect } from "react";
import { Routes, Route,useNavigate } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import { useSelector } from "react-redux";
/** admin section  */
import TopbarAdmin from "./scenes/adminscenes/global/Topbar";
import SidebarAdmin from "./scenes/adminscenes/global/Sidebar";
import DashboardAdmin from "./scenes/adminscenes/dashboard";
import Users from "./scenes/adminscenes/Users";
import Createuser from "./scenes/adminscenes/Createuser";
import Subscription from "./scenes/adminscenes/Subscription";
import Createsubscription from "./scenes/adminscenes/Createsubscription";
import Reports from "./scenes/adminscenes/Reports";
import Logout from "./scenes/adminscenes/Logout";

/** end section */

import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Visitor from "./scenes/Visitor";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import CreateVisitor from "./scenes/CreateVisitor";
import Createumrahpackage from "./scenes/Umrahcretepackage"
import Createumrahgroup from "./scenes/Umrahcretegroup"
import Createumrah from "./scenes/UmrahCreate"
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import Userinfo from "./scenes/Userinfo";
import Geography from "./scenes/geography";
import Companyinfo from './scenes/Companyinfo'
import Agentlist from './scenes/Agentlist'
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import CreateAgent from './scenes/CreateAgent'
import Banklist from './scenes/Banklist'
import Addbank from './scenes/Addbank'
import Countrylist from './scenes/Countrylist'
import Addcountry from './scenes/Addcountry'
import Supplierinfo from './scenes/Supplierinfo'
import Addsupplier from './scenes/Addsupplier'
import Projectsetup from './scenes/Projectsetup'
import Marketmanagement from './scenes/Marketmanagement'
import Ticketairlines from './scenes/Ticketairlines'
import Createticketairlines from './scenes/Createticketairlines'
import Ticketpurchase from './scenes/Ticketpurchase'
import Createticketpurchase from './scenes/Createticketpurchase'
import Ticketsales from './scenes/Ticketsales'
import Createticketsales from './scenes/Createticketsales'
import Passportprocessing from './scenes/Passportprocessing'
import Visaprocessing from './scenes/Visaprocessing'
import Createvisaprocessing from './scenes/Createvisaprocessing'
import Emailsms from './scenes/Emailsms'
import Hajjpreregister from './scenes/Hajjpreregister'
import Hajjmainregister from './scenes/Hajjmainregister'
import Hajjpackage from './scenes/Hajjpackage'
import Hajjgroup from './scenes/Hajjgroup'

import Hajjcreatepackage from './scenes/Hajjcretepackage'
import Hajjcreategroup from './scenes/Hajjcretegroup'
import Hajjmaincreate from './scenes/HajjCreate'
import Hajjprecreate from './scenes/HajjPreaCreate'

import Umrah from './scenes/Umrah'
import Umrahpackage from './scenes/Umrahpackage'
import Umrahgroup from './scenes/Umrahgroup'
import Student from './scenes/Student'
import Customers from './scenes/Customers'
import Embassyformlist from './scenes/Embassyformlist'
import Embassy from './scenes/Embassy'
import Paymentumrah from './scenes/Paymentumrah'
import Paymentstudent from './scenes/Paymentstudent'
import Paymentmanpower from './scenes/Paymentmanpower'
import Paymentvisa from './scenes/Paymentvisa'
import Paymentpassport from './scenes/Paymentpassport'
import Paymentagent from './scenes/Paymentagent'
import Paymentdailyaccount from './scenes/Paymentdailyaccount'
import Paymentprehaji from './scenes/Paymentprehaji'
import Paymentmainhaji from './scenes/Paymentmainhaji'
import Reportmanpowermaster from './scenes/Reportmanpowermaster'
import Reportpassport from './scenes/Reportpassport'
import Reportbankledger from './scenes/Reportbankledger'
import Reportmanpower from './scenes/Reportmanpower'
import Reportstudent from './scenes/Reportstudent'
import Reportpaymentledger from './scenes/Reportpaymentledger'
import Reportallhaji from './scenes/Reportallhaji'
import Reportpaymentledgermaster from './scenes/Reportpaymentledgermaster'
import Reportvisitor from './scenes/Reportvisitor'
import Reportticketsales from './scenes/Reportticketsales'
import Reportticketpurchase from './scenes/Reportticketpurchase'
import Reportagentpaymentledger from './scenes/Reportagentpaymentledger'
import Login from './scenes/Login'
import Passportlist from './scenes/Passportservice'
import Custmorcreate from './scenes/Customerscreate'
import Embassycrete from './scenes/Embassycreate'
import Embassyagentcreate from './scenes/Embassyagentcreate'
import Home from './scenes/Home'
import Signup from './scenes/Signup'


function App() {
  const [theme, colorMode] = useMode();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false); 
  const { user } = useSelector((state) => state.users); 
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
      if (user?.isAdmin) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } else {
      setIsAuthenticated(false);
     // navigate("/home"); // Redirect to home if no token
    }
  }, [user, navigate]);
  
  

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ProtectedRoute/>
        {!isAuthenticated ? (
            <>
              <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Home />} />
              </Routes>
            </>
          ) : (
          <div className="app">
            {isAdmin ? (
              <>
                <SidebarAdmin />
                <main className="content">
                  <TopbarAdmin />
                  <Routes>
                    <Route path="/dashboard" element={<DashboardAdmin />} />
                    <Route
                      path="/users"
                      element={
                        <ProtectedRoute>
                          <Users />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="/admin/users" element={<Users />} /> */}
                    <Route path="/createuser" element={<Createuser />} />
                    <Route path="/subscription" element={<Subscription />} />
                    <Route path="/createsubscription" element={<Createsubscription />} />
                    <Route path="/reports" element={<Reports />} />
                    <Route path="/logout" element={<Logout />} />
                  </Routes>
                </main>
              </>
            ) : (
              // Vendor Section
              <>
                <Sidebar />
                <main className="content">
                  <Topbar />
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/visitor" element={<Visitor />} />
                    <Route path="/contacts" element={<Contacts />} />
                    <Route path="/invoices" element={<Invoices />} />
                    <Route path="/createvisitor" element={<CreateVisitor />} />
                    <Route path="/createumrahpackege" element={<Createumrahpackage/>} />
                    <Route path="/createumrahgroup" element={<Createumrahgroup/>} />
                    <Route path="/createumrah" element={<Createumrah/>} />
                    <Route path="/bar" element={<Bar />} />
                    <Route path="/pie" element={<Pie />} />
                    <Route path="/line" element={<Line />} />
                    <Route path="/userinfo" element={<Userinfo />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/geography" element={<Geography />} />
                    <Route path="/companyinfo" element={<Companyinfo />} />
                    <Route path="/custmorecreate" element={<Custmorcreate/>} />
                    <Route path="/agentlist" element={<Agentlist />} />
                    <Route path="/createagent" element={<CreateAgent />} />
                    <Route path="/banklist" element={<Banklist />} />
                    <Route path="/addEmbassy" element={<Embassycrete />} />
                    <Route path="/addEmbassyAgent" element={<Embassyagentcreate />} />
                    <Route path="/addbank" element={<Addbank />} />
                    <Route path="/countrylist" element={<Countrylist />} />
                    <Route path="/addcountry" element={<Addcountry />} />
                    <Route path="/supplierinfo" element={<Supplierinfo />} />
                    <Route path="/addsupplier" element={<Addsupplier />} />
                    <Route path="/projectsetup" element={<Projectsetup />} />
                    <Route path="/marketmanagement" element={<Marketmanagement />} />
                    <Route path="/ticketairlines" element={<Ticketairlines />} />
                    <Route path="/createticketairlines" element={<Createticketairlines />} />
                    <Route path="/ticketpurchase" element={<Ticketpurchase />} />
                    <Route path="/createticketpurchase" element={<Createticketpurchase />} />
                    <Route path="/ticketsales" element={<Ticketsales />} />
                    <Route path="/createticketsales" element={<Createticketsales />} />
                    <Route path="/passportprocessing" element={<Passportprocessing />} />
                    <Route path="/passports" element={<Passportlist/>} />
                    <Route path="/visaprocessing" element={<Visaprocessing />} />
                    <Route path="/createvisaprocessing" element={<Createvisaprocessing />} />
                    <Route path="/emailsms" element={<Emailsms />} />
                    <Route path="/hajjpreregister" element={<Hajjpreregister />} />
                    <Route path="/hajjmainregister" element={<Hajjmainregister />} />
                    <Route path="/hajjpackage" element={<Hajjpackage />} />
                    <Route path="/hajjgroup" element={<Hajjgroup />} />
                    <Route path="/hajjcreatepackage" element={<Hajjcreatepackage />} />
                    <Route path="/hajjcreategroup" element={<Hajjcreategroup />} />
                    <Route path="/hajjmaincreate" element={<Hajjmaincreate />} />
                    <Route path="/hajjprecreate" element={<Hajjprecreate />} />
                    <Route path="/umrah" element={<Umrah />} />
                    <Route path="/umrahpackage" element={<Umrahpackage />} />
                    <Route path="/umrahgroup" element={<Umrahgroup />} />
                    <Route path="/student" element={<Student />} />
                    <Route path="/customers" element={<Customers />} />
                    <Route path="/embassyformlist" element={<Embassyformlist />} />
                    <Route path="/embassy" element={<Embassy />} />
                    <Route path="/paymentumrah" element={<Paymentumrah />} />
                    <Route path="/paymentstudent" element={<Paymentstudent />} />
                    <Route path="/paymentmanpower" element={<Paymentmanpower />} />
                    <Route path="/paymentvisa" element={<Paymentvisa />} />
                    <Route path="/paymentpassport" element={<Paymentpassport />} />
                    <Route path="/paymentagent" element={<Paymentagent />} />
                    <Route path="/paymentdailyaccount" element={<Paymentdailyaccount />} />
                    <Route path="/paymentprehaji" element={<Paymentprehaji />} />
                    <Route path="/paymentmainhaji" element={<Paymentmainhaji />} />
                    <Route path="/reportmanpowermaster" element={<Reportmanpowermaster />} />
                    <Route path="/reportpassport" element={<Reportpassport />} />
                    <Route path="/reportbankledger" element={<Reportbankledger />} />
                    <Route path="/reportmanpower" element={<Reportmanpower />} />
                    <Route path="/reportstudent" element={<Reportstudent />} />
                    <Route path="/reportpaymentledger" element={<Reportpaymentledger />} />
                    <Route path="/reportallhaji" element={<Reportallhaji />} />
                    <Route path="/reportpaymentledgermaster" element={<Reportpaymentledgermaster />} />
                    <Route path="/reportvisitor" element={<Reportvisitor />} />
                    <Route path="/reportticketsales" element={<Reportticketsales />} />
                    <Route path="/reportticketpurchase" element={<Reportticketpurchase />} />
                    <Route path="/reportagentpaymentledger" element={<Reportagentpaymentledger />} />
                    <Route path="/logout" element={<Logout />} />
                  </Routes>
                </main>
              </>
            )}
          </div>
        )}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;