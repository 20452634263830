import { axiosInstance } from "."; // Import the configured Axios instance

// Base URL for API calls
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';

/** 
 * Added by Dilip Yadav 17-10-2024
 * Saves new Custmore details.
 * @param {Object} payload - The Custmore details to be saved.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const saveCustmoreDetails = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-custmore`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error saving Custmore details:", error); 
        throw error; 
    }
}

/** 
 * Added by Dilip Yadav 17-10-2024
 * Fetches the list of Custmore details for a specific user by ID.
 * @param {string} id - The ID of the user.
 * @returns {Promise<Object>} - The Custmore details list.
 */
export const getCustmoreDetailsList = async (id) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-custmore-data/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        console.error("Error fetching Custmore details list:", error); // Log the error
        throw error; // Rethrow the error
    }
}

/**
 * Added by Dilip Yadav 17-10-2024
 * Soft deletes Custmore details by ID.
 * @param {string} id - The ID of the Custmore to delete.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const deleteCustmoreDetails = async (id) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/delete-custmore/${id}`);
        return response.data; 
    } catch (error) {
        console.error("Error deleting Custmore details:", error); 
        throw error; 
    }
};

/**
 * Added by Dilip Yadav 17-10-2024
 * Updates Custmore details by ID.
 * @param {string} id - The ID of the Custmore to update.
 * @param {Object} payload - The updated Custmore details.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const updateCustmoreDetails = async (id, payload) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-custmore/${id}`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error updating Custmore details:", error);
        throw error;
    }
};
