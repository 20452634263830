
import { Box, Button, Typography, useTheme, Modal, TextField, RadioGroup, FormControlLabel, Radio, Dialog,FormControl, DialogActions, DialogContent, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";

import { tokens } from "../../theme";
import Header from "../../components/Header";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { getEmbassyAgentDetailsList, deleteEmbassyAgentDetails, updateEmbassyAgentDetails } from "../../apicalls/embassyagentDetails";
import { useState, useEffect } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";
const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataCountries, setEmbassyData] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [currentEmbassy, setCurrentEmbassy] = useState(null);
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";
    const [countries, setCountries] = useState([]);

    const fetchData = async () => {
        try {
            const response = await getEmbassyAgentDetailsList(vendorId);
            setEmbassyData(response.data || []);
        } catch (error) {
            console.error("Error fetching Embassy data:", error);
            message.error("Failed to load Embassy data.");
        }
    };

    // const fetchCountries = async () => {
    //     try {
    //         const response = await getCountries(); 
    //         setCountries(response.data || []);
    //     } catch (error) {
    //         console.error("Error fetching countries:", error);
    //         message.error("Failed to load countries.");
    //     }
    // };

    useEffect(() => {
       // fetchCountries();
        fetchData();
    }, [vendorId]);

    const handleDeleteEmbassy = async (id) => {
        try {
            await deleteEmbassyAgentDetails(id);
            message.success("Embassy deleted successfully!");
            fetchData();
        } catch (error) {
            message.error("Failed to delete Embassy.");
        }
    };

    const handleUpdateEmbassy = async () => {
        try {
            await updateEmbassyAgentDetails(currentEmbassy._id, currentEmbassy);
            message.success("Embassy updated successfully!");
            fetchData();
            setOpenEditModal(false);
        } catch (error) {
            message.error("Failed to update Embassy.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentEmbassy((prev) => ({
            ...prev,
            [name]: name === "isActive" ? value === "active" : value,
        }));
    };

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Embassy Agent" subtitle="Embassy Agent List" />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                    }}
                >
                    <Link to="/addEmbassyAgent" className="text-decoration-none text-white">
                        + Add New Embassy Agent
                    </Link>
                </Button>
            </Box>

            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": { border: "none" },
                    "& .MuiDataGrid-cell": { borderBottom: "none" },
                    "& .name-column--cell": { color: colors.greenAccent[300] },
                    "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], borderBottom: "none" },
                    "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
                    "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
                    "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
                }}
            >
                <div className="table-responsive">
                    <table className="table accordion table-dark">
                        <thead>
                            <tr>
                                <th scope="col">Embassy Agent Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone Number</th>
                                <th scope="col">Country</th>
                                <th scope="col">Passport No.</th>
                                <th scope="col">Passport Exp. Date</th>
                                <th scope="col">Date of Birth</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataCountries.map((Embassy) => (
                                   <tr key={Embassy._id}>
                                    <td>{Embassy.embassyAgentName}</td>
                                    <td>{Embassy.email}</td>
                                    <td>{Embassy.phoneNumber}</td>
                                    <td>{Embassy.countryId}</td>
                                    <td>{Embassy.passportNumber}</td>
                                    <td>{new Date(Embassy.passportExpDate || Date.now()).toLocaleDateString()}</td> 
                                    <td>{new Date(Embassy.birthDate || Date.now()).toLocaleDateString()}</td> 
                                    <td>
                                        <EditIcon onClick={() => {
                                            setCurrentEmbassy(Embassy);
                                            setOpenEditModal(true);
                                        }} />
                                        <DeleteIcon onClick={() => {
                                            setCurrentEmbassy(Embassy);
                                            setOpenDeleteModal(true);
                                        }} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Box>

            {/* Edit Modal */}
            <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
                <Box sx={{ ...modalStyle }}>
                    <DialogContent>
                        <Typography variant="h6">Edit Embassy</Typography>

                        <TextField
                            label="Embassy Agent Name"
                            name="embassyAgentName"
                            value={currentEmbassy?.embassyAgentName || ""}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />

                        <TextField
                            label="Email"
                            name="email"
                            value={currentEmbassy?.email || ""}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />

                        <TextField
                            label="Phone Number"
                            name="phoneNumber"
                            value={currentEmbassy?.phoneNumber || ""}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />

                         <TextField
                            label="Passport Number"
                            name="passportNumber"
                            value={currentEmbassy?.passportNumber || ""}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />

                      
                       {/* <FormControl 
                            fullWidth 
                            variant="filled" 
                            sx={{ mb: 2 }} 
                        >
                            <InputLabel id="country-select-label">Select Country</InputLabel>
                            <Select
                                labelId="country-select-label"
                                value={currentEmbassy?.countryId || ""}
                                onChange={handleChange}
                                name="countryId"
                            >
                                {countries.map(country => (
                                    <MenuItem key={country.id} value={country.id}>
                                        {country.name}
                                    </MenuItem>
                                ))}
                            </Select>
                           
                        </FormControl> */}
                        
                        <TextField
                            label="Passport Exp Date"
                            type="date"
                            name="passportExpDate"
                            value={currentEmbassy?.passportExpDate ? new Date(currentEmbassy.passportExpDate).toISOString().split("T")[0] : ""}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                        />

                        {/* Status Radio Buttons */}
                        <Typography variant="subtitle1">Status</Typography>
                        <RadioGroup
                            row
                            name="isActive"
                            value={currentEmbassy?.isActive ? "active" : "inactive"}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="active" control={<Radio />} label="Active" />
                            <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
                        </RadioGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleUpdateEmbassy} color="primary">Save Changes</Button>
                    </DialogActions>
                </Box>
            </Modal>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <DialogContent>
                    <Typography variant="h6">Are you sure you want to delete this Embassy?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDeleteEmbassy(currentEmbassy._id)} color="primary">Yes</Button>
                    <Button onClick={() => setOpenDeleteModal(false)} color="secondary">No</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default Team;
