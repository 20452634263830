import { axiosInstance } from ".";

/*** 
 * Added by Dilip Yadav
 * Used for saving project information 
 * Date: 23-10-2024
 */
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';
export const saveProjectInfo = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-update-projectinfo`, payload);
        return response.data;
        
    } catch (error) {
        console.error("Error saving project info:", error);
        throw error; 
    }
}

/** 
 * Added by Dilip Yadav
 * Used for fetching the list of project info 
 * Date: 23-10-2024
 */
export const geprojectDetails = async (id) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-projectinfo-data/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching project details:", error);
        throw error; 
    }
}

/**
 * Added by Dilip Yadav 
 * Used for updating a project's information
 * @param {string} id - The ID of the project to update
 * @param {Object} payload - The updated project data
 * @returns {Promise<Object>}
 */
export const updateProjectInfo = async (id, payload) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-projectinfo/${id}`, payload);
        return response.data;
    } catch (error) {
        console.error("Error updating project info:", error);
        throw error; 
    }
};
