import {
    RadioGroup,
    FormLabel,
    FormControlLabel,
    Radio,
    Box,
    Button,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormHelperText
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { saveEmbassyDetails } from "../../apicalls/empbassyDetails";
import { message } from "antd";
import { useSelector,useDispatch } from "react-redux";
import { fetchCountries } from "../../redux/countrySlice"; 
import { useEffect } from "react";

const Form = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";
    const countries = useSelector((state) => state.countries || []);
    const dispatch = useDispatch();
    useEffect(() => {
      if (vendorId) {
        dispatch(fetchCountries(vendorId));
      }
     
    }, [dispatch, vendorId]);

    const handleFormSubmit = async (values, { resetForm }) => {
        try {
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });

            const response = await saveEmbassyDetails(formData);
            if (response.success) {
                message.success(response.message);
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error("An error occurred. Please try again.");
            console.log(error);
        }
        resetForm();
    };

    const initialValues = {
        embassyName: "",
        phoneNumber: "",
        email: "",
        note: "",
        isActive: "Active",
        vendorId: vendorId,
        countryId: "",
        date: ""
    };

    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

    const checkoutSchema = yup.object().shape({
        embassyName: yup.string().required("Embassy name is required"),
        email: yup.string().email("Invalid email format").required("email is required"),
        phoneNumber: yup.string().matches(phoneRegExp, "Phone number is not valid").required("Phone number is required"),
        note: yup.string().required("Note is required"),
        isActive: yup.string().oneOf(['Active', 'Inactive']).required("Status is required"),
        countryId: yup.string().required("Country selection is required"),
        date: yup.date().required("Date is required").nullable(),
    });

    return (
        <Box m="20px">
            <Header title="CREATE EMBASSY" subtitle="Create a New EMBASSY" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            {['embassyName', 'phoneNumber', 'email', 'note'].map((field, index) => (
                                <TextField
                                    key={index}
                                    fullWidth
                                    variant="filled"
                                    type={field === 'email' ? "email" : "text"}
                                    label={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values[field]}
                                    name={field}
                                    error={!!touched[field] && !!errors[field]}
                                    helperText={touched[field] && errors[field]}
                                    sx={{ gridColumn: "span 2" }}
                                />
                            ))}
                        </Box>

                       
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Date"
                                type="date"
                                margin="dense"
                                InputLabelProps={{ shrink: true }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.date}
                                name="date"
                                error={!!touched.date && !!errors.date}
                                helperText={touched.date && errors.date}
                                sx={{ gridColumn: "span 2" }}
                            />

                            <FormControl component="fieldset" sx={{ gridColumn: "span 2" }}>
                                <FormLabel component="legend">Status</FormLabel>
                                <RadioGroup row value={values.isActive} onChange={handleChange} name="isActive">
                                    <FormControlLabel value="Active" control={<Radio />} label="Active" />
                                    <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
                                </RadioGroup>
                            </FormControl>
                        </Box>


                        
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(12, minmax(0, 1fr))" // Adjusted to 12 columns
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            {/* Other TextFields... */}
                            
                            <FormControl 
                                fullWidth 
                                variant="filled" 
                                error={!!touched.countryId && !!errors.countryId} 
                                sx={{ gridColumn: "span 6", mb: 2 }} 
                            >
                                <InputLabel id="country-select-label">Select Country</InputLabel>
                                <Select
                                    labelId="country-select-label"
                                    value={values.countryId}
                                    onChange={handleChange}
                                    name="countryId"
                                    onBlur={handleBlur}
                                >
                                      {Array.isArray(countries) && countries.length > 0 ? (
                                        countries.map(country => (
                                            <MenuItem key={country._id} value={country._id}>
                                                {country.CountryName}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled>No countries available</MenuItem>
                                    )}
                                </Select>
                                {touched.countryId && errors.countryId && (
                                    <FormHelperText>{errors.countryId}</FormHelperText>
                                )}
                            </FormControl>
                            
                            {/* Other components... */}
                        </Box>


                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained">
                                Create New Embassy
                            </Button>&nbsp;&nbsp;
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                onClick={() => resetForm()}
                            >
                                Reset
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default Form;
