import { axiosInstance } from ".";

// Base URL for API calls
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';
/**
 * Added by Dilip Yadav
 * Used for saving new supplier details 
 * @param {Object} payload - The supplier details to save
 * @returns {Promise<Object>}
 */
export const saveSupplierDetails = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-supplierdetails`, payload);
        return response.data;
    } catch (error) {
        console.error("Error saving supplier details:", error);
        throw new Error("Failed to save supplier details. Please try again later.");
    }
};

/**
 * Added by Dilip Yadav
 * Used for fetching the list of supplier details 
 * @param {string} id - The ID of the supplier
 * @returns {Promise<Object>}
 */
export const getSupplierDetailsList = async (id) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-supplier-data/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching supplier details list:", error);
        throw new Error("Failed to fetch supplier details. Please try again later.");
    }
};

/**
 * Added by Dilip Yadav 
 * Used for soft deleting supplier details
 * @param {string} id - The ID of the supplier
 * @returns {Promise<Object>}
 */
export const deleteSupplierDetails = async (id) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/delete-supplierdetails/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting supplier details:", error);
        throw new Error("Failed to delete supplier details. Please try again later.");
    }
};

/**
 * Added by Dilip Yadav 
 * Used for updating supplier details
 * @param {string} id - The ID of the supplier
 * @param {Object} payload - The updated supplier details
 * @returns {Promise<Object>}
 */
export const updateSupplierDetails = async (id, payload) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-supplierdetails/${id}`, payload);
        return response.data;
    } catch (error) {
        console.error("Error updating supplier details:", error);
        throw new Error("Failed to update supplier details. Please try again later.");
    }
};
