import { createSlice } from "@reduxjs/toolkit";
import { getAgentList } from "../apicalls/agent"; 
const agentsSlice = createSlice({
    name: "Agent",
    initialState: {
        agents: [],
    },
    reducers: {
        setAgents: (state, action) => {
            state.agents = action.payload;
        },
        addAgent: (state, action) => {
            state.agents.push(action.payload);
        },
        removeAgent: (state, action) => {
            state.agents = state.agents.filter(agent => agent.id !== action.payload.id);
        },
        updateAgent: (state, action) => {
            const index = state.agents.findIndex(agent => agent.id === action.payload.id);
            if (index !== -1) {
                state.agents[index] = action.payload;
            }
        },
    },
});

export const { setAgents, addAgent, removeAgent, updateAgent } = agentsSlice.actions;

// Fetch agents action
export const fetchAgents = (vendorId) => async (dispatch) => {
    try {
        const response = await getAgentList(vendorId); 
        dispatch(setAgents(response.data));
    } catch (error) {
        console.error("Failed to fetch agents:", error);
    }
};

export default agentsSlice.reducer;
