import { createSlice } from "@reduxjs/toolkit";
import { getUmrahGroupDetailsList } from "../apicalls/umrahGroupgedetails";

const groupsSlice = createSlice({
    name: "Groups",
    initialState: {
        groups: [],  
    },
    reducers: {
        setGroups: (state, action) => {
            state.groups = action.payload;  
        },
        addPackage: (state, action) => {
            state.groups.push(action.payload); 
        },
        removePackage: (state, action) => {
            state.groups = state.groups.filter(pkg => pkg._id !== action.payload._id); 
        },
        updatePackage: (state, action) => {
            const index = state.groups.findIndex(pkg => pkg._id === action.payload._id);  
            if (index !== -1) {
                state.groups[index] = action.payload; 
            }
        },
    },
});

export const { setGroups, addPackage, removePackage, updatePackage } = groupsSlice.actions;

// Thunk to fetch groups
export const fetchGroups = (vendorId, typeOf) => async (dispatch) => {
    try {
        const response = await getUmrahGroupDetailsList(vendorId, typeOf); 
        dispatch(setGroups(response.data)); 
    } catch (error) {
        console.error("Failed to fetch groups:", error);
    }
};

export default groupsSlice.reducer;
