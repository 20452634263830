
import React, { useEffect, useState } from "react";
import { Box, Button, useTheme, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Link } from 'react-router-dom';
import { message } from "antd";
import { useSelector } from "react-redux";
import { getUmrahGroupDetailsList, deleteUmrahGroupDetails, updateUmrahGroupDetails } from "../../apicalls/umrahGroupgedetails";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataGroup, setGroupData] = useState([]);
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [groupDetails, setGroupDetails] = useState({});

    const fetchData = async () => {
        try {
            const type = "Hajj";
            const response = await getUmrahGroupDetailsList(vendorId,type);
            setGroupData(response.data || []);
        } catch (error) {
            console.error("Error fetching group data:", error);
            message.error("Failed to load group data.");
        }
    };

    const handleDelete = async () => {
        try {
            await deleteUmrahGroupDetails(selectedGroupId);
            message.success("Group deleted successfully!");
            fetchData();
            setDeleteModalOpen(false);
        } catch (error) {
            console.error("Error deleting group:", error);
            message.error("Failed to delete group.");
        }
    };

    const openDeleteConfirmation = (groupId) => {
        setSelectedGroupId(groupId);
        setDeleteModalOpen(true);
    };

    const openUpdateDialogHandler = (details) => {
        setGroupDetails(details);
        setSelectedGroupId(details._id);
        setUpdateModalOpen(true);
    };

    const handleUpdate = async () => {
        try {
            await updateUmrahGroupDetails(selectedGroupId, groupDetails);
            message.success("Group updated successfully!");
            fetchData();
            setUpdateModalOpen(false);
        } catch (error) {
            console.error("Error updating group:", error);
            message.error("Failed to update group.");
        }
    };

    useEffect(() => {
        fetchData();
    }, [vendorId]);

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="HAJJ GROUP" subtitle="Managing the Hajj Group List" />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                    }}
                >
                    <Link to="/hajjcreategroup" style={{ color: 'inherit', textDecoration: 'none' }}>
                        + Add New Hajj Group
                    </Link>
                </Button>
            </Box>

            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": { border: "none" },
                    "& .MuiDataGrid-cell": { borderBottom: "none" },
                    "& .name-column--cell": { color: colors.greenAccent[300] },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
                }}
            >
                <div className="table-responsive">
                    <table className="table accordion table-dark">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Group Name</th>
                                <th scope="col">No of Assign Main Hajji</th>
                                <th scope="col">Year</th>
                                <th scope="col">Package Type</th>
                                <th scope="col">Flight Date</th>
                                <th scope="col">Return Date</th>
                                <th scope="col">Airlines</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataGroup.length>0? dataGroup.map((group, index) => (
                                <React.Fragment key={group._id}>
                                    <tr data-bs-toggle="collapse" data-bs-target={`#r${index}`} aria-expanded="false" aria-controls={`r${index}`}>
                                        <th scope="row">+</th>
                                        <td>{group.GroupName || `Group Name ${index + 1}`}</td>
                                        <td>{group.NumberOfAssign}</td>
                                        <td>{group.Year || 2024}</td>
                                        <td>{group.PackageType || `Type ${index + 1}`}</td>
                                        <td>{new Date(group.FlightDate || Date.now()).toLocaleDateString()}</td>
                                        <td>{new Date(group.ReturnDate || Date.now()).toLocaleDateString()}</td>
                                        <td>{group.AirlinesNumber}</td>
                                    </tr>
                                    <tr className="collapse accordion-collapse" id={`r${index}`} data-bs-parent=".table">
                                        <td colSpan="8">
                                            <b>Hotel:</b> {group.HotelName}<br />
                                            <b>Package Duration:</b> Sample Duration<br />
                                            <b>Action:</b>
                                            <Button onClick={() => openUpdateDialogHandler(group)} variant="contained" color="primary" sx={{ marginRight: '10px' }}>
                                                <EditIcon /> 
                                            </Button>
                                            <Button onClick={() => openDeleteConfirmation(group._id)} variant="contained" color="secondary">
                                                <DeleteIcon /> 
                                            </Button>
                                        </td>
                                    </tr>
                                </React.Fragment>
                               )) : (
                                <tr>
                                    <td colSpan="9" className="text-center">No Hajj Group data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </Box>

            {/* Delete Confirmation Modal */}
            <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to delete this group?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} variant="contained" color="primary">Yes</Button>
                    <Button onClick={() => setDeleteModalOpen(false)} variant="outlined" color="secondary">No</Button>
                </DialogActions>
            </Dialog>

            {/* Update Group Details Modal */}
            <Dialog open={updateModalOpen} onClose={() => setUpdateModalOpen(false)}>
            <DialogTitle>Update Group Details</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Group Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={groupDetails.GroupName || ''}
                    onChange={(e) => setGroupDetails({ ...groupDetails, GroupName: e.target.value })}
                />
                <TextField
                    margin="dense"
                    label="No. of Assign Main Haji"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={groupDetails.NumberOfAssign || ''}
                    onChange={(e) => setGroupDetails({ ...groupDetails, NumberOfAssign: e.target.value })}
                />
                <TextField
                    margin="dense"
                    label="Year"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={groupDetails.Year || ''}
                    onChange={(e) => setGroupDetails({ ...groupDetails, Year: e.target.value })}
                />
                <TextField
                    margin="dense"
                    label="Package Type"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={groupDetails.PackageType || ''}
                    onChange={(e) => setGroupDetails({ ...groupDetails, PackageType: e.target.value })}
                />
                <TextField
                    margin="dense"
                    label="Flight Date"
                    type="date"
                    fullWidth
                    variant="outlined"
                    value={groupDetails.FlightDate ? new Date(groupDetails.FlightDate).toISOString().substring(0, 10) : ''}
                    onChange={(e) => setGroupDetails({ ...groupDetails, FlightDate: e.target.value })}
                />
                <TextField
                    margin="dense"
                    label="Return Date"
                    type="date"
                    fullWidth
                    variant="outlined"
                    value={groupDetails.ReturnDate ? new Date(groupDetails.ReturnDate).toISOString().substring(0, 10) : ''}
                    onChange={(e) => setGroupDetails({ ...groupDetails, ReturnDate: e.target.value })}
                />
                <TextField
                    margin="dense"
                    label="Airlines Number"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={groupDetails.AirlinesNumber || ''}
                    onChange={(e) => setGroupDetails({ ...groupDetails, AirlinesNumber: e.target.value })}
                />
                <TextField
                    margin="dense"
                    label="Hotel Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={groupDetails.HotelName || ''}
                    onChange={(e) => setGroupDetails({ ...groupDetails, HotelName: e.target.value })}
                />
                <TextField
                    margin="dense"
                    label="Package Duration"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={groupDetails.PackageDuration || ''}
                    onChange={(e) => setGroupDetails({ ...groupDetails, PackageDuration: e.target.value })}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleUpdate} variant="contained" color="primary">
                    Update
                </Button>
                <Button onClick={() => setUpdateModalOpen(false)} variant="outlined" color="secondary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>

        </Box>
    );
};

export default Team;
