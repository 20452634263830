import { Box, Button, Typography, useTheme, Modal, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import { getSupplierDetailsList, deleteSupplierDetails, updateSupplierDetails } from "../../apicalls/supplierdetails";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [dataSupplier, setSupplierData] = useState([]); 
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [currentSupplier, setCurrentSupplier] = useState(null);
    const [supplierToDelete, setSupplierToDelete] = useState(null);
    const { user } = useSelector((state) => state.users); 
    const vendorId = user ? user._id : ""; 

    const fetchData = async () => {
        try {
            const response = await getSupplierDetailsList(vendorId);
            setSupplierData(response.data || []);
        } catch (error) {
            console.error("Error fetching supplier data:", error);
            message.error("Failed to load supplier data.");
        }
    };

    useEffect(() => {
        fetchData(); 
    }, [vendorId]);

    const handleDeleteOpen = (id) => {
        setSupplierToDelete(id);
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setSupplierToDelete(null);
    };

    const handleDeleteConfirm = async () => {
        try {
            await deleteSupplierDetails(supplierToDelete);
            message.success("Supplier details deleted successfully!");
            fetchData(); 
            handleDeleteClose();
        } catch (error) {
            console.error("Error deleting supplier data:", error);
            message.error("Failed to delete supplier data.");
        }
    };

    const handleUpdateOpen = (supplier) => {
        setCurrentSupplier(supplier);
        setOpen(true);
    };

    const handleUpdateClose = () => {
        setOpen(false);
        setCurrentSupplier(null);
    };

    const handleUpdateSubmit = async () => {
        try {
            await updateSupplierDetails(currentSupplier._id, currentSupplier);
            message.success("Supplier details updated successfully!");
            fetchData();
            handleUpdateClose();
        } catch (error) {
            console.error("Error updating supplier data:", error);
            message.error("Failed to update supplier data.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentSupplier((prev) => ({ ...prev, [name]: value }));
    };

    const handleStatusChange = (e) => {
        setCurrentSupplier((prev) => ({ ...prev, isActive: e.target.value === "active" }));
    };

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Supplier Information" subtitle="Supplier List" />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                    }}
                >
                    <Link to="/addsupplier" className="text-decoration-none text-white">
                        + Add New Supplier
                    </Link>
                </Button>
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <div className="table-responsive">
                    <table className="table accordion table-dark">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Mobile</th>
                                <th scope="col">NID No.</th>
                                <th scope="col">VAT No.</th>
                                <th scope="col">Address</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataSupplier.length>0 ? dataSupplier.map((supplier) => (
                                <tr key={supplier._id}>
                                    <td>{supplier.SupplierName}</td>
                                    <td>{supplier.SupplierEmail}</td>
                                    <td>{supplier.SupplierNumber}</td>
                                    <td>{supplier.NIDNumber}</td>
                                    <td>{supplier.VATNumber}</td>
                                    <td>{supplier.Address}</td>
                                    <td>{supplier.isActive ? "Active" : "Inactive"}</td>
                                    <td>
                                        <Button onClick={() => handleUpdateOpen(supplier)} aria-label={`Edit ${supplier.SupplierName}`}>
                                            <EditIcon />
                                        </Button>
                                        <Button onClick={() => handleDeleteOpen(supplier._id)} aria-label={`Delete ${supplier.SupplierName}`}>
                                            <DeleteIcon />
                                        </Button>
                                    </td>
                                </tr>
                           )) : (
                            <tr>
                                <td colSpan="9" className="text-center">No Supplier data available</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </Box>

            {/* Update Modal */}
           {/* Update Modal */}
            <Modal open={open} onClose={handleUpdateClose}>
                <Box
                    sx={{
                        padding: 4,
                        bgcolor: "white",
                        borderRadius: 2,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '400px', 
                        boxShadow: 24, 
                    }}
                >
                    <Typography variant="h6">Edit Supplier</Typography>
                    <Box mt={2}>
                        <input
                            name="SupplierName"
                            placeholder="Supplier Name"
                            value={currentSupplier?.SupplierName || ""}
                            onChange={handleChange}
                            style={{ width: '100%', marginBottom: '10px' }}
                        />
                        <input
                            name="SupplierEmail"
                            placeholder="Supplier Email"
                            value={currentSupplier?.SupplierEmail || ""}
                            onChange={handleChange}
                            style={{ width: '100%', marginBottom: '10px' }}
                        />
                        <input
                            name="SupplierNumber"
                            placeholder="Supplier Mobile"
                            value={currentSupplier?.SupplierNumber || ""}
                            onChange={handleChange}
                            style={{ width: '100%', marginBottom: '10px' }}
                        />
                        <input
                            name="NIDNumber"
                            placeholder="NID No."
                            value={currentSupplier?.NIDNumber || ""}
                            onChange={handleChange}
                            style={{ width: '100%', marginBottom: '10px' }}
                        />
                        <input
                            name="VATNumber"
                            placeholder="VAT No."
                            value={currentSupplier?.VATNumber || ""}
                            onChange={handleChange}
                            style={{ width: '100%', marginBottom: '10px' }}
                        />
                        <input
                            name="Address"
                            placeholder="Address"
                            value={currentSupplier?.Address || ""}
                            onChange={handleChange}
                            style={{ width: '100%', marginBottom: '10px' }}
                        />

                        {/* Status Radio Buttons */}
                        <Typography variant="subtitle1">Status</Typography>
                        <RadioGroup row value={currentSupplier?.isActive ? "active" : "inactive"} onChange={handleStatusChange}>
                            <FormControlLabel value="active" control={<Radio />} label="Active" />
                            <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
                        </RadioGroup>

                        <Button variant="contained" color="primary" onClick={handleUpdateSubmit}>
                            Update
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteOpen}
                onClose={handleDeleteClose}
            >
                <DialogTitle>{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this supplier?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Team;
