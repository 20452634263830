import React, { useState, useEffect } from "react"; 
import { Box, Button, useTheme, Modal, Typography, TextField, FormControl, FormControlLabel, RadioGroup, Radio, InputLabel, Select, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import EditIcon from "@mui/icons-material/Edit"; 
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"; 
import Header from "../../components/Header";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { message } from "antd";
import { getVisitorDetailsList, deleteVisitorDetails, updateVisitorDetails } from "../../apicalls/visitor";

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [dataVisitor, setVisiterData] = useState([]);
  const { user } = useSelector((state) => state.users);
  const vendorId = user ? user._id : "";

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [selectedVisitorId, setSelectedVisitorId] = useState(null);
  const [visitorDetails, setVisitorDetails] = useState({});

  const fetchData = async () => {
    try {
      const response = await getVisitorDetailsList(vendorId);
      setVisiterData(response.data || []);
    } catch (error) {
      console.error("Error fetching visitor data:", error);
      message.error("Failed to load visitor data.");
    }
  };

  const handleDelete = async () => {
    try {
      await deleteVisitorDetails(selectedVisitorId);
      message.success("Visitor deleted successfully!");
      fetchData();
      setDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting visitor:", error);
      message.error("Failed to delete visitor.");
    }
  };

  const openDeleteConfirmation = (visitorId) => {
    setSelectedVisitorId(visitorId);
    setDeleteModalOpen(true);
  };
  const openUpdateDialogHandler = (visitor) => {
    setVisitorDetails(visitor);
    setSelectedVisitorId(visitor._id); 
    setUpdateModalOpen(true);
  };

  const handleUpdate = async () => {
    try {
      await updateVisitorDetails(selectedVisitorId, visitorDetails);
      message.success("Visitor updated successfully!");
      fetchData();
      setUpdateModalOpen(false);
    } catch (error) {
      console.error("Error updating visitor:", error);
      message.error("Failed to update visitor.");
    }
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleCloseUpdateModal = () => {
    setUpdateModalOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, [vendorId]);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="VISITORS" subtitle="Managing the Visitors" />
        <Link to="/createvisitor" style={{ textDecoration: 'none' }}>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            + Create Visitors
          </Button>
        </Link>
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <div className="table-responsive">
          <table className="table accordion table-dark">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Assign Agent</th>
                <th scope="col">Name</th>
                <th scope="col">Phone</th>
                <th scope="col">Email</th>
                <th scope="col">Address</th>
              </tr>
            </thead>
            <tbody>
              {dataVisitor.length > 0 ? dataVisitor.map((visitor, index) => (
                <React.Fragment key={visitor.id}>
                  <tr data-bs-toggle="collapse" data-bs-target={`#r${index + 1}`}>
                    <th scope="row">+</th>
                    <td>{visitor.AgentName}</td>
                    <td>{visitor.VisitorName}</td>
                    <td>{visitor.VisitorNumber}</td>
                    <td>{visitor.VisitorEmail}</td>
                    <td>{visitor.Address}</td>
                  </tr>
                  <tr className="collapse accordion-collapse" id={`r${index + 1}`} data-bs-parent=".table">
                    <td colSpan="5">
                      <b>Visiting Purpose:</b> {visitor.VisitingPurpose}<br />
                      <b>Remark:</b> {visitor.Remarks}<br />
                      <b>Status:</b>
                      <Button type="submit" color="secondary" variant="contained">
                        <b>{visitor.isActive ? "Active" : "Inactive"}</b>
                      </Button>
                      <br />
                      <b>Actions:</b>
                      <EditIcon onClick={() => openUpdateDialogHandler(visitor)} style={{ cursor: 'pointer', marginRight: 8 }} />
                      <RemoveRedEyeIcon onClick={() => openDeleteConfirmation(visitor._id)} style={{ cursor: 'pointer' }} />
                    </td>
                  </tr>
                </React.Fragment>
              )) : (
                <tr>
                    <td colSpan="9" className="text-center">No Visitor data available</td>
                </tr>
            )}
            </tbody>
          </table>
        </div>
      </Box>

      {/* Delete Confirmation Modal */}
      <Modal
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box 
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Confirm Deletion
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete this visitor? This action cannot be undone.
          </Typography>
          <Box display="flex" justifyContent="space-between" mt={3}>
            <Button onClick={handleCloseDeleteModal} color="primary">Cancel</Button>
            <Button onClick={handleDelete} color="secondary">Delete</Button>
          </Box>
        </Box>
      </Modal>

      {/* Update Visitor Modal */}
      <Modal
        open={updateModalOpen}
        onClose={handleCloseUpdateModal}
        aria-labelledby="update-modal-title"
        aria-describedby="update-modal-description"
      >
        <Box 
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="update-modal-title" variant="h6" component="h2">
            Update Visitor Details
          </Typography>
          
          <TextField
            label="Name"
            value={visitorDetails.VisitorName || ""}
            onChange={(e) => setVisitorDetails({ ...visitorDetails, VisitorName: e.target.value })}
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            label="Phone"
            value={visitorDetails.VisitorNumber || ""}
            onChange={(e) => setVisitorDetails({ ...visitorDetails, VisitorNumber: e.target.value })}
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            label="Email"
            value={visitorDetails.VisitorEmail || ""}
            onChange={(e) => setVisitorDetails({ ...visitorDetails, VisitorEmail: e.target.value })}
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            label="Address"
            value={visitorDetails.Address || ""}
            onChange={(e) => setVisitorDetails({ ...visitorDetails, Address: e.target.value })}
            fullWidth
            sx={{ mt: 2 }}
          />

          <FormControl fullWidth variant="filled" sx={{ mt: 2 }}>
            <InputLabel id="visiting-purpose-label">Visiting Purpose</InputLabel>
            <Select
              labelId="visiting-purpose-label"
              value={visitorDetails.VisitingPurpose || ""}
              onChange={(e) => setVisitorDetails({ ...visitorDetails, VisitingPurpose: e.target.value })}
              name="VisitingPurpose"
            >
              <MenuItem value="visa">Visa</MenuItem>
              <MenuItem value="embassy">Embassy</MenuItem>
              <MenuItem value="hajj">Hajj</MenuItem>
              <MenuItem value="umrah">Umrah</MenuItem>
              <MenuItem value="passport">Passport</MenuItem>
              <MenuItem value="marketing">Marketing</MenuItem>
            </Select>
          </FormControl>

          <FormControl component="fieldset" sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Status</Typography>
            <RadioGroup
              row
              value={visitorDetails.isActive ? "active" : "inactive"}
              onChange={(e) => setVisitorDetails({ ...visitorDetails, isActive: e.target.value === "active" })}
            >
              <FormControlLabel value="active" control={<Radio />} label="Active" />
              <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
            </RadioGroup>
          </FormControl>

          <Box display="flex" justifyContent="space-between" mt={3}>
            <Button onClick={handleCloseUpdateModal} color="primary">Cancel</Button>
            <Button onClick={handleUpdate} color="secondary">Update</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Team;
