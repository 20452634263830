import { Box, Button, TextField, Select, MenuItem, InputLabel, FormControl, FormHelperText } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { message } from "antd";
import { saveVisitorDetails } from "../../apicalls/visitor";
import { useSelector,useDispatch } from "react-redux";
import { fetchAgents } from "../../redux/agentsSlice"; 
import { useEffect } from "react";
const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const { user } = useSelector((state) => state.users);
  const agents = useSelector((state) => state.agents.agents || []);
  const dispatch = useDispatch();
  const vendorId = user ? user._id : "";
  useEffect(() => {
    if (vendorId) {
      dispatch(fetchAgents(vendorId));
    }
   
  }, [dispatch, vendorId]);
  const checkoutSchema = yup.object().shape({
    VisitorName: yup.string().required("required"),
    VisitorNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("required"),
    Address: yup.string().required("required"),
    VisitingPurpose: yup.string().required("required"),
    Agent_id: yup.string().required("required"),
    Remarks: yup.string().required("Remarks are required"),
    VisitorEmail: yup.string().email("Email is not valid").required("Visitor email is required"),
  });

  const initialValues = {
    VisitorName: "",
    VisitorNumber: "",
    Address: "",
    VisitingPurpose: "",
    Agent_id: "",
    Remarks: "",
    VisitorEmail: "",
    vendor_id: vendorId,
    status: "Active", 
  };
  const handleFormSubmit = async (values, { resetForm, setSubmitting }) => {
    const formData = new FormData();
    Object.keys(values).forEach(key => {
      formData.append(key, values[key]);
    });

    try {
      await saveVisitorDetails(formData);
      message.success("Visitor details saved successfully!");
      resetForm();
    } catch (error) {
      console.error("Error saving Visitor details:", error);
      message.error("Error saving Visitor details. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box m="20px">
      <Header title="CREATE VISITOR" subtitle="Create a New Visitor" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                label="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.VisitorName}
                name="VisitorName"
                error={!!touched.VisitorName && !!errors.VisitorName}
                helperText={touched.VisitorName && errors.VisitorName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Contact Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.VisitorNumber}
                name="VisitorNumber"
                error={!!touched.VisitorNumber && !!errors.VisitorNumber}
                helperText={touched.VisitorNumber && errors.VisitorNumber}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.VisitorEmail}
                name="VisitorEmail"
                error={!!touched.VisitorEmail && !!errors.VisitorEmail}
                helperText={touched.VisitorEmail && errors.VisitorEmail}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Address}
                name="Address"
                error={!!touched.Address && !!errors.Address}
                helperText={touched.Address && errors.Address}
                sx={{ gridColumn: "span 2" }}
              />
              <FormControl fullWidth variant="filled" error={!!touched.VisitingPurpose && !!errors.VisitingPurpose} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="visiting-purpose-label">Visiting Purpose</InputLabel>
                <Select
                  labelId="visiting-purpose-label"
                  value={values.VisitingPurpose}
                  onChange={handleChange}
                  name="VisitingPurpose"
                  onBlur={handleBlur}
                >
                  <MenuItem value="visa">Visa</MenuItem>
                  <MenuItem value="embassy">Embassy</MenuItem>
                  <MenuItem value="hajj">Hajj</MenuItem>
                  <MenuItem value="umrah">Umrah</MenuItem>
                  <MenuItem value="passport">Passport</MenuItem>
                  <MenuItem value="marketing">Marketing</MenuItem>
                </Select>
                {touched.VisitingPurpose && errors.VisitingPurpose && (
                  <FormHelperText>{errors.VisitingPurpose}</FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth variant="filled" error={!!touched.Agent_id && !!errors.Agent_id} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="assign-user-label">Assign Agent</InputLabel>
                <Select
                  labelId="assign-user-label"
                  value={values.Agent_id}
                  onChange={handleChange}
                  name="Agent_id"
                  onBlur={handleBlur}
                >
                 {Array.isArray(agents) && agents.length > 0 ? (
                                agents.map(agent => (
                                    <MenuItem key={agent._id} value={agent._id}>
                                        {agent.AgentName}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem disabled>No agents available</MenuItem>
                            )}
                </Select>
                {touched.Agent_id && errors.Agent_id && (
                  <FormHelperText>{errors.Agent_id}</FormHelperText>
                )}
              </FormControl>
              
              <TextField
                fullWidth
                variant="filled"
                label="Remarks"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Remarks}
                name="Remarks"
                error={!!touched.Remarks && !!errors.Remarks}
                helperText={touched.Remarks && errors.Remarks}
                multiline
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create New Visitor
              </Button>&nbsp;&nbsp;
              <Button type="button" color="primary" variant="contained" onClick={resetForm}>
                Reset
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
