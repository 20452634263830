import { axiosInstance } from "."; // Import the configured Axios instance

// Base URL for API calls
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';

/** 
 * Added by Dilip Yadav 20-10-2024
 * Saves new airlinesticketpurchase details.
 * @param {Object} payload - The airlinesticketpurchase details to be saved.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const saveairlinesticketpurchaseDetails = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-airlinesticketpurchase`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error saving airlinse ticket details:", error); 
        throw error; 
    }
}

/** 
 * Added by Dilip Yadav 20-10-2024
 * Fetches the list of airlinesticketpurchase details for a specific user by ID.
 * @param {string} id - The ID of the user.
 * @returns {Promise<Object>} - The airlinesticketpurchase details list.
 */
export const getairlinesticketpurchaseDetailsList = async (id) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-airlinesticketpurchase-data/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        console.error("Error fetching airlinse ticket details list:", error); // Log the error
        throw error; // Rethrow the error
    }
}

/**
 * Added by Dilip Yadav 20-10-2024
 * Soft deletes airlinesticketpurchase details by ID.
 * @param {string} id - The ID of the airlinesticketpurchase to delete.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const deleteairlinesticketpurchaseDetails = async (id) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/delete-airlinesticketpurchase/${id}`);
        return response.data; 
    } catch (error) {
        console.error("Error deleting airlinse ticket  details:", error); 
        throw error; 
    }
};

/**
 * Added by Dilip Yadav 20-10-2024
 * Updates airlinesticketpurchase details by ID.
 * @param {string} id - The ID of the airlinesticketpurchase to update.
 * @param {Object} payload - The updated airlinesticketpurchase details.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const updateairlinesticketpurchaseDetails = async (id, payload) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-airlinesticketpurchase/${id}`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error updating airlinse ticket details:", error);
        throw error;
    }
};
