import { axiosInstance } from "."; // Import the configured Axios instance

// Base URL for API calls
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';

/** 
 * Added by Dilip Yadav 19-10-2024
 * Saves new airlinesticket details.
 * @param {Object} payload - The airlinesticket details to be saved.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const saveairlinesticketDetails = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-airlinesticket`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error saving Embassy Agent details:", error); 
        throw error; 
    }
}

/** 
 * Added by Dilip Yadav 19-10-2024
 * Fetches the list of airlinesticket details for a specific user by ID.
 * @param {string} id - The ID of the user.
 * @returns {Promise<Object>} - The airlinesticket details list.
 */
export const getairlinesticketDetailsList = async (id) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-airlinesticket-data/${id}`);
        return response.data; 
    } catch (error) {
        console.error("Error fetching Embassy Agent details list:", error); // Log the error
        throw error; // Rethrow the error
    }
}

/**
 * Added by Dilip Yadav 19-10-2024
 * Soft deletes airlinesticket details by ID.
 * @param {string} id - The ID of the airlinesticket to delete.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const deleteairlinesticketDetails = async (id) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/delete-airlinesticket/${id}`);
        return response.data; 
    } catch (error) {
        console.error("Error deleting Embassy Agent details:", error); 
        throw error; 
    }
};

/**
 * Added by Dilip Yadav 19-10-2024
 * Updates airlinesticket details by ID.
 * @param {string} id - The ID of the airlinesticket to update.
 * @param {Object} payload - The updated airlinesticket details.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const updateairlinesticketDetails = async (id, payload) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-airlinesticket/${id}`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error updating Embassy Agent details:", error);
        throw error;
    }
};
