import {
    Box,
    Button,
    Typography,
    useTheme,
    Dialog,
    DialogActions, 
    DialogContent,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormHelperText,
    Radio, RadioGroup, FormControlLabel
} from "@mui/material";


import { tokens } from "../../theme";
import Header from "../../components/Header";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from 'antd';
import { getVisaDetailsList, deleteVisaDetails, updateVisaDetails } from "../../apicalls/visaDetails";
import { fetchCountries } from "../../redux/countrySlice"; 
import { fetchAgents } from "../../redux/agentsSlice"; 

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useSelector((state) => state.users); 
    const vendorId = user ? user._id : ""; 
    const agents = useSelector((state) => state.agents.agents || []);
    const countries = useSelector((state) => state.countries.countries || []);

    const dispatch = useDispatch();
    
    useEffect(() => {
        if (vendorId) {
            dispatch(fetchAgents(vendorId));
            dispatch(fetchCountries(vendorId));
        }
    }, [dispatch, vendorId]);

    const [visaData, setVisaData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [currentVisaId, setCurrentVisaId] = useState(null);
    const [currentVisaData, setCurrentVisaData] = useState({});

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await getVisaDetailsList(vendorId);
            if (response.success) {
                setVisaData(response.data);
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error("Failed to fetch visa data");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [vendorId]);

    const handleDelete = async (id) => {
        try {
            const response = await deleteVisaDetails(id);
            if (response.success) {
                message.success("Visa deleted successfully");
                fetchData();
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error("Failed to delete visa");
            console.error(error);
        }
    };

    const openDeleteConfirmation = (id) => {
        setCurrentVisaId(id);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setCurrentVisaId(null);
    };

    const confirmDelete = () => {
        if (currentVisaId) {
            handleDelete(currentVisaId);
            handleCloseDeleteDialog();
        }
    };

    const openUpdateDialogHandler = (visa) => {
        setCurrentVisaId(visa._id);
        setCurrentVisaData({
            ...visa,
            agent_id: visa.agent_id?._id || '', 
            country_id: visa.country_id?._id || '', 
          });
        setOpenUpdateDialog(true);
    };

    const handleCloseUpdateDialog = () => {
        setOpenUpdateDialog(false);
        setCurrentVisaData({});
    };

    const handleUpdate = async () => {
        try {
            const response = await updateVisaDetails(currentVisaId, currentVisaData);
            if (response.success) {
                message.success("Visa updated successfully");
                fetchData();
                handleCloseUpdateDialog();
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error("Failed to update visa");
            console.error(error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentVisaData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="VISA MANAGEMENT" subtitle="Visa Management List" />
                <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                    >
                        <Link to="/createvisaprocessing" className="text-decoration-none text-white">
                            + Add New Visa Management
                        </Link>
                    </Button>
                </Box>
            </Box>

            {/* Visa Data Table */}
            <Box m="40px 0 0 0" height="75vh">
                <div className="table-responsive">
                    <table className="table accordion table-dark">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Name</th>
                                <th scope="col">Agent Name</th>
                                <th scope="col">Visa Fee</th>
                                <th scope="col">Service Charge</th>
                                <th scope="col">Paid Amount</th>
                                <th scope="col">Due Amount</th>
                                <th scope="col">Visa Type</th>
                                <th scope="col">Visa for Country</th>
                            </tr>
                        </thead>
                        <tbody>
                            {visaData.length > 0 ? visaData.map((visa) => (
                                <React.Fragment key={visa._id}>
                                    <tr data-bs-toggle="collapse" data-bs-target={`#r${visa._id}`}>
                                        <th scope="row">+</th>
                                        <td>{visa.fullName}</td>
                                        <td>{visa.agent_id && visa.agent_id.AgentName ? visa.agent_id.AgentName : 'N/A'}</td>
                                        <td>{visa.visaFee}</td>
                                        <td>{visa.serviceCharge}</td>
                                        <td>{visa.paidAmount}</td>
                                        <td>{visa.dueAmount}</td>
                                        <td>{visa.visaType}</td>
                                        <td>{visa.country_id && visa.country_id.CountryName ? visa.country_id.CountryName : 'N/A'}</td>
                                    </tr>
                                    <tr className="collapse accordion-collapse" id={`r${visa._id}`} data-bs-parent=".table">
                                        <td colSpan="10">
                                            <b>Passport Number:</b> {visa.passportNumber}<br />
                                            <b>Father's Name:</b> {visa.fatherName}<br />
                                            <b>Mother's Name:</b> {visa.motherName}<br />
                                            <b>Date of Birth:</b> {new Date(visa.dateOfBirth || Date.now()).toLocaleDateString()}<br />
                                            <b>Date:</b> {new Date(visa.date || Date.now()).toLocaleDateString()}<br />
                                            <b>Issue Date:</b> {new Date(visa.issueDate || Date.now()).toLocaleDateString()}<br />
                                            <b>Expiry Date:</b> {new Date(visa.expiredDate || Date.now()).toLocaleDateString()}<br />
                                            <b>Address:</b> {visa.address}<br />
                                            <b>Remarks:</b> {visa.remarks}<br />
                                            <b>Action:</b>
                                            <Button onClick={() => openUpdateDialogHandler(visa)} color="primary">
                                                <EditIcon />
                                            </Button>
                                            <Button onClick={() => openDeleteConfirmation(visa._id)} color="secondary">
                                                <DeleteIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )) : (
                                <tr>
                                    <td colSpan="9" className="text-center">No visa data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </Box>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Delete Visa</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this visa?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
                    <Button onClick={confirmDelete} color="secondary">Delete</Button>
                </DialogActions>
            </Dialog>

           {/* Update Visa Dialog */}
<Dialog open={openUpdateDialog} onClose={handleCloseUpdateDialog}>
    <DialogTitle>Update Visa</DialogTitle>
    <DialogContent>
        <TextField
            margin="dense"
            name="fullName"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={currentVisaData.fullName || ''}
            onChange={handleInputChange}
        />
        <FormControl fullWidth variant="filled" sx={{ marginTop: 2 }}>
            <InputLabel id="agent-label">Select Agent Name</InputLabel>
            <Select
                labelId="agent-label"
                value={currentVisaData.agent_id || ''}
                onChange={(e) => setCurrentVisaData(prevData => ({ ...prevData, agent_id: e.target.value }))}
                name="agent_id"
            >
                {Array.isArray(agents) && agents.length > 0 ? (
                    agents.map(agent => (
                        <MenuItem key={agent._id} value={agent._id}>
                            {agent.AgentName}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled>No agents available</MenuItem>
                )}
            </Select>
        </FormControl>
        <FormControl fullWidth variant="filled" sx={{ marginTop: 2 }}>
            <InputLabel id="country-label">Select Country</InputLabel>
            <Select
                labelId="country-label"
                value={currentVisaData.country_id || ''}
                onChange={(e) => setCurrentVisaData(prevData => ({ ...prevData, country_id: e.target.value }))}
                name="country_id"
            >
                {Array.isArray(countries) && countries.length > 0 ? (
                    countries.map(country => (
                        <MenuItem key={country._id} value={country._id}>
                            {country.CountryName}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled>No countries available</MenuItem>
                )}
            </Select>
        </FormControl>
        <TextField
            margin="dense"
            name="visaFee"
            label="Visa Fee"
            type="number"
            fullWidth
            variant="outlined"
            value={currentVisaData.visaFee || ''}
            onChange={handleInputChange}
        />
        <TextField
            margin="dense"
            name="serviceCharge"
            label="Service Charge"
            type="number"
            fullWidth
            variant="outlined"
            value={currentVisaData.serviceCharge || ''}
            onChange={handleInputChange}
        />
        <TextField
            margin="dense"
            name="paidAmount"
            label="Paid Amount"
            type="number"
            fullWidth
            variant="outlined"
            value={currentVisaData.paidAmount || ''}
            onChange={handleInputChange}
        />
        <TextField
            margin="dense"
            name="dueAmount"
            label="Due Amount"
            type="number"
            fullWidth
            variant="outlined"
            value={currentVisaData.dueAmount || ''}
            onChange={handleInputChange}
        />
        <FormControl fullWidth variant="filled">
            <InputLabel id="visa-purpose-label">Visa Type</InputLabel>
            <Select
                labelId="visa-purpose-label"
                value={currentVisaData.visaType || ''}
                onChange={(e) => setCurrentVisaData(prevData => ({ ...prevData, visaType: e.target.value }))}
                name="visaType"
                onBlur={handleInputChange}
            >
                <MenuItem value="tourist">Tourist Visa</MenuItem>
                <MenuItem value="student">Student Visa</MenuItem>
                <MenuItem value="work">Work Visa</MenuItem>
                <MenuItem value="business">Business Visa</MenuItem>
                <MenuItem value="transit">Transit Visa</MenuItem>
            </Select>
          
        </FormControl>
        <TextField
            margin="dense"
            name="passportNumber"
            label="Passport Number"
            type="text"
            fullWidth
            variant="outlined"
            value={currentVisaData.passportNumber || ''}
            onChange={handleInputChange}
        />
        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
            <RadioGroup
                row
                name="isActive"
                value={currentVisaData.isActive ? 'active' : 'inactive'}
                onChange={(e) => setCurrentVisaData(prevData => ({ ...prevData, isActive: e.target.value === 'active' }))}
            >
                <FormControlLabel value="active" control={<Radio />} label="Active" />
                <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
            </RadioGroup>
        </FormControl>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleCloseUpdateDialog} color="primary">Cancel</Button>
        <Button onClick={handleUpdate} color="primary">Update</Button>
    </DialogActions>
</Dialog>


        </Box>
    );
};

export default Team;
