import { createSlice } from '@reduxjs/toolkit';

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: {
    transactions: [],
    totalValue: 0,
    totalUsers: 0, 
  },
  reducers: {
    addTransaction: (state, action) => {
      state.transactions.push(action.payload);
      state.totalValue += action.payload.cost;
      state.totalUsers = state.transactions.length;
    },
    resetTransactions: (state) => {
      state.transactions = [];
      state.totalValue = 0;
      state.totalUsers = 0; 
    },
  },
});

export const { addTransaction, resetTransactions } = transactionsSlice.actions;

export default transactionsSlice.reducer;
