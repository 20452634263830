import { Box, Typography, Button, useTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, FormControlLabel, Switch } from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../../components/admincomponents/Header";
import { useEffect, useState } from "react";
import { GetSubscriptionList, DeleteSubscription, UpdateSubscription } from '../../../apicalls/subscription';
import { Link, useNavigate } from 'react-router-dom'; 
import { message } from 'antd';

const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [subscriptionToDelete, setSubscriptionToDelete] = useState(null);
    const [currentSubscription, setCurrentSubscription] = useState(null);
    const navigate = useNavigate();

    const fetchSubscriptions = async () => {
        try {
            const response = await GetSubscriptionList();
            if (response.success) {
                setSubscriptions(response.data); 
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error("Failed to fetch subscription data");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (subscriptionToDelete) {
            try {
                const response = await DeleteSubscription(subscriptionToDelete);
                if (response.success) {
                    message.success("Subscription deleted successfully");
                    fetchSubscriptions(); // Refresh the list after deletion
                } else {
                    message.error(response.message);
                }
            } catch (error) {
                message.error("Failed to delete subscription");
                console.error(error);
            } finally {
                setOpenDeleteModal(false);
                setSubscriptionToDelete(null);
            }
        }
    };

    const handleEdit = (subscription) => {
        setCurrentSubscription(subscription);
        setOpenEditModal(true);
    };

    const handleUpdate = async () => {
        if (currentSubscription) {
            try {
                const response = await UpdateSubscription(currentSubscription._id,currentSubscription);
                if (response.success) {
                    message.success("Subscription updated successfully");
                    fetchSubscriptions(); // Refresh the list after updating
                } else {
                    message.error(response.message);
                }
            } catch (error) {
                message.error("Failed to update subscription");
                console.error(error);
            } finally {
                setOpenEditModal(false);
                setCurrentSubscription(null);
            }
        }
    };

    const openDeleteConfirmation = (id) => {
        setSubscriptionToDelete(id);
        setOpenDeleteModal(true);
    };

    useEffect(() => {
        fetchSubscriptions();
    }, []);

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Subscription" subtitle="Manage Your Subscriptions" />
                <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                    >
                        <Link to="/createsubscription" className="text-decoration-none text-white">
                            + Create Subscription
                        </Link>
                    </Button>
                </Box>
            </Box>

            {/* Loading state */}
            {loading ? (
                <Typography variant="h6" color={colors.grey[100]}>
                    Loading subscriptions...
                </Typography>
            ) : (
                <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap="20px"
                >
                    {subscriptions.map((subscription) => (
                        <Box
                            key={subscription._id} 
                            gridColumn="span 4"
                            backgroundColor={colors.primary[400]}
                            display="flex"
                            justifyContent="center"
                            className="p-3"
                        >
                            <Box>
                                <Header title={subscription.name} subtitle={subscription.subtitle} />
                                <Typography
                                    variant="h5"
                                    fontWeight="600"
                                    color={colors.grey[100]}
                                >
                                    {subscription.description}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    fontWeight="600"
                                    color={colors.grey[100]}
                                >
                                    Price: ${subscription.price.$numberDecimal}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    fontWeight="600"
                                    color={colors.grey[100]}
                                >
                                    Status: {subscription.isActive ? "Active" : "Inactive"}
                                </Typography>
                                
                                <Box display="flex" justifyContent="space-between" mt="10px">
                                    <Button
                                        sx={{
                                            backgroundColor: colors.blueAccent[700],
                                            color: colors.grey[100],
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            padding: "10px 20px",
                                        }}
                                        onClick={() => handleEdit(subscription)}
                                    >
                                        Edit
                                    </Button>

                                    <Button
                                        sx={{
                                            backgroundColor: colors.redAccent[700],
                                            color: colors.grey[100],
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            padding: "10px 20px",
                                        }}
                                        onClick={() => openDeleteConfirmation(subscription._id)}
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
            )}

            {/* Delete Confirmation Modal */}
            <Dialog
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
            >
                <DialogTitle>Delete Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this subscription? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteModal(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit Subscription Modal */}
            <Dialog
                open={openEditModal}
                onClose={() => setOpenEditModal(false)}
            >
                <DialogTitle>Edit Subscription</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={currentSubscription?.name || ''}
                        onChange={(e) => setCurrentSubscription({ ...currentSubscription, name: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Sub title"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={currentSubscription?.subtitle || ''}
                        onChange={(e) => setCurrentSubscription({ ...currentSubscription, subtitle: e.target.value })}
                    />

                    <TextField
                        margin="dense"
                        label="Description"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={currentSubscription?.description || ''}
                        onChange={(e) => setCurrentSubscription({ ...currentSubscription, description: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Price"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={currentSubscription?.price?.$numberDecimal || ''}
                        onChange={(e) => setCurrentSubscription({ ...currentSubscription, price: { $numberDecimal: e.target.value } })}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={currentSubscription?.isActive || false}
                                onChange={(e) => setCurrentSubscription({ ...currentSubscription, isActive: e.target.checked })}
                                color="primary"
                            />
                        }
                        label="Active"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditModal(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdate} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Dashboard;
