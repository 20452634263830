import { Box, Button, Typography, useTheme, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { message } from 'antd';
import { getPassportDetailsList, deletePassportDetails, updatePassportDetails } from "../../apicalls/passportDetails";
import { fetchCountries } from "../../redux/countrySlice"; 
import { fetchAgents } from "../../redux/agentsSlice"; 
const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useSelector((state) => state.users); 
    const vendorId = user ? user._id : ""; 
    const { agents } = useSelector((state) => state.agents || []); 
    const { countries } = useSelector((state) => state.countries || []); 
    const dispatch = useDispatch();
    useEffect(() => {
      if (vendorId) {
        dispatch(fetchAgents(vendorId));
        dispatch(fetchCountries(vendorId));
      }
     
    }, [dispatch, vendorId]);
    const [passportData, setpassportData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [currentpassportId, setCurrentpassportId] = useState(null);
    const [currentPassportData, setCurrentPassportData] = useState({});

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await getPassportDetailsList(vendorId);
            if (response.success) {
                setpassportData(response.data);
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error("Failed to fetch passport data");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [vendorId]);

    const handleDelete = async (id) => {
        try {
            const response = await deletePassportDetails(id);
            if (response.success) {
                message.success("passport deleted successfully");
                fetchData();
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error("Failed to delete passport");
            console.error(error);
        }
    };

    const openDeleteConfirmation = (id) => {
        setCurrentpassportId(id);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setCurrentpassportId(null);
    };

    const confirmDelete = () => {
        if (currentpassportId) {
            handleDelete(currentpassportId);
            handleCloseDeleteDialog();
        }
    };

    const openUpdateDialogHandler = (passport) => {
        setCurrentpassportId(passport._id);
        setCurrentPassportData({
            ...passport,
            agent_id: passport.agent_id?._id || '', 
            country_id: passport.country_id?._id || '', 
          });
        setOpenUpdateDialog(true);
    };

    const handleCloseUpdateDialog = () => {
        setOpenUpdateDialog(false);
        setCurrentPassportData({});
    };

    const handleUpdate = async () => {
        try {
            const response = await updatePassportDetails(currentpassportId, currentPassportData);
            if (response.success) {
                message.success("passport updated successfully");
                fetchData();
                handleCloseUpdateDialog();
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error("Failed to update passport");
            console.error(error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentPassportData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="PASSPORT MANAGEMENT" subtitle="Passport Management List" />
                <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                    >
                        <Link to="/passportprocessing" className="text-decoration-none text-white">
                            + Add New Passport Management
                        </Link>
                    </Button>
                </Box>
            </Box>

            {/* passport Data Table */}
            <Box m="40px 0 0 0" height="75vh">
                <div className="table-responsive">
                    <table className="table accordion table-dark">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Name</th>
                                <th scope="col">Agent Name</th>
                                <th scope="col">Passport Fee</th>
                                <th scope="col">Service Charge</th>
                                <th scope="col">Paid Amount</th>
                                <th scope="col">Due Amount</th>
                                <th scope="col">Passport Type</th>
                                <th scope="col">Passport for Country</th>
                            </tr>
                        </thead>
                        <tbody>
                            {passportData.length > 0 ? passportData.map((passport) => (
                                <React.Fragment key={passport._id}>
                                    <tr data-bs-toggle="collapse" data-bs-target={`#r${passport._id}`}>
                                        <th scope="row">+</th>
                                        <td>{passport.fullName}</td>
                                        <td>{passport.agent_id && passport.agent_id.AgentName ? passport.agent_id.AgentName : 'N/A'}</td>
                                        <td>{passport.passportFee}</td>
                                        <td>{passport.serviceCharge}</td>
                                        <td>{passport.paidAmount}</td>
                                        <td>{passport.dueAmount}</td>
                                        <td>{passport.passportType}</td>
                                        <td>{passport.country_id && passport.country_id.CountryName ? passport.country_id.CountryName : 'N/A'}</td>
                                    </tr>
                                    <tr className="collapse accordion-collapse" id={`r${passport._id}`} data-bs-parent=".table">
                                        <td colSpan="10">
                                            <b>Passport Number:</b> {passport.passportNumber}<br />
                                            <b>Father's Name:</b> {passport.fatherName}<br />
                                            <b>Mother's Name:</b> {passport.motherName}<br />
                                            <b>Date of Birth:</b> { new Date(passport.dateOfBirth || Date.now()).toLocaleDateString()}<br />
                                            <b>Date:</b> {new Date(passport.date || Date.now()).toLocaleDateString()}<br />
                                            <b>Issue Date:</b> { new Date(passport.issueDate ||  Date.now()).toLocaleDateString() }<br />
                                            <b>Expiry Date:</b> { new Date(passport.expiredDate ||  Date.now()).toLocaleDateString() } <br />
                                            <b>Present Address:</b> {passport.presentAddress}<br />
                                            <b>Permanent Address:</b> {passport.permanentAddress}<br />
                                            <b>Remarks:</b> {passport.remarks}<br />
                                            <b>Action:</b> <Button onClick={() => openUpdateDialogHandler(passport)} color="primary">
                                                <EditIcon />
                                            </Button>
                                            <Button onClick={() => openDeleteConfirmation(passport._id)} color="secondary">
                                                <DeleteIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                </React.Fragment>
                           )) : (
                            <tr>
                                <td colSpan="9" className="text-center">No Passport Management data available</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </Box>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Delete passport</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this passport?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
                    <Button onClick={confirmDelete} color="secondary">Delete</Button>
                </DialogActions>
            </Dialog>

            {/* Update passport Dialog */}
            <Dialog open={openUpdateDialog} onClose={handleCloseUpdateDialog}>
                <DialogTitle>Update passport</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        name="fullName"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={currentPassportData.fullName || ''}
                        onChange={handleInputChange}
                    />
                   <FormControl fullWidth variant="filled">
                        <InputLabel id="agent-purpose-label">Select Agent Name</InputLabel>
                        <Select
                            labelId="agent-purpose-label"
                            value={currentPassportData.agent_id || ''}
                            onChange={(e) => setCurrentPassportData(prevData => ({ ...prevData, agent_id: e.target.value }))}
                            name="agent_id"
                        >
                             {Array.isArray(agents) && agents.length > 0 ? (
                          agents.map(agent => (
                              <MenuItem key={agent._id} value={agent._id}>
                                  {agent.AgentName}
                              </MenuItem>
                          ))
                      ) : (
                          <MenuItem disabled>No agents available</MenuItem>
                      )}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        name="passportFee"
                        label="passport Fee"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={currentPassportData.passportFee || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="serviceCharge"
                        label="Service Charge"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={currentPassportData.serviceCharge || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="paidAmount"
                        label="Paid Amount"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={currentPassportData.paidAmount || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="dueAmount"
                        label="Due Amount"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={currentPassportData.dueAmount || ''}
                        onChange={handleInputChange}
                    />
                    <FormControl fullWidth variant="filled">
                            <InputLabel id="passport-purpose-label">Passport Type</InputLabel>
                            <Select
                                labelId="passport-purpose-label"
                                value={currentPassportData.passportType || ''}
                                onChange={(e) => setCurrentPassportData(prevData => ({ ...prevData, passportType: e.target.value }))}
                                name="passportType"
                            >
                                    <MenuItem value="regular">Regular Passport</MenuItem>
                                    <MenuItem value="diplomatic">Diplomatic Passport</MenuItem>
                                    <MenuItem value="official">Official Passport</MenuItem>
                                </Select>
                </FormControl>
                   <FormControl fullWidth variant="filled">
                        <InputLabel id="passport-purpose-label">Select Country Name</InputLabel>
                        <Select
                            labelId="passport-purpose-label"
                            value={currentPassportData.country_id || ''}
                            onChange={(e) => setCurrentPassportData(prevData => ({ ...prevData, country_id: e.target.value }))}
                            name="country"
                        >
                           
                             {Array.isArray(countries) && countries.length > 0 ? (
                          countries.map(country => (
                              <MenuItem key={country._id} value={country._id}>
                                 {country.CountryName}
                              </MenuItem>
                          ))
                      ) : (
                          <MenuItem disabled>No countries available</MenuItem>
                      )}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        name="passportNumber"
                        label="Passport Number"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={currentPassportData.passportNumber || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="fathersName"
                        label="Father's Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={currentPassportData.fatherName || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="mothersName"
                        label="Mother's Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={currentPassportData.motherName || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="dateOfBirth"
                        label="Date of Birth"
                        type="date"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={currentPassportData.dateOfBirth || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="date"
                        label="Date"
                        type="date"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={currentPassportData.date || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="issueDate"
                        label="Issue Date"
                        type="date"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={currentPassportData.issueDate || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="expiredDate"
                        label="Expiry Date"
                        type="date"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={currentPassportData.expiredDate || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="address"
                        label="Address"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={currentPassportData.address || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="remarks"
                        label="Remarks"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={currentPassportData.remarks || ''}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUpdateDialog} color="primary">Cancel</Button>
                    <Button onClick={handleUpdate} color="primary">Update</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Team;
