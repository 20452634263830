import { createSlice } from "@reduxjs/toolkit";
import { getairlinesticketDetailsList } from "../apicalls/airlinesticketDetails"; 
const airlineSlice = createSlice({
    name: "airlines",
    initialState: {
        airline: [],
    },
    reducers: {
        setairline: (state, action) => {
            state.airline = action.payload;
        },
        addAgent: (state, action) => {
            state.airline.push(action.payload);
        },
        removeAgent: (state, action) => {
            state.airline = state.airline.filter(agent => agent.id !== action.payload.id);
        },
        updateAgent: (state, action) => {
            const index = state.airline.findIndex(agent => agent.id === action.payload.id);
            if (index !== -1) {
                state.airline[index] = action.payload;
            }
        },
    },
});

export const { setairline, addAgent, removeAgent, updateAgent } = airlineSlice.actions;

// Fetch airline action
export const fetchairline = (vendorId) => async (dispatch) => {
    try {
        const response = await getairlinesticketDetailsList(vendorId); 
        dispatch(setairline(response.data));
    } catch (error) {
        console.error("Failed to fetch airline:", error);
    }
};

export default airlineSlice.reducer;
