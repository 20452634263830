import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { saveCompanyInfo, geCompanyDetails } from '../../apicalls/companyinfo'; 
import { useEffect, useState } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";

const Form = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [companyData, setCompanyData] = useState(null); 
    const { user } = useSelector((state) => state.users); 
    const vendorId = user ? user._id : ""; 

    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

    const checkoutSchema = yup.object().shape({
        CompanyName: yup.string().required("Required"),
        Companymail: yup.string().email("Invalid email").required("Required"),
        ContactNumber: yup
            .string()
            .matches(phoneRegExp, "Phone number is not valid")
            .required("Required"),
        Companyaddress: yup.string().required("Required"),
        Companylogo: yup.mixed().required("Required"),
    });
    const initialValues = {
        CompanyName: companyData ? companyData.CompanyName : "",
        ContactNumber: companyData ? companyData.ContactNumber : "",
        Companyaddress: companyData ? companyData.Companyaddress : "",
        Companylogo: null,
        Companymail: companyData ? companyData.Companymail : "",
        vendor_id: vendorId 
    };

    const handleFormSubmit = async (values) => {
        const formData = new FormData();
        Object.keys(values).forEach(key => {
            formData.append(key, values[key]);
        });

        try {
            if (companyData) {
                await saveCompanyInfo(formData); 
                message.success("Company info updated successfully!");
            } else {
                await saveCompanyInfo(formData); 
                message.success("Company info saved successfully!");
            }
            const updatedData = await geCompanyDetails();
            const existingCompany = updatedData.data.find(company => company.vendor_id === vendorId);
            setCompanyData(existingCompany); 
        } catch (error) {
          
        }
    };
    
    useEffect(() => {
        const fetchCompanyData = async () => {
            try {
                const data = await geCompanyDetails(vendorId);
                const existingCompany = data.data.find(company => company.vendor_id === vendorId);
                if (existingCompany) {
                    setCompanyData(existingCompany);
                }
            } catch (error) {
                console.error("Error fetching company data:", error);
            }
        };

        fetchCompanyData(); 
    }, [vendorId]);

    return (
        <Box m="20px">
            <Header title="COMPANY INFORMATION" subtitle="Company Information Page" />

            <Formik
                initialValues={initialValues} 
                validationSchema={checkoutSchema}
                onSubmit={handleFormSubmit}
                enableReinitialize 
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Company Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.CompanyName}
                                name="CompanyName"
                                error={touched.CompanyName && !!errors.CompanyName}
                                helperText={touched.CompanyName && errors.CompanyName}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="email"
                                label="Company Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.Companymail}
                                name="Companymail"
                                error={touched.Companymail && !!errors.Companymail}
                                helperText={touched.Companymail && errors.Companymail}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Contact Number"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.ContactNumber}
                                name="ContactNumber"
                                error={touched.ContactNumber && !!errors.ContactNumber}
                                helperText={touched.ContactNumber && errors.ContactNumber}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Company Address"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.Companyaddress}
                                name="Companyaddress"
                                error={touched.Companyaddress && !!errors.Companyaddress}
                                helperText={touched.Companyaddress && errors.Companyaddress}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="file"
                                onChange={(event) => {
                                    handleChange(event);
                                    values.Companylogo = event.currentTarget.files[0]; 
                                }}
                                name="Companylogo"
                                error={touched.Companylogo && !!errors.Companylogo}
                                helperText={touched.Companylogo && errors.Companylogo}
                                sx={{ gridColumn: "span 4" }}
                            />
                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained">
                                Submit
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default Form;
