import { axiosInstance } from "."; // Import the configured Axios instance

// Base URL for API calls
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';
/** 
 * Added by Dilip Yadav 12-10-2024
 * Saves new UmrahGroup details.
 * @param {Object} payload - The UmrahGroup details to be saved.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const saveUmrahGroupDetails = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-umrahgroupdetails`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error saving UmrahGroup details:", error); 
        throw error; 
    }
}

/** 
 * Added by Dilip Yadav 12-10-2024
 * Fetches the list of UmrahGroup details for a specific user by ID.
 * @param {string} id - The ID of the user.
 * @returns {Promise<Object>} - The UmrahGroup details list.
 */
export const getUmrahGroupDetailsList = async (id,typeOf) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-umrahgroup-data/${id}/${typeOf}`);
        return response.data; // Return the response data
    } catch (error) {
        console.error("Error fetching UmrahGroup details list:", error); // Log the error
        throw error; // Rethrow the error
    }
}

/**
 * Added by Dilip Yadav 12-10-2024
 * Soft deletes UmrahGroup details by ID.
 * @param {string} id - The ID of the UmrahGroup to delete.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const deleteUmrahGroupDetails = async (id) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/delete-umrahgroupdetails/${id}`);
        return response.data; 
    } catch (error) {
        console.error("Error deleting UmrahGroup details:", error); 
        throw error; 
    }
};

/**
 * Added by Dilip Yadav 12-10-2024
 * Updates UmrahGroup details by ID.
 * @param {string} id - The ID of the UmrahGroup to update.
 * @param {Object} payload - The updated UmrahGroup details.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const updateUmrahGroupDetails = async (id, payload) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-umrahgroupdetails/${id}`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error updating UmrahGroup details:", error);
        throw error;
    }
};
