import { axiosInstance } from ".";

// Base URL for API calls
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';
 
/**
 * Added by Dilip Yadav
 * Used for saving new Visitor details 
 * @param {Object} payload - The Visitor details to save
 * @returns {Promise<Object>}
 */
export const saveVisitorDetails = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-visitors-details`, payload);
        return response.data;
    } catch (error) {
        console.error("Error saving Visitor details:", error);
        throw new Error("Failed to save Visitor details. Please try again later.");
    }
};

/**
 * Added by Dilip Yadav
 * Used for fetching the list of Visitor details 
 * @param {string} id - The ID of the Visitor
 * @returns {Promise<Object>}
 */
export const getVisitorDetailsList = async (id) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-visitor-data/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Visitor details list:", error);
        throw new Error("Failed to fetch Visitor details. Please try again later.");
    }
};

/**
 * Added by Dilip Yadav 
 * Used for soft deleting Visitor details
 * @param {string} id - The ID of the Visitor
 * @returns {Promise<Object>}
 */
export const deleteVisitorDetails = async (id) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/delete-visitors-details/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting Visitor details:", error);
        throw new Error("Failed to delete Visitor details. Please try again later.");
    }
};

/**
 * Added by Dilip Yadav 
 * Used for updating Visitor details
 * @param {string} id - The ID of the Visitor
 * @param {Object} payload - The updated Visitor details
 * @returns {Promise<Object>}
 */
export const updateVisitorDetails = async (id, payload) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-visitors-details/${id}`, payload);
        return response.data;
    } catch (error) {
        console.error("Error updating Visitor details:", error);
        throw new Error("Failed to update Visitor details. Please try again later.");
    }
};
