import { RadioGroup,FormLabel, FormControlLabel, Radio,Box, Button, TextField, Select, MenuItem, InputLabel, FormControl, FormHelperText } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { saveCustmoreDetails } from "../../apicalls/customerDetails"; 
import { message } from "antd";
import { useSelector,useDispatch } from "react-redux";
import { fetchAgents } from "../../redux/agentsSlice"; 
import { fetchCountries } from "../../redux/countrySlice"; 
import { useEffect } from "react";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { user } = useSelector((state) => state.users);
  const agents = useSelector((state) => state.agents.agents || []);
  const countries = useSelector((state) => state.countries.countries || []);
  const dispatch = useDispatch();
  const vendorId = user ? user._id : "";
  useEffect(() => {
    if (vendorId) {
      dispatch(fetchAgents(vendorId));
      dispatch(fetchCountries(vendorId));
    }
   
  }, [dispatch, vendorId]);
  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      const customerData = { ...values, vendorId: vendorId };
      await saveCustmoreDetails(customerData);
      message.success("Customer details saved successfully!");
      resetForm();
    } catch (error) {
      message.error("Error saving customer details. Please try again.");
      console.error("Error saving customer details:", error);
    }
  };

  const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const checkoutSchema = yup.object().shape({
    fullName: yup.string().required("Required"),
    nidNo: yup.string().required("Required"),
    policeClearanceDate: yup.date().required("Required"),
    medicalTestDate: yup.date().required("Required"),
    medicalExpDate: yup.date().required("Required"),
    flightArrivalDateTime: yup.date().required("Required"),
    medicalReportSubmittedDate: yup.date().required("Required"),
    flightDepartureDateTime: yup.date().required("Required"),
    callingVisaExpDate: yup.date().required("Required"),
    eVisaIssueDate: yup.date().required("Required"),
    eVisaExpDate: yup.date().required("Required"),
    passportExpDate: yup.date().required("Required"),
    ticketStatus: yup.string().required("Required"),
 
    passportNumber: yup.string().required("Required"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),
    callingVisaIssueDate: yup.string().required("Required"),
    dateOfBirth: yup.date().required("Required"),
    agentId: yup.string().required("Required"),
    paymentStatus: yup.string().required("Required"),
    bmetEntryDate: yup.date().required("Required"),
    address: yup.string().required("Required"),
    payableAmount: yup.number().required("Required").positive("Must be a positive number"),
    paidAmount: yup.number().required("Required").positive("Must be a positive number"),
    countryId: yup.string().required("Required"),
    fingerprintStatus: yup.string().oneOf(['yes', 'no'], "Select a valid fingerprint status").required("Required"),
    highCommissionApproval: yup.string().oneOf(['yes', 'no'], "Select a valid high commission approval status").required("Required"),
});


  const initialValues = {
    fullName: "",
    nidNo: "",
    policeClearanceDate: "",
    medicalTestDate: "",
    phoneNumber: "",
    callingVisaIssueDate: "",
    dateOfBirth: "",
    agentId: "",
    paymentStatus: "",
    bmetEntryDate: "",
    address: "",
    payableAmount: "",
    paidAmount: "",
    countryId: "",
    medicalExpDate: "",
    flightArrivalDateTime: "",
    medicalReportSubmittedDate: "",
    flightDepartureDateTime: "",
    ticketStatus: "",
    fingerprintStatus: "",
    highCommissionApproval: "",
    passportNumber: "",
    callingVisaExpDate: "",
    eVisaIssueDate: "",
    eVisaExpDate: "",
    passportExpDate: "",
  };

  return (
    <Box m="20px">
      <Header title="CUSTOMER PROCESSING" subtitle="Create Customer" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <FormControl fullWidth variant="filled" error={!!touched.agentId && !!errors.agentId} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="agent-select-label">Select Agent</InputLabel>
                <Select
                  labelId="agent-select-label"
                  value={values.agentId}
                  onChange={handleChange}
                  name="agentId"
                  onBlur={handleBlur}
                >
                {Array.isArray(agents) && agents.length > 0 ? (
                                agents.map(agent => (
                                    <MenuItem key={agent._id} value={agent._id}>
                                        {agent.AgentName}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem disabled>No agents available</MenuItem>
                            )}
                </Select>
                {touched.agentId && errors.agentId && (
                  <FormHelperText>{errors.agentId}</FormHelperText>
                )}
              </FormControl>

              <TextField
                fullWidth
                variant="filled"
                label="Full Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fullName}
                name="fullName"
                error={!!touched.fullName && !!errors.fullName}
                helperText={touched.fullName && errors.fullName}
                sx={{ gridColumn: "span 2" }}
              />

              <FormControl fullWidth variant="filled" error={!!touched.countryId && !!errors.countryId} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="country-select-label">Select Country</InputLabel>
                <Select
                  labelId="country-select-label"
                  value={values.countryId}
                  onChange={handleChange}
                  name="countryId"
                  onBlur={handleBlur}
                >
                  {Array.isArray(countries) && countries.length > 0 ? (
                            countries.map(country => (
                                <MenuItem key={country._id} value={country._id}>
                                    {country.CountryName}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem disabled>No countries available</MenuItem>
                        )}
                </Select>
                {touched.countryId && errors.countryId && (
                  <FormHelperText>{errors.countryId}</FormHelperText>
                )}
              </FormControl>

              <TextField
                fullWidth
                variant="filled"
                label="NID No"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nidNo}
                name="nidNo"
                error={!!touched.nidNo && !!errors.nidNo}
                helperText={touched.nidNo && errors.nidNo}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Police Clearance Date"
                type="date"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.policeClearanceDate}
                name="policeClearanceDate"
                error={!!touched.policeClearanceDate && !!errors.policeClearanceDate}
                helperText={touched.policeClearanceDate && errors.policeClearanceDate}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Medical Test Date"
                type="date"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.medicalTestDate}
                name="medicalTestDate"
                error={!!touched.medicalTestDate && !!errors.medicalTestDate}
                helperText={touched.medicalTestDate && errors.medicalTestDate}
                sx={{ gridColumn: "span 2" }}
              />

            <TextField
                fullWidth
                variant="filled"
                label="Medical Exp Date"
                type="date"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.medicalExpDate}
                name="medicalExpDate"
                error={!!touched.medicalExpDate && !!errors.medicalExpDate}
                helperText={touched.medicalExpDate && errors.medicalExpDate}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phoneNumber}
                name="phoneNumber"
                error={!!touched.phoneNumber && !!errors.phoneNumber}
                helperText={touched.phoneNumber && errors.phoneNumber}
                sx={{ gridColumn: "span 2" }}
              />


              <TextField
                fullWidth
                variant="filled"
                label="Date of Birth"
                type="date"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.dateOfBirth}
                name="dateOfBirth"
                error={!!touched.dateOfBirth && !!errors.dateOfBirth}
                helperText={touched.dateOfBirth && errors.dateOfBirth}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Passport Exp Date"
                type="date"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.passportExpDate}
                name="passportExpDate"
                error={!!touched.passportExpDate && !!errors.passportExpDate}
                helperText={touched.passportExpDate && errors.passportExpDate}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Medical Report Submitted Date"
                type="date"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.medicalReportSubmittedDate}
                name="medicalReportSubmittedDate"
                error={!!touched.medicalReportSubmittedDate && !!errors.medicalReportSubmittedDate}
                helperText={touched.medicalReportSubmittedDate && errors.medicalReportSubmittedDate}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Calling Visa Issue Date"
                type="date"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.callingVisaIssueDate}
                name="callingVisaIssueDate"
                error={!!touched.callingVisaIssueDate && !!errors.callingVisaIssueDate}
                helperText={touched.callingVisaIssueDate && errors.callingVisaIssueDate}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Calling Visa Exp Date"
                type="date"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.callingVisaExpDate}
                name="callingVisaExpDate"
                error={!!touched.callingVisaExpDate && !!errors.callingVisaExpDate}
                helperText={touched.callingVisaExpDate && errors.callingVisaExpDate}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="e-Visa Issue Date"
                type="date"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.eVisaIssueDate}
                name="eVisaIssueDate"
                error={!!touched.eVisaIssueDate && !!errors.eVisaIssueDate}
                helperText={touched.eVisaIssueDate && errors.eVisaIssueDate}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="e-Visa Exp Date"
                type="date"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.eVisaExpDate}
                name="eVisaExpDate"
                error={!!touched.eVisaExpDate && !!errors.eVisaExpDate}
                helperText={touched.eVisaExpDate && errors.eVisaExpDate}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="BMET Entry Date"
                type="date"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.bmetEntryDate}
                name="bmetEntryDate"
                error={!!touched.bmetEntryDate && !!errors.bmetEntryDate}
                helperText={touched.bmetEntryDate && errors.bmetEntryDate}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Passport Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.passportNumber}
                name="passportNumber"
                error={!!touched.passportNumber && !!errors.passportNumber}
                helperText={touched.passportNumber && errors.passportNumber}
                sx={{ gridColumn: "span 2" }}
              />

            <FormControl fullWidth error={!!touched.highCommissionApproval && !!errors.highCommissionApproval} sx={{ gridColumn: "span 2" }}>
            
              <FormLabel component="legend">High Commission Approval</FormLabel>
              <RadioGroup
                row
                aria-labelledby="high-commission-approval-label"
                name="highCommissionApproval"
                value={values.highCommissionApproval}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
              {touched.highCommissionApproval && errors.highCommissionApproval && (
                <FormHelperText>{errors.highCommissionApproval}</FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth error={!!touched.fingerprintStatus && !!errors.fingerprintStatus} sx={{ gridColumn: "span 2" }}>
                <FormLabel component="legend">Finger Print Status</FormLabel>
                <RadioGroup
                row
                  aria-labelledby="fingerprint-status-label"
                  name="fingerprintStatus"
                  value={values.fingerprintStatus}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                {touched.fingerprintStatus && errors.fingerprintStatus && (
                  <FormHelperText>{errors.fingerprintStatus}</FormHelperText>
                )}
              </FormControl>



            <FormControl fullWidth variant="filled" error={!!touched.ticketStatus && !!errors.ticketStatus} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="country-select-label">Select Ticket Status</InputLabel>
                <Select
                  labelId="country-select-label"
                  value={values.ticketStatus}
                  onChange={handleChange}
                  name="ticketStatus"
                  onBlur={handleBlur}
                >
                  <MenuItem value="approved">Approved</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="canceal">Canceal</MenuItem>
                </Select>
                {touched.ticketStatus && errors.ticketStatus && (
                  <FormHelperText>{errors.ticketStatus}</FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth variant="filled" error={!!touched.paymentStatus && !!errors.paymentStatus} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="country-select-label">Select Payment Status</InputLabel>
                <Select
                  labelId="country-select-label"
                  value={values.paymentStatus}
                  onChange={handleChange}
                  name="paymentStatus"
                  onBlur={handleBlur}
                >
                  <MenuItem value="approved">Approved</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="canceal">Canceal</MenuItem>
                </Select>
                {touched.paymentStatus && errors.paymentStatus && (
                  <FormHelperText>{errors.paymentStatus}</FormHelperText>
                )}
              </FormControl>



              <TextField
                fullWidth
                variant="filled"
                label="Flight Arrival Date Time"
                type="date"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.flightArrivalDateTime}
                name="flightArrivalDateTime"
                error={!!touched.flightArrivalDateTime && !!errors.flightArrivalDateTime}
                helperText={touched.flightArrivalDateTime && errors.flightArrivalDateTime}
                sx={{ gridColumn: "span 2" }}
              />


              <TextField
                fullWidth
                variant="filled"
                label="Flight Departure Date Time"
                type="date"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.flightDepartureDateTime}
                name="flightDepartureDateTime"
                error={!!touched.flightDepartureDateTime && !!errors.flightDepartureDateTime}
                helperText={touched.flightDepartureDateTime && errors.flightDepartureDateTime}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address}
                name="address"
                error={!!touched.address && !!errors.address}
                helperText={touched.address && errors.address}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Payable Amount"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.payableAmount}
                name="payableAmount"
                error={!!touched.payableAmount && !!errors.payableAmount}
                helperText={touched.payableAmount && errors.payableAmount}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Paid Amount"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.paidAmount}
                name="paidAmount"
                error={!!touched.paidAmount && !!errors.paidAmount}
                helperText={touched.paidAmount && errors.paidAmount}
                sx={{ gridColumn: "span 2" }}
              />

            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Submit
              </Button>&nbsp;&nbsp;
              <Button type="button" color="primary" variant="contained" onClick={resetForm}>
                Reset
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
