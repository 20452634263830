import React, { useState, useEffect } from "react";
import { Box, Button, RadioGroup, Radio, FormControlLabel, FormLabel, Select, FormHelperText, MenuItem, FormControl, InputLabel, Typography, useTheme, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Link } from 'react-router-dom';
import { message } from 'antd'; 
import { useSelector, useDispatch } from "react-redux";
import { fetchPackages } from "../../redux/packegSlice"; 
import { fetchGroups } from "../../redux/groupSlice"; 
import { fetchAgents } from "../../redux/agentsSlice"; 
import { getUmrahDetailsList, deleteUmrahDetails, updateUmrahDetails } from "../../apicalls/umrahDetails";
import EditIcon from "@mui/icons-material/Edit"; 
import DeleteIcon from "@mui/icons-material/Delete"; 

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useSelector((state) => state.users); 
  const vendorId = user ? user._id : ""; 
  const [umrahData, setUmrahData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [currentUmrahId, setCurrentUmrahId] = useState(null);
  const [currentData, setCurrentData] = useState({});

  const { packages } = useSelector((state) => state.packages  || []);
  const { groups } = useSelector((state) => state.groups  || [] );
  const { agents } = useSelector((state) => state.agents  || []); 
  const dispatch = useDispatch();
  const typeOf = 'Hajjmain'; 

  useEffect(() => {
      if (vendorId) {
          dispatch(fetchPackages(vendorId, typeOf));
          dispatch(fetchGroups(vendorId, typeOf));
          dispatch(fetchAgents(vendorId));
      }
  }, [dispatch, vendorId, typeOf]); 

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getUmrahDetailsList(vendorId, "Hajjmain");
      if (response.success) {
        setUmrahData(response.data);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error("Failed to fetch umrah data");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteUmrahDetails(id);
      if (response.success) {
        message.success("Umrah deleted successfully");
        fetchData();
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error("Failed to delete umrah");
      console.error(error);
    }
  };

  const openDeleteConfirmation = (id) => {
    setCurrentUmrahId(id);
    setDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    if (currentUmrahId) {
      handleDelete(currentUmrahId);
      setDeleteModalOpen(false);
      setCurrentUmrahId(null);
    }
  };

  const openUpdateDialogHandler = (umrah) => {
    setCurrentData({
      ...umrah,
      Agent_id: umrah.Agent_id?._id || '', 
      groupId: umrah.groupId?._id || '', 
      packegeId: umrah.packegeId?._id || '', 
    });
    setUpdateModalOpen(true);
  };

  const handleUpdate = async () => {
    try {
      const response = await updateUmrahDetails(currentData._id, currentData);
      if (response.success) {
        message.success("Umrah updated successfully");
        fetchData();
        setUpdateModalOpen(false);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error("Failed to update umrah");
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="HAJJ MAIN-REGISTER" subtitle="Managing the Main-Registration List" />
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          <Link to="/hajjmaincreate" className="text-decoration-none text-white">
            + Add New Main Haji Registration
          </Link>
        </Button>
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .name-column--cell": { color: colors.greenAccent[300] },
          "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], borderBottom: "none" },
          "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
          "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
          "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
        }}
      >
        <div className="table-responsive">
          <table className="table accordion table-dark">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Full Name</th>
                <th scope="col">Agent Name</th>
                <th scope="col">Father's Name</th>
                <th scope="col">Mother's Name</th>
                <th scope="col">Mobile No.</th>
                <th scope="col">Group Name</th>
              </tr>
            </thead>
            <tbody>
              {umrahData.length > 0 ? umrahData.map((umrah, index) => (
                <React.Fragment key={index}>
                  <tr data-bs-toggle="collapse" data-bs-target={`#row${index}`}>
                    <th scope="row">+</th>
                    <td>{umrah.umrahName}</td>
                    <td>{umrah.Agent_id && umrah.Agent_id.AgentName ? umrah.Agent_id.AgentName : 'N/A'}</td>
                    <td>{umrah.fatherName}</td>
                    <td>{umrah.motherName}</td>
                    <td>{umrah.mobileNumber}</td>
                    <td>{umrah.groupId.GroupName}</td>
                  </tr>
                  <tr className="collapse accordion-collapse" id={`row${index}`} data-bs-parent=".table">
                    <td colSpan="5">
                      <b>Package:</b> {umrah.packegeId.PackageName}<br />
                      <b>Pre-Registration date:</b>  {new Date(umrah.registrationDate || Date.now()).toLocaleDateString()}<br />
                      <b>Old Charge Amount:</b> {umrah.oldChargeAmount}<br />
                      <b>New Charge Amount:</b> {umrah.newChargeAmount}<br />
                      <b>Main Registration Date:</b> {new Date(umrah.registrationDate || Date.now()).toLocaleDateString()}<br />
                      <b>Date of Birth:</b>  {new Date(umrah.dateOfBirth || Date.now()).toLocaleDateString()}<br />
                      <b>Passport No.:</b> {umrah.passportNumber}<br />
                      <b>Payable Amount:</b> {umrah.billAmount}<br />
                      <b>Paid Amount:</b> {umrah.paidAmount}<br />
                      <b>Payment Date:</b>  {new Date(umrah.paymentDate || Date.now()).toLocaleDateString()}<br />
                      <b>Remarks:</b> {umrah.remark}<br />
                      <b>isActive:</b> {umrah.isActive}<br />
                      <b>Action:</b><br />
                      <Button onClick={() => openUpdateDialogHandler(umrah)} variant="contained" color="primary" sx={{ marginRight: '10px' }}>
                        <EditIcon />
                      </Button>
                      <Button onClick={() => openDeleteConfirmation(umrah._id)} variant="contained" color="secondary">
                        <DeleteIcon />
                      </Button>
                    </td>
                  </tr>
                </React.Fragment>
              )) : (
                <tr>
                    <td colSpan="9" className="text-center">No  Main-Registration data available</td>
                </tr>
            )}
            </tbody>
          </table>
        </div>
      </Box>

      {/* Delete Confirmation Modal */}
      <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this hajj main-register?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmDelete} variant="contained" color="primary">Yes</Button>
          <Button onClick={() => setDeleteModalOpen(false)} variant="outlined" color="secondary">No</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={updateModalOpen} onClose={() => setUpdateModalOpen(false)}>
        <DialogTitle>Update Group Details</DialogTitle>
        <DialogContent>
        {Object.keys(currentData).length > 0 && (
          <>
            <TextField
              autoFocus
              margin="dense"
              label="Full Name"
              type="text"
              fullWidth
              variant="outlined"
              value={currentData.umrahName || ''}
              onChange={(e) => setCurrentData({ ...currentData, umrahName: e.target.value })}
            />
          <FormControl fullWidth variant="filled">
              <InputLabel id="package-label">Select Agent Name</InputLabel>
              <Select
                labelId="package-label"
                value={currentData.Agent_id || ''}
                onChange={(e) => setCurrentData({ ...currentData, Agent_id: e.target.value })}
                name="packegeId"
              >
                {Array.isArray(agents) && agents.length > 0 ? (
                          agents.map(agent => (
                              <MenuItem key={agent._id} value={agent._id}>
                                  {agent.AgentName}
                              </MenuItem>
                          ))
                      ) : (
                          <MenuItem disabled>No agents available</MenuItem>
                      )}
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              label="Father's Name"
              type="text"
              fullWidth
              variant="outlined"
              value={currentData.fatherName || ''}
              onChange={(e) => setCurrentData({ ...currentData, fatherName: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Mother's Name"
              type="text"
              fullWidth
              variant="outlined"
              value={currentData.motherName || ''}
              onChange={(e) => setCurrentData({ ...currentData, motherName: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Mobile No."
              type="text"
              fullWidth
              variant="outlined"
              value={currentData.mobileNumber || ''}
              onChange={(e) => setCurrentData({ ...currentData, mobileNumber: e.target.value })}
            />
            <FormControl fullWidth variant="filled">
              <InputLabel id="groupId-label">Group Type</InputLabel>
              <Select
                labelId="groupId-label"
                value={currentData.groupId || ''}
                onChange={(e) => setCurrentData({ ...currentData, groupId: e.target.value })}
                name="groupId"
              >

                {Array.isArray(groups) && groups.length > 0 ? (
                        groups.map(group => (
                            <MenuItem key={group._id} value={group._id}>
                                  {group.GroupName} 
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>No group available</MenuItem>
                    )}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="filled">
              <InputLabel id="package-label">Type of Package</InputLabel>
              <Select
                labelId="package-label"
                value={currentData.packegeId || ''}
                onChange={(e) => setCurrentData({ ...currentData, packegeId: e.target.value })}
                name="packegeId"
              >
                 {Array.isArray(packages) && packages.length > 0 ? (
                        packages.map(packege => (
                            <MenuItem key={packege._id} value={packege._id}>
                                 {packege.PackageName} 
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>No packege available</MenuItem>
                    )}
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              label="Registration Date"
              type="date"
              fullWidth
              variant="outlined"
              value={currentData.registrationDate ? new Date(currentData.registrationDate).toISOString().substring(0, 10) : ''}
              onChange={(e) => setCurrentData({ ...currentData, registrationDate: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Date of Birth"
              type="date"
              fullWidth
              variant="outlined"
              value={currentData.dateOfBirth ? new Date(currentData.dateOfBirth).toISOString().substring(0, 10) : ''}
              onChange={(e) => setCurrentData({ ...currentData, dateOfBirth: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Passport No."
              type="text"
              fullWidth
              variant="outlined"
              value={currentData.passportNumber || ''}
              onChange={(e) => setCurrentData({ ...currentData, passportNumber: e.target.value })}
            />
             <TextField
              margin="dense"
              label="Old Charge Amount"
              type="number"
              fullWidth
              variant="outlined"
              value={currentData.oldChargeAmount || ''}
              onChange={(e) => setCurrentData({ ...currentData, oldChargeAmount: e.target.value })}
            />
             <TextField
              margin="dense"
              label="New Charge Amount"
              type="number"
              fullWidth
              variant="outlined"
              value={currentData.newChargeAmount || ''}
              onChange={(e) => setCurrentData({ ...currentData, newChargeAmount: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Payable Amount"
              type="number"
              fullWidth
              variant="outlined"
              value={currentData.billAmount || ''}
              onChange={(e) => setCurrentData({ ...currentData, billAmount: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Paid Amount"
              type="number"
              fullWidth
              variant="outlined"
              value={currentData.paidAmount || ''}
              onChange={(e) => setCurrentData({ ...currentData, paidAmount: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Payment Date"
              type="date"
              fullWidth
              variant="outlined"
              value={currentData.paymentDate ? new Date(currentData.paymentDate).toISOString().substring(0, 10) : ''}
              onChange={(e) => setCurrentData({ ...currentData, paymentDate: e.target.value })}
            />

            {/* isActive Radio Buttons */}
            <FormControl component="fieldset" fullWidth variant="outlined">
              <FormLabel component="legend">Status</FormLabel>
              <RadioGroup
                row
                value={currentData.isActive || ''}
                onChange={(e) => setCurrentData({ ...currentData, isActive: e.target.value })}
              >
                <FormControlLabel value="Active" control={<Radio />} label="Active" />
                <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
                <FormControlLabel value="Pending" control={<Radio />} label="Pending" />
              </RadioGroup>
            </FormControl>

            <TextField
              margin="dense"
              label="Remarks"
              type="text"
              fullWidth
              variant="outlined"
              value={currentData.remark || ''}
              onChange={(e) => setCurrentData({ ...currentData, remark: e.target.value })}
            />
          </>
        )}
      </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdate} variant="contained" color="primary">Update</Button>
          <Button onClick={() => setUpdateModalOpen(false)} variant="outlined" color="secondary">Cancel</Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
};

export default Team;
