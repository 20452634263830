import { message } from "antd";
import React, { useEffect } from "react";
import { GetCurrentUser } from "../apicalls/users";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Setuser } from "../redux/usersSlice";
import { HidenLoading, ShowLoading } from "../redux/loadersSlice";

function ProtectedRoute({ children, allowedRoles }) {
  const { user } = useSelector((state) => state.users);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Fetch the current user based on the token
  const getCurrentUser = async () => {
    try {
      dispatch(ShowLoading());
      const response = await GetCurrentUser();
      if (response.success) {
        dispatch(Setuser(response.data));
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(Setuser(null));
      message.error(error.message);
      localStorage.removeItem("token"); // Clear token if the user is invalid
      navigate("/login"); // Redirect to login page if user is not authenticated
    } finally {
      dispatch(HidenLoading());
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const pathname = window.location.pathname;

    // Allow access to login and signup pages
    if (!token && (pathname === "/login" || pathname === "/signup")) {
      return; // Do nothing, let the user stay on /login or /signup
    }

    // If the token exists, fetch user data
    if (token) {
      getCurrentUser();
    } else {
      // If no token is found, redirect to /home (except when on /login or /signup)
      if (pathname !== "/login" && pathname !== "/signup") {
        navigate("/home"); // Redirect to home if not logged in and trying to access other routes
      }
    }
  }, [navigate, dispatch]);

  // If the user data is not fetched yet, you can show a loading spinner or null
  if (!user) {
    return null; // Or show a loading spinner here while fetching the user data
  }

  // You can also add role-based access here (optional)
  // if (!allowedRoles.includes(user.isAdmin)) {
  //   navigate("/unauthorized");
  //   return null;
  // }

  return children; // Render the protected content if the user is authenticated
}

export default ProtectedRoute;

