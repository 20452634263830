import React from 'react'; // Ensure React is in scope for JSX
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { message } from "antd";
import { savePackageDetails } from "../../apicalls/packegedetails"; 
import { useSelector } from "react-redux";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { user } = useSelector((state) => state.users);
  const vendorId = user ? user._id : ""; 

  const checkoutSchema = yup.object().shape({
    PackageName: yup.string().required("Package name is required"),
    PackageDate: yup.date().required("Package date is required").nullable(),
    PackageAmount: yup.number().positive("Amount must be a positive number").required("Package amount is required"),
    PackagePurpose: yup.string().required("Package purpose is required"),
    Remarks: yup.string().required("Remarks are required"),
    PackageDuration: yup.number().positive("Duration must be a positive number").required("Package duration is required"),
    PackageYear: yup.string().required("Package year is required").matches(/^\d{4}$/, "Year must be a 4-digit number"),
  });

  const initialValues = {
    PackageName: "",
    PackageDate: null,
    PackageAmount: "",
    PackagePurpose: "", 
    TypeOf: "Umrah", 
    Remarks: "",
    PackageYear: "",
    PackageDuration: "",
    vendor_id: vendorId,
    isActive: "Active",
  };

  const handleFormSubmit = async (values, { resetForm, setSubmitting }) => {
    const formData = new FormData();
    Object.keys(values).forEach(key => {
      formData.append(key, values[key]);
    });

    try {
      await savePackageDetails(formData);
      message.success("Package details saved successfully!");
      resetForm();
    } catch (err) { 
      console.error("Error saving package details:", err); 
      message.error("Error saving package details. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 5 }, (_, i) => currentYear + i);

  return (
    <Box m="20px">
      <Header title="CREATE PACKAGE" subtitle="Create a New Package" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                label="Package Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.PackageName}
                name="PackageName"
                error={!!touched.PackageName && !!errors.PackageName}
                helperText={touched.PackageName && errors.PackageName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="date"
                onBlur={handleBlur}
                onChange={(e) => {
                  const dateValue = new Date(e.target.value); // Convert to Date object
                  setFieldValue('PackageDate', dateValue);
                }}
                value={values.PackageDate ? values.PackageDate.toISOString().split('T')[0] : ''}
                name="PackageDate"
                error={!!touched.PackageDate && !!errors.PackageDate}
                helperText={touched.PackageDate && errors.PackageDate}
                sx={{ gridColumn: "span 2" }}
              />

              <FormControl fullWidth variant="filled" error={!!touched.PackageYear && !!errors.PackageYear} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="package-year-label">Package Year</InputLabel>
                <Select
                  labelId="package-year-label"
                  value={values.PackageYear}
                  onChange={handleChange}
                  name="PackageYear"
                  onBlur={handleBlur}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
                {touched.PackageYear && errors.PackageYear && (
                  <FormHelperText>{errors.PackageYear}</FormHelperText>
                )}
              </FormControl>

              <TextField
                fullWidth
                variant="filled"
                label="Package Amount"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.PackageAmount}
                name="PackageAmount"
                error={!!touched.PackageAmount && !!errors.PackageAmount}
                helperText={touched.PackageAmount && errors.PackageAmount}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Package Duration"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.PackageDuration}
                name="PackageDuration"
                error={!!touched.PackageDuration && !!errors.PackageDuration}
                helperText={touched.PackageDuration && errors.PackageDuration}
                sx={{ gridColumn: "span 2" }}
              />

              <FormControl fullWidth variant="filled" error={!!touched.PackagePurpose && !!errors.PackagePurpose} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="visiting-purpose-label">Type of Package</InputLabel>
                <Select
                  labelId="visiting-purpose-label"
                  value={values.PackagePurpose}
                  onChange={handleChange}
                  name="PackagePurpose"
                  onBlur={handleBlur}
                >
                  <MenuItem value="visa">Visa</MenuItem>
                  <MenuItem value="embassy">Embassy</MenuItem>
                  <MenuItem value="hajj">Hajj</MenuItem>
                  <MenuItem value="umrah">Umrah</MenuItem>
                  <MenuItem value="passport">Passport</MenuItem>
                  <MenuItem value="marketing">Marketing</MenuItem>
                </Select>
                {touched.PackagePurpose && errors.PackagePurpose && (
                  <FormHelperText>{errors.PackagePurpose}</FormHelperText>
                )}
              </FormControl>

              <TextField
                fullWidth
                variant="filled"
                label="Remarks"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Remarks}
                name="Remarks"
                error={!!touched.Remarks && !!errors.Remarks}
                helperText={touched.Remarks && errors.Remarks}
                multiline
                sx={{ gridColumn: "span 4" }}
              />
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create New Package
              </Button>
              &nbsp;&nbsp;
              <Button type="button" color="primary" variant="contained" onClick={resetForm}>
                Reset
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
