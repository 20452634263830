const { axiosInstance } = require(".");

// Register user
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';
export const Subscription= async (payload)=>{
    try {
 const response= await axiosInstance.post(`${BASE_URL}/save-subscription`,payload)
  return response.data;
    } catch (error){
         console.log(error)
    }
}

//get  subscription data -06-10-2024
export const GetSubscriptionList= async () => {
  try {
      const response = await axiosInstance.get(`${BASE_URL}/get-subscription-data`);
      return response.data;
  } catch (error) {
      return error;
  }
}

export const DeleteSubscription = async (id) => {
  try {
      const response = await axiosInstance.delete(`${BASE_URL}/delete-subscription/${id}`);
      return response.data;
  } catch (error) {
      return error.response; // Returns the error response if the request fails
  }
};

// Update subscription
export const UpdateSubscription = async (id, payload) => {
  try {
      const response = await axiosInstance.put(`${BASE_URL}/update-subscription/${id}`, payload);
      return response.data;
  } catch (error) {
      return error.response; // Returns the error response if the request fails
  }
};


/*** Change status  */

export const changeSubscriptionStatus = async (id, isActive) => {
  try {
      const response = await axiosInstance.patch(`${BASE_URL}/change-status/${id}`, { isActive });
      return response.data;
  } catch (error) {
      return error.response;
  }
};
