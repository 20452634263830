import { createSlice } from "@reduxjs/toolkit";
import { getPackageDetailsList } from "../apicalls/packegedetails";
const packagesSlice = createSlice({
    name: "Packages",
    initialState: {
        packages: [],  
    },
    reducers: {
        setPackages: (state, action) => {
            state.packages = action.payload;  
        },
        addPackage: (state, action) => {
            state.packages.push(action.payload); 
        },
        removePackage: (state, action) => {
            state.packages = state.packages.filter(pkg => pkg._id !== action.payload._id); 
        },
        updatePackage: (state, action) => {
            const index = state.packages.findIndex(pkg => pkg._id === action.payload._id);  
            if (index !== -1) {
                state.packages[index] = action.payload; 
            }
        },
    },
});

export const { setPackages, addPackage, removePackage, updatePackage } = packagesSlice.actions;

// Thunk to fetch packages
export const fetchPackages = (vendorId, typeOf) => async (dispatch) => {
    try {
        const response = await getPackageDetailsList(vendorId, typeOf); 
        dispatch(setPackages(response.data)); 
    } catch (error) {
        console.error("Failed to fetch packages:", error);
        // Optionally, dispatch an error action here
    }
};

export default packagesSlice.reducer;
