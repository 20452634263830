import { axiosInstance } from ".";

/*** 
 * Added by Dilip Yadav
 * Used for saving new bank details 
 * Date: 10-08-2024
 */
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';
export const saveBankDetails = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-bankdetails`, payload);
        return response.data;
    } catch (error) {
        console.error("Error saving bank details:", error);
        throw error; 
    }
}

/** 
 * Added by Dilip Yadav
 * Used for fetching the list of bank details 
 * Date: 10-08-2024
 */
export const getBankDetailsList = async (id) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-bank-data/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching bank details list:", error);
        throw error; 
    }
}

/**
 * Added by Dilip Yadav 
 * Used for soft deleting bank details
 * @param {string} id 
 * @returns {Promise<Object>}
 */
export const deleteBankDetails = async (id) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/delete-bankdetails/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting bank details:", error);
        throw error; 
    }
};

/**
 * Added by Dilip Yadav 
 * Used for updating bank details
 * @param {string} id 
 * @param {Object} payload 
 * @returns {Promise<Object>}
 */
export const updateBankDetails = async (id, payload) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-bankdetails/${id}`, payload);
        return response.data;
    } catch (error) {
        console.error("Error updating bank details:", error);
        throw error; 
    }
};
