import { Box, Button, Typography, useTheme } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { Link } from 'react-router-dom';
import { TextField, Select, MenuItem, InputLabel, FormControl, FormHelperText, Radio } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";

const Team = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const handleFormSubmit = (values) => {
        console.log(values);
    };
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "age",
            headerName: "Age",
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "phone",
            headerName: "Phone Number",
            flex: 1,
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
        },
        {
            field: "accessLevel",
            headerName: "Access Level",
            flex: 1,
            renderCell: ({ row: { access } }) => {
                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            access === "admin"
                                ? colors.greenAccent[600]
                                : access === "manager"
                                    ? colors.greenAccent[700]
                                    : colors.greenAccent[700]
                        }
                        borderRadius="4px"
                    >
                        {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
                        {access === "manager" && <SecurityOutlinedIcon />}
                        {access === "user" && <LockOpenOutlinedIcon />}
                        <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                            {access}
                        </Typography>
                    </Box>
                );
            },
        },
    ];

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="VISITOR'S REPORT" subtitle="Visitor's Report List" />
            </Box>
            <Box>
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={checkoutSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(6, minmax(0, 1fr))"
                                sx={{
                                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                }}
                            >
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="date"
                                    label="From Date"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.CName}
                                    name="CName"
                                    error={!!touched.CName && !!errors.CName}
                                    helperText={touched.CName && errors.CName}
                                    sx={{ gridColumn: "span 1" }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="date"
                                    label="To Date"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.CName}
                                    name="CName"
                                    error={!!touched.CName && !!errors.CName}
                                    helperText={touched.CName && errors.CName}
                                    sx={{ gridColumn: "span 1" }}
                                />
                                <FormControl fullWidth variant="filled" error={!!touched.VisitingPurpose && !!errors.VisitingPurpose} sx={{ gridColumn: "span 1" }}>
                                    <InputLabel id="visiting-purpose-label">Assigned Users</InputLabel>
                                    <Select
                                        labelId="visiting-purpose-label"
                                        value={values.VisitingPurpose}
                                        onChange={handleChange}
                                        name="VisitingPurpose"
                                        onBlur={handleBlur}
                                        label="Visiting Purpose" // Ensure the label prop matches the InputLabel text
                                    >
                                        <MenuItem value="a">A</MenuItem>
                                        <MenuItem value="b">B</MenuItem>
                                        <MenuItem value="c">C</MenuItem>
                                    </Select>
                                    {touched.VisitingPurpose && errors.VisitingPurpose && (
                                        <FormHelperText>{errors.VisitingPurpose}</FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl fullWidth variant="filled" error={!!touched.VisitingPurpose && !!errors.VisitingPurpose} sx={{ gridColumn: "span 1" }}>
                                    <InputLabel id="visiting-purpose-label">Visiting Purpose</InputLabel>
                                    <Select
                                        labelId="visiting-purpose-label"
                                        value={values.VisitingPurpose}
                                        onChange={handleChange}
                                        name="VisitingPurpose"
                                        onBlur={handleBlur}
                                        label="Visiting Purpose" // Ensure the label prop matches the InputLabel text
                                    >
                                        <MenuItem value="a">A</MenuItem>
                                        <MenuItem value="b">B</MenuItem>
                                        <MenuItem value="c">C</MenuItem>
                                    </Select>
                                    {touched.VisitingPurpose && errors.VisitingPurpose && (
                                        <FormHelperText>{errors.VisitingPurpose}</FormHelperText>
                                    )}
                                </FormControl>
                                <Button type="submit" color="secondary" variant="contained">
                                    Search
                                </Button>
                                <Button type="submit" color="primary" variant="contained">
                                    Export CSV
                                </Button>
                            </Box>
                            {/* <Box display="flex" justifyContent="end" mt="20px">
                                <Button type="submit" color="secondary" variant="contained">
                                    Search
                                </Button>&nbsp;&nbsp;
                                <Button type="submit" color="primary" variant="contained">
                                    Export CSV
                                </Button>
                            </Box> */}
                        </form>
                    )}
                </Formik>
                {/* <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                    }}
                >
                    <Link to="/addbank" className="text-decoration-none text-white">
                        Search
                    </Link>
                </Button> &nbsp;
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                    }}
                >
                    <Link to="/addbank" className="text-decoration-none text-white">
                        Export CSV
                    </Link>
                </Button> */}
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                {/* <DataGrid checkboxSelection rows={mockDataTeam} columns={columns} /> */}

                <div class="table-responsive">
                    <table class="table table-dark">
                        <thead>
                            <tr>
                                <th scope="col">No.</th>
                                <th scope="col">Date</th>
                                <th scope="col">Assign User</th>
                                <th scope="col">Name</th>
                                <th scope="col">Phone No.</th>
                                <th scope="col">Address</th>
                                <th scope="col">Visiting Purpose</th>
                                <th scope="col">Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td>1</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                            </tr>
                            <tr >
                                <td>2</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                            </tr>
                            <tr >
                                <td>3</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                            </tr>
                            <tr >
                                <td>4</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                                <td>Lorem Bank</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Box>
        </Box >
    );
};

const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const checkoutSchema = yup.object().shape({
    CName: yup.string().required("required"),
    companymail: yup.string().email("invalid email").required("required"),
    contact: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("required"),
    companyaddress: yup.string().required("required"),
    companylogo: yup.string().required("required"),
});
const initialValues = {
    CName: "",
    contact: "",
    companyaddress: "",
    companylogo: "",
    companymail: "",
};

export default Team;
