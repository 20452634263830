import { createSlice } from "@reduxjs/toolkit";
import { GetSubscriptionList } from "../apicalls/subscription"; 

const SubscriptionsSlice = createSlice({
    name: "subscriptions", 
    initialState: {
        subscriptions: [],
    },
    reducers: {
        setSubscriptions: (state, action) => {
            state.subscriptions = action.payload; 
        },
        addSubscription: (state, action) => {
            state.subscriptions.push(action.payload);
        },
        removeSubscription: (state, action) => {
            state.subscriptions = state.subscriptions.filter(subscription => subscription.id !== action.payload.id); 
        },
        updateSubscription: (state, action) => {
            const index = state.subscriptions.findIndex(subscription => subscription.id === action.payload.id); 
            if (index !== -1) {
                state.subscriptions[index] = action.payload;
            }
        },
    },
});

export const { setSubscriptions, addSubscription, removeSubscription, updateSubscription } = SubscriptionsSlice.actions;

export const fetchSubscriptions = () => async (dispatch) => {
    try {
        const response = await GetSubscriptionList();
        if (response.success) { 
            dispatch(setSubscriptions(response.data));
        } else {
            console.error("Failed to fetch subscriptions:", response.message); 
        }
    } catch (error) {
        console.error("Failed to fetch subscriptions:", error);
    }
};

export default SubscriptionsSlice.reducer;
