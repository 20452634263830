import { Box, Button, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom"; 
import { tokens } from "../../../theme";
import Header from "../../../components/admincomponents/Header";

const Logout = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate(); 
    const handleLogout = () => {
        localStorage.removeItem("token"); 
        window.location.pathname='login';
    };

    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="LOGOUT" subtitle="Logout" />
            </Box>

            {/* GRID & CHARTS */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
            >
                {/* LOGOUT CONFIRMATION BOX */}
                <Box
                    gridColumn="span 4"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    flexDirection='column'
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                >
                    <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
                        Do you want to Logout?
                    </Typography>
                    <Box>&nbsp;</Box>
                    <Box
                        gridColumn="span 4"
                        backgroundColor={colors.primary[400]}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                    >
                        <Button
                            onClick={handleLogout} 
                            sx={{
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                            }}
                        >
                            Yes
                        </Button>
                        &nbsp; &nbsp;
                        <Button
                            onClick={handleCancel} 
                            sx={{
                                backgroundColor: '#808080',
                                color: colors.grey[100],
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                            }}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Logout;
