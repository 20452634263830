import { Box, Button, Typography, useTheme, Dialog, DialogTitle, DialogContent, DialogActions, TextField, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { tokens } from "../../theme";
import { useState, useEffect } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";
import { getBankDetailsList, deleteBankDetails, updateBankDetails } from "../../apicalls/bankdetails";
import Header from "../../components/Header";

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataBanks, setBankData] = useState([]); 
    const [open, setOpen] = useState(false);
    const [currentBank, setCurrentBank] = useState(null);
    const { user } = useSelector((state) => state.users); 
    const vendorId = user ? user._id : ""; 

    const fetchData = async () => {
        try {
            const response = await getBankDetailsList(vendorId);
            setBankData(response.data || []);
        } catch (error) {
            console.error("Error fetching bank data:", error);
            message.error("Failed to load bank data.");
        }
    };

    useEffect(() => {
        fetchData(); 
    }, [vendorId]);

    const handleDelete = async (id) => {
        try {
            await deleteBankDetails(id);
            message.success("Bank details deleted successfully!");
            fetchData();
        } catch (error) {
            console.error("Error deleting bank data:", error);
            message.error("Failed to delete bank data.");
        }
    };

    const handleUpdateOpen = (bank) => {
        setCurrentBank(bank);
        setOpen(true);
    };

    const handleUpdateClose = () => {
        setOpen(false);
        setCurrentBank(null);
    };

    const handleUpdateSubmit = async () => {
        try {
            await updateBankDetails(currentBank._id, currentBank);
            message.success("Bank details updated successfully!");
            fetchData();
            handleUpdateClose();
        } catch (error) {
            console.error("Error updating bank data:", error);
            message.error("Failed to update bank data.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // If the status is changed, update isActive based on radio button value
        if (name === "isActive") {
            setCurrentBank((prev) => ({ ...prev, isActive: value === "active" }));
        } else {
            setCurrentBank((prev) => ({ ...prev, [name]: value }));
        }
    };

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="BANK" subtitle="Bank List" />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                    }}
                >
                    <Link to="/addbank" className="text-decoration-none text-white">
                        + Add New Bank
                    </Link>
                </Button>
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": { border: "none" },
                    "& .MuiDataGrid-cell": { borderBottom: "none" },
                    "& .name-column--cell": { color: colors.greenAccent[300] },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <div className="table-responsive">
                    <table className="table accordion table-dark">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Bank Name</th>
                                <th scope="col">Branch</th>
                                <th scope="col">Description</th>
                                <th scope="col">Address</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataBanks.length > 0 ? (
                                dataBanks.map((bank, index) => (
                                    <tr key={bank._id}>
                                        <td>{index + 1}</td>
                                        <td>{bank.BankName}</td>
                                        <td>{bank.BranchName}</td>
                                        <td>{bank.Description}</td>
                                        <td>{bank.Address}</td>
                                        <td>{bank.isActive ? "Active" : "Inactive"}</td>
                                        <td>
                                            <Button onClick={() => handleUpdateOpen(bank)}>
                                                <EditIcon />
                                            </Button>
                                            <Button onClick={() => handleDelete(bank._id)} color="error">
                                                <DeleteIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" style={{ textAlign: 'center' }}>
                                        No bank details available.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </Box>

            <Dialog open={open} onClose={handleUpdateClose}>
                <DialogTitle>Update Bank Details</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="BankName"
                        label="Bank Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={currentBank ? currentBank.BankName : ''}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="BranchName"
                        label="Branch"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={currentBank ? currentBank.BranchName : ''}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="Description"
                        label="Description"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={currentBank ? currentBank.Description : ''}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="Address"
                        label="Address"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={currentBank ? currentBank.Address : ''}
                        onChange={handleChange}
                    />
                    
                    {/* Status Radio Buttons */}
                    <Typography variant="subtitle1">Status</Typography>
                    <RadioGroup
                        row
                        name="isActive"
                        value={currentBank ? (currentBank.isActive ? "Active" : "Inactive") : "Inactive"}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="Active" control={<Radio />} label="Active" />
                        <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
                    </RadioGroup>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdateClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateSubmit} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
};

export default Team;
