import { createSlice } from "@reduxjs/toolkit";
const loaders=createSlice({
    name:"loader",
    initialState:{
        loading:false
    },
    reducers:{
        ShowLoading:(state)=>{
           state.loading=true;
        },
        HidenLoading:(state)=>{
            state.loading=false
        }
    }
});

export const {ShowLoading,HidenLoading}=loaders.actions;
export default loaders.reducer;