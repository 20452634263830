import { createSlice } from '@reduxjs/toolkit';
import { getUmrahDetailsList } from "../apicalls/umrahDetails";
import { getVisitorDetailsList } from "../apicalls/visitor";
import { getAgentList } from "../apicalls/agent";
import { getCustmoreDetailsList } from "../apicalls/customerDetails";
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    totalCredit: 100,
    totalDebit: 10,
    totalBalance: 90,
    preRegisteredHajji: 0,
    mainHajji: 0,
    umrahHajji: 0,
    tourAndTravelCustomer: 0,
    agentCustomer: 0,
    totalVisitor: 0,
    revenueGenerated: 0,
    transactions: [],
    count: 0, 
  },
  reducers: {
    setTotalCredit: (state, action) => { state.totalCredit = action.payload; },
    setTotalDebit: (state, action) => { state.totalDebit = action.payload; },
    setTotalBalance: (state, action) => { state.totalBalance = action.payload; },
    setPreRegisteredHajji: (state, action) => { state.preRegisteredHajji = action.payload; },
    setMainHajji: (state, action) => { state.mainHajji = action.payload; },
    setUmrahHajji: (state, action) => { state.umrahHajji = action.payload; },
    setTourAndTravelCustomer: (state, action) => { state.tourAndTravelCustomer = action.payload; },
    setAgentCustomer: (state, action) => { state.agentCustomer = action.payload; },
    setTotalVisitor: (state, action) => { state.totalVisitor = action.payload; },
    setRevenueGenerated: (state, action) => { state.revenueGenerated = action.payload; },
    setTransactions: (state, action) => { state.transactions = action.payload; },
    setCount: (state, action) => { state.count = action.payload; }, 
  },
});

export const {
  setTotalCredit,
  setTotalDebit,
  setTotalBalance,
  setPreRegisteredHajji,
  setMainHajji,
  setUmrahHajji,
  setTourAndTravelCustomer,
  setAgentCustomer,
  setTotalVisitor,
  setRevenueGenerated,
  setTransactions,
  setCount, 
} = dashboardSlice.actions;

export const fetchPreRegisteredHajji = (vendorId, typeOf) => async (dispatch) => {
    try {
        const response = await getUmrahDetailsList(vendorId, typeOf); 
        dispatch(setPreRegisteredHajji(response.data.length)); 
    } catch (error) {
        console.error("Failed to fetch groups:", error);
    }
};

export const fetchMainRegisteredHajji = (vendorId, typeOf) => async (dispatch) => {
    try {
        const response = await getUmrahDetailsList(vendorId, typeOf); 
        dispatch(setMainHajji(response.data.length)); 
    } catch (error) {
        console.error("Failed to fetch groups:", error);
    }
};
export const fetchsetUmrahHajji = (vendorId, typeOf) => async (dispatch) => {
    try {
        const response = await getUmrahDetailsList(vendorId, typeOf); 
        dispatch(setUmrahHajji(response.data.length)); 
    } catch (error) {
        console.error("Failed to fetch umrah :", error);
    }
};

export const fetchsetTotalVisitor = (vendorId) => async (dispatch) => {
    try {
        const response = await getVisitorDetailsList(vendorId); 
        dispatch(setTotalVisitor(response.data.length)); 
    } catch (error) {
        console.error("Failed to fetch visitor:", error);
    }
};

export const feachsetTotalAgents=(vendorId)=> async(dispatch)=>{
    try{
       const response= await getAgentList(vendorId);
       dispatch(setAgentCustomer(response.data.length))
    } catch(error){
       console.error("Fail to feach agent count",error);
    }
}

export const feachsetTotalCustomer=(vendorId)=> async (dispatch)=>{
    try{
      const response= await getCustmoreDetailsList(vendorId);
      dispatch(setTourAndTravelCustomer(response.data.length));
    } catch(error){
         console.error('Fail to feach custmore count',error);
    }
}
export default dashboardSlice.reducer;
