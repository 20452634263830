import {
    Box,
    Button,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormHelperText
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { saveairlinesticketpurchaseDetails } from "../../apicalls/airlinesticketpurchaseDetails"; 
import { message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { fetchsupplier } from "../../redux/supplierSlice"; 
import { fetchairline } from "../../redux/airlinesSlice"; 
import { useEffect } from "react";

const Form = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";
    const suppliers = useSelector((state) => state.suppliers.supplier || []);
    const airlines = useSelector((state) => state.airlines.airline || []);
    const dispatch = useDispatch();

    useEffect(() => {
        if (vendorId) {
            dispatch(fetchsupplier(vendorId));
            dispatch(fetchairline(vendorId));
        }
    }, [dispatch, vendorId]);

    const handleFormSubmit = async (values, { resetForm }) => {
        try {
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });

            const response = await saveairlinesticketpurchaseDetails(formData);
            message.success(response.success ? response.message : response.message);
        } catch (error) {
            message.error("An error occurred. Please try again.");
            console.error(error);
        }
        resetForm();
    };

    const initialValues = {
        airlinseId: "",
        invoice: "",
        unitPrice: "",
        ticketQty: "",
        payableAmount: "",
        paymentStatus: "Pending",
        paidAmount: 0,
        dueAmount: 0,
        airlinesType: "",
        vendorId: vendorId,
        supplierId: "",
        date: new Date().toISOString().substring(0, 10), // Added back for date
    };

    const checkoutSchema = yup.object().shape({
        airlinseId: yup.string().required("Airline name is required"),
        invoice: yup.string().required("Invoice is required"),
        ticketQty: yup.number().required("Ticket quantity is required").positive().integer(),
        unitPrice: yup.number().required("Unit price is required").positive(),
        payableAmount: yup.number().required("Payable amount is required").positive(),
        paidAmount: yup.number().min(0).max(yup.ref('payableAmount')).required("Paid amount is required"),
        supplierId: yup.string().required("Supplier is required"),
        airlinesType: yup.string().required("Airlines Ticket  Type is required"),
        date: yup.date().required("Date is required"),
    });

    return (
        <Box m="20px">
            <Header title="TICKET PURCHASE" subtitle="Create Ticket Purchase" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm }) => {
                    const handleTicketQtyChange = (e) => {
                        const ticketQty = e.target.value;
                        const payableAmount = ticketQty * values.unitPrice;
                        setFieldValue('ticketQty', ticketQty);
                        setFieldValue('payableAmount', payableAmount);
                        setFieldValue('dueAmount', payableAmount - values.paidAmount);
                    };

                    const handleUnitPriceChange = (e) => {
                        const unitPrice = e.target.value;
                        const payableAmount = values.ticketQty * unitPrice;
                        setFieldValue('unitPrice', unitPrice);
                        setFieldValue('payableAmount', payableAmount);
                        setFieldValue('dueAmount', payableAmount - values.paidAmount);
                    };

                    const handlePaidAmountChange = (e) => {
                        const paidAmount = e.target.value;
                        setFieldValue('paidAmount', paidAmount);
                        setFieldValue('dueAmount', values.payableAmount - paidAmount);
                    };

                    return (
                        <form onSubmit={handleSubmit}>
                            <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                                {['invoice', 'date'].map((field, index) => (
                                    <TextField
                                        key={index}
                                        fullWidth
                                        variant="filled"
                                        type={field === 'date' ? 'date' : 'text'}
                                        label={field.charAt(0).toUpperCase() + field.slice(1)}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values[field]}
                                        name={field}
                                        error={touched[field] && Boolean(errors[field])}
                                        helperText={touched[field] && errors[field]}
                                        sx={{ gridColumn: "span 2" }}
                                        InputLabelProps={field === 'date' ? { shrink: true } : {}}
                                    />
                                ))}
                            </Box>

                            <Box display="grid" gap="30px" mt="20px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                                {['unitPrice', 'ticketQty', 'paidAmount'].map((field, index) => (
                                    <TextField
                                        key={index}
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={field.charAt(0).toUpperCase() + field.slice(1)}
                                        onBlur={handleBlur}
                                        onChange={field === 'ticketQty' ? handleTicketQtyChange : (field === 'unitPrice' ? handleUnitPriceChange : handlePaidAmountChange)}
                                        value={values[field]}
                                        name={field}
                                        error={touched[field] && Boolean(errors[field])}
                                        helperText={touched[field] && errors[field]}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                ))}
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="number"
                                    label="Payable Amount"
                                    value={values.payableAmount}
                                    name="payableAmount"
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{ readOnly: true }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="number"
                                    label="Due Amount"
                                    value={values.dueAmount}
                                    name="dueAmount"
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{ readOnly: true }}
                                />
                            </Box>

                            <Box display="grid" gap="30px" mt="20px" gridTemplateColumns="repeat(2, minmax(0, 1fr))">
                                <FormControl
                                    fullWidth
                                    variant="filled"
                                    error={touched.supplierId && Boolean(errors.supplierId)}
                                    sx={{ gridColumn: "span 2" }}
                                >
                                    <InputLabel id="supplier-select-label">Select Supplier</InputLabel>
                                    <Select
                                        labelId="supplier-select-label"
                                        value={values.supplierId}
                                        onChange={handleChange}
                                        name="supplierId"
                                        onBlur={handleBlur}
                                    >
                                        {Array.isArray(suppliers) && suppliers.length > 0 ? (
                                            suppliers.map(supplier => (
                                                <MenuItem key={supplier._id} value={supplier._id}>
                                                    {supplier.SupplierName}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>No suppliers available</MenuItem>
                                        )}
                                    </Select>
                                    {touched.supplierId && errors.supplierId && (
                                        <FormHelperText>{errors.supplierId}</FormHelperText>
                                    )}
                                </FormControl>
                            </Box>

                            <Box display="grid" gap="30px" mt="20px" gridTemplateColumns="repeat(2, minmax(0, 1fr))">
                                <FormControl
                                    fullWidth
                                    variant="filled"
                                    error={touched.airlinseId && Boolean(errors.airlinseId)}
                                    sx={{ gridColumn: "span 2" }}
                                >
                                    <InputLabel id="airline-select-label">Select Airline</InputLabel>
                                    <Select
                                        labelId="airline-select-label"
                                        value={values.airlinseId}
                                        onChange={handleChange}
                                        name="airlinseId"
                                        onBlur={handleBlur}
                                    >
                                        {Array.isArray(airlines) && airlines.length > 0 ? (
                                            airlines.map(airline => (
                                                <MenuItem key={airline._id} value={airline._id}>
                                                    {airline.airlinesName}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>No airline available</MenuItem>
                                        )}
                                    </Select>
                                    {touched.airlinseId && errors.airlinseId && (
                                        <FormHelperText>{errors.airlinseId}</FormHelperText>
                                    )}
                                </FormControl>
                            </Box>

                            <Box display="grid" gap="30px" mt="20px" gridTemplateColumns="repeat(2, minmax(0, 1fr))">
                                <FormControl
                                    fullWidth
                                    variant="filled"
                                    error={touched.airlinesType && Boolean(errors.airlinesType)}
                                    sx={{ gridColumn: "span 2" }}
                                >
                                    <InputLabel id="airline-select-label">Select Airlines Ticket Type</InputLabel>
                                    <Select
                                        labelId="airline-select-label"
                                        value={values.airlinesType}
                                        onChange={handleChange}
                                        name="airlinesType"
                                        onBlur={handleBlur}
                                    >
                                        <MenuItem value="Economy">Economy</MenuItem>
                                        <MenuItem value="Business">Business</MenuItem>
                                        <MenuItem value="First">First Class</MenuItem>
                                    </Select>
                                    {touched.airlinesType && errors.airlinesType && (
                                        <FormHelperText>{errors.airlinesType}</FormHelperText>
                                    )}
                                </FormControl>
                            </Box>

                            <Box display="flex" justifyContent="end" mt="20px">
                                <Button type="submit" color="secondary" variant="contained">Create New Airline Ticket</Button>
                                <Button type="button" color="primary" variant="contained" onClick={resetForm} sx={{ marginLeft: "10px" }}>Reset</Button>
                            </Box>
                        </form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default Form;
