import { createSlice } from "@reduxjs/toolkit";
import { getSupplierDetailsList } from "../apicalls/supplierdetails"; 
const supplierSlice = createSlice({
    name: "suppliers",
    initialState: {
        supplier: [],
    },
    reducers: {
        setsupplier: (state, action) => {
            state.supplier = action.payload;
        },
        addAgent: (state, action) => {
            state.supplier.push(action.payload);
        },
        removeAgent: (state, action) => {
            state.supplier = state.supplier.filter(agent => agent.id !== action.payload.id);
        },
        updateAgent: (state, action) => {
            const index = state.supplier.findIndex(agent => agent.id === action.payload.id);
            if (index !== -1) {
                state.supplier[index] = action.payload;
            }
        },
    },
});

export const { setsupplier, addAgent, removeAgent, updateAgent } = supplierSlice.actions;

// Fetch supplier action
export const fetchsupplier = (vendorId) => async (dispatch) => {
    try {
        const response = await getSupplierDetailsList(vendorId); 
        dispatch(setsupplier(response.data));
    } catch (error) {
        console.error("Failed to fetch supplier:", error);
    }
};

export default supplierSlice.reducer;
