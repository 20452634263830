import { axiosInstance } from ".";

/*** 
 * Added by Dilip Yadav
 * Used for saving a new agent 
 * 10-08-2024
 */
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';

export const saveAgent = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-agent`, payload);
        return response.data;
    } catch (error) {
        console.error("Error saving agent:", error);
        throw error; 
    }
}

/** 
 * Added by Dilip Yadav
 * Used for fetching the list of agents 
 * 10-08-2024
 */
export const getAgentList = async (id) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-agent-data/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching agent list:", error);
        throw error; 
    }
}

/**
 * Added by Dilip Yadav 
 * Used for soft deleting an agent
 * @param {string} id 
 * @returns {Promise<Object>}
 */
export const deleteAgent = async (id) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/delete-agent/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting agent:", error);
        throw error; 
    }
};

/**
 * Added by Dilip Yadav 
 * Used for updating an agent's profile
 * @param {string} id 
 * @param {Object} payload 
 * @returns {Promise<Object>}
 */
export const updateAgent = async (id, payload) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-agent/${id}`, payload);
        return response.data;
    } catch (error) {
        console.error("Error updating agent:", error);
        throw error; 
    }
};
