import { axiosInstance } from "."; // Import the configured Axios instance

// Base URL for API calls
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';

/** 
 * Added by Dilip Yadav 15-10-2024
 * Saves new Visa details.
 * @param {Object} payload - The Visa details to be saved.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const saveVisaDetails = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-visa`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error saving Visa details:", error); 
        throw error; 
    }
}

/** 
 * Added by Dilip Yadav 15-10-2024
 * Fetches the list of Visa details for a specific user by ID.
 * @param {string} id - The ID of the user.
 * @returns {Promise<Object>} - The Visa details list.
 */
export const getVisaDetailsList = async (id) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-visa-data/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        console.error("Error fetching Visa details list:", error); // Log the error
        throw error; // Rethrow the error
    }
}

/**
 * Added by Dilip Yadav 15-10-2024
 * Soft deletes Visa details by ID.
 * @param {string} id - The ID of the Visa to delete.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const deleteVisaDetails = async (id) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/delete-visa/${id}`);
        return response.data; 
    } catch (error) {
        console.error("Error deleting Visa details:", error); 
        throw error; 
    }
};

/**
 * Added by Dilip Yadav 15-10-2024
 * Updates Visa details by ID.
 * @param {string} id - The ID of the Visa to update.
 * @param {Object} payload - The updated Visa details.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const updateVisaDetails = async (id, payload) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-visa/${id}`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error updating Visa details:", error);
        throw error;
    }
};
