import { createSlice } from "@reduxjs/toolkit";

const userslice=createSlice({
    name:"users",
    initialState:{
        user:null
    },
    reducers:{
        Setuser:(state,action)=>{
            state.user=action.payload
        }
    }
});

export const {Setuser}=userslice.actions;
export default userslice.reducer;
