import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  IconButton,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/admincomponents/Header";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { RegisterUser } from "../../../apicalls/users";
import { GetSubscriptionList } from '../../../apicalls/subscription';
import { message } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { fetchSubscriptions } from "../../../redux/subscriptionSlice"; 

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { subscriptions } = useSelector((state) => state.subscriptions || {});

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      const response = await RegisterUser(values);
      if (response.success) {
        message.success(response.message);
      } else {
        message.error(response.message); 
      }
    } catch (error) {
      message.error("An error occurred. Please try again.");
      console.error(error);
    }
    resetForm(); 
  };

  useEffect(() => {
    dispatch(fetchSubscriptions());
  }, [dispatch]);

  return (
    <Box m="20px">
      <Header title="CREATE USER" subtitle="Create a New User" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <input type="hidden" value='0' name="isAdmin" />
              <TextField
                fullWidth
                variant="filled"
                label="Company Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Vendor Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.vendorName}
                name="vendorName"
                error={touched.vendorName && Boolean(errors.vendorName)}
                helperText={touched.vendorName && errors.vendorName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="email"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address1}
                name="address1"
                error={touched.address1 && Boolean(errors.address1)}
                helperText={touched.address1 && errors.address1}
                sx={{ gridColumn: "span 2" }}
              />
              <FormControl
                fullWidth
                variant="filled"
                error={touched.subscription_id && Boolean(errors.subscription_id)}
                sx={{ gridColumn: "span 2" }}
              >
                <InputLabel id="visiting-purpose-label">
                  Subscription Package
                </InputLabel>
                <Select
                  labelId="visiting-purpose-label"
                  value={values.subscription_id}
                  onChange={handleChange}
                  name="subscription_id"
                  onBlur={handleBlur}
                  label="Subscription Package"
                >
                  {Array.isArray(subscriptions) && subscriptions.length > 0 ? (
                    subscriptions.map(subscription => (
                      <MenuItem key={subscription._id} value={subscription._id}>
                        {subscription.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No subscription available</MenuItem>
                  )}
                </Select>
                {touched.subscription_id && errors.subscription_id && (
                  <FormHelperText>{errors.subscription_id}</FormHelperText>
                )}
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                type={showPassword ? "text" : "password"}
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)} 
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create New User
              </Button>
              &nbsp;&nbsp;
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={() => resetForm()} 
              >
                Reset
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("required"),
  vendorName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  address1: yup.string().required("required"),
  subscription_id: yup.string().required("required"),
  password: yup.string().required("required"),
});

const initialValues = {
  name: "",
  vendorName: "",
  email: "",
  address1: "",
  subscription_id: "",
  password: "",
  isAdmin: '0',
};

export default Form;
