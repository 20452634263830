import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import StatBox from "../../components/StatBox";
import PaymentsIcon from "@mui/icons-material/Payments";
import SavingsIcon from "@mui/icons-material/Savings";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ContactsIcon from "@mui/icons-material/Contacts";
import GroupIcon from "@mui/icons-material/Group";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from 'react';
import { fetchPreRegisteredHajji,fetchMainRegisteredHajji,fetchsetUmrahHajji,fetchsetTotalVisitor,feachsetTotalAgents,feachsetTotalCustomer } from '../../redux/dashboardSlice';
const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);
  const vendorId = user ? user._id : "";
  const typeOf = 'Hajj';
  useEffect(() => {
      if (vendorId) {
          dispatch(fetchPreRegisteredHajji(vendorId, 'Hajj'));
          dispatch(fetchMainRegisteredHajji(vendorId, 'Hajjmain'));
          dispatch(fetchsetUmrahHajji(vendorId, 'Umrah'));
          dispatch(fetchsetTotalVisitor(vendorId));
          dispatch(feachsetTotalAgents(vendorId));
          dispatch(feachsetTotalCustomer(vendorId));
      }
  }, [vendorId, typeOf, dispatch]);
  const {
    totalCredit,
    totalDebit,
    totalBalance,
    preRegisteredHajji,
    mainHajji,
    umrahHajji,
    tourAndTravelCustomer,
    agentCustomer,
    totalVisitor,
    revenueGenerated,
    transactions,
  } = useSelector((state) => state.dashboard);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          <DownloadOutlinedIcon sx={{ mr: "10px" }} />
          Download Reports
        </Button>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box gridColumn="span 4" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
          <StatBox
            title={totalCredit.toLocaleString()}
            subtitle="Total Credit"
            progress="0.75"
            increase="+14%"
            icon={<PaymentsIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />}
          />
        </Box>
        <Box gridColumn="span 4" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
          <StatBox
            title={totalDebit.toLocaleString()}
            subtitle="Total Debit"
            progress="0.50"
            increase="+21%"
            icon={<PaymentsIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />}
          />
        </Box>
        <Box gridColumn="span 4" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
          <StatBox
            title={totalBalance.toLocaleString()}
            subtitle="Total Balance"
            progress="0.30"
            increase="+5%"
            icon={<SavingsIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />}
          />
        </Box>
        <Box gridColumn="span 4" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
          <StatBox
            title={preRegisteredHajji.toLocaleString()}
            subtitle="Pre-Registered Hajji"
            progress="0.75"
            increase="+14%"
            icon={<HomeWorkIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />}
          />
        </Box>
        <Box gridColumn="span 4" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
          <StatBox
            title={mainHajji.toLocaleString()}
            subtitle="Main Hajji"
            progress="0.50"
            increase="+21%"
            icon={<HomeWorkIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />}
          />
        </Box>
        <Box gridColumn="span 4" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
          <StatBox
            title={umrahHajji.toLocaleString()}
            subtitle="Umrah Hajji"
            progress="0.30"
            increase="+5%"
            icon={<HomeWorkIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />}
          />
        </Box>
        <Box gridColumn="span 4" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
          <StatBox
            title={tourAndTravelCustomer.toLocaleString()}
            subtitle="Tour & Travels Customer"
            progress="0.75"
            increase="+14%"
            icon={<ContactsIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />}
          />
        </Box>
        <Box gridColumn="span 4" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
          <StatBox
            title={agentCustomer.toLocaleString()}
            subtitle="Agent Customer"
            progress="0.50"
            increase="+21%"
            icon={<PointOfSaleIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />}
          />
        </Box>
        <Box gridColumn="span 4" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
          <StatBox
            title={totalVisitor.toLocaleString()}
            subtitle="Total Visitor"
            progress="0.30"
            increase="+5%"
            icon={<GroupIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />}
          />
        </Box>

        {/* ROW 2 */}
        <Box gridColumn="span 8" gridRow="span 2" backgroundColor={colors.primary[400]}>
          <Box mt="25px" p="0 30px" display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
                Revenue Generated
              </Typography>
              <Typography variant="h3" fontWeight="bold" color={colors.greenAccent[500]}>
                ${revenueGenerated.toLocaleString()}
              </Typography>
            </Box>
            <IconButton>
              <DownloadOutlinedIcon sx={{ fontSize: "26px", color: colors.greenAccent[500] }} />
            </IconButton>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} />
          </Box>
        </Box>

        <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.primary[400]} overflow="auto">
          <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={`4px solid ${colors.primary[500]}`} p="15px">
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Transactions
            </Typography>
          </Box>
          {mockTransactions.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography color={colors.greenAccent[500]} variant="h5" fontWeight="600">
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box backgroundColor={colors.greenAccent[500]} p="5px 10px" borderRadius="4px">
                ${transaction.cost}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
