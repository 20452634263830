import { axiosInstance } from ".";

/*** 
 * Added by Dilip Yadav
 * Used for saving company information 
 * Date: 10-08-2024
 */
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';
export const saveCompanyInfo = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-update-companyinfo`, payload);
        return response.data;
    } catch (error) {
        console.error("Error saving company info:", error);
        throw error; 
    }
}

/** 
 * Added by Dilip Yadav
 * Used for fetching the list of company info 
 * Date: 10-08-2024
 */
export const geCompanyDetails = async (id) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-companyinfo-data/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching company details:", error);
        throw error; 
    }
}

/**
 * Added by Dilip Yadav 
 * Used for updating a company's information
 * @param {string} id - The ID of the company to update
 * @param {Object} payload - The updated company data
 * @returns {Promise<Object>}
 */
export const updateCompanyInfo = async (id, payload) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-companyinfo/${id}`, payload);
        return response.data;
    } catch (error) {
        console.error("Error updating company info:", error);
        throw error; 
    }
};
