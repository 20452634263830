import { axiosInstance } from "."; // Import the configured Axios instance

// Base URL for API calls
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';

/** 
 * Added by Dilip Yadav 19-10-2024
 * Saves new EmbassyAgent details.
 * @param {Object} payload - The EmbassyAgent details to be saved.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const saveEmbassyAgentDetails = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-embassyagent`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error saving Embassy Agent details:", error); 
        throw error; 
    }
}

/** 
 * Added by Dilip Yadav 19-10-2024
 * Fetches the list of EmbassyAgent details for a specific user by ID.
 * @param {string} id - The ID of the user.
 * @returns {Promise<Object>} - The EmbassyAgent details list.
 */
export const getEmbassyAgentDetailsList = async (id) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-embassyagent-data/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        console.error("Error fetching Embassy Agent details list:", error); // Log the error
        throw error; // Rethrow the error
    }
}

/**
 * Added by Dilip Yadav 19-10-2024
 * Soft deletes EmbassyAgent details by ID.
 * @param {string} id - The ID of the EmbassyAgent to delete.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const deleteEmbassyAgentDetails = async (id) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/delete-embassyagent/${id}`);
        return response.data; 
    } catch (error) {
        console.error("Error deleting Embassy Agent details:", error); 
        throw error; 
    }
};

/**
 * Added by Dilip Yadav 19-10-2024
 * Updates EmbassyAgent details by ID.
 * @param {string} id - The ID of the EmbassyAgent to update.
 * @param {Object} payload - The updated EmbassyAgent details.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const updateEmbassyAgentDetails = async (id, payload) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-embassyagent/${id}`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error updating Embassy Agent details:", error);
        throw error;
    }
};
