import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Dialog,
    DialogActions,
    DialogContent,
    useTheme,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import {
    getairlinesticketsalesDetailsList,
    deleteairlinesticketsalesDetails,
    updateairlinesticketsalesDetails,
} from "../../apicalls/airlinesticketsaleDetails";
import React,{ useState, useEffect } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataCountries, setAirlinseticketsellData] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [currentAirlinseticketsell, setCurrentAirlinseticketsell] = useState(null);
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";

    const fetchData = async () => {
        try {
            const response = await getairlinesticketsalesDetailsList(vendorId);
            setAirlinseticketsellData(response.data || []);
        } catch (error) {
            message.error("Failed to load Airlines ticket data.");
        }
    };

    useEffect(() => {
        fetchData();
    }, [vendorId]);

    const handleDeleteAirlinseticketsell = async (id) => {
        try {
            await deleteairlinesticketsalesDetails(id);
            message.success("Airlines ticket deleted successfully!");
            fetchData();
        } catch (error) {
            message.error("Failed to delete Airlines ticket.");
        }
    };

    const handleUpdateAirlinseticketsell = async () => {
        try {
            await updateairlinesticketsalesDetails(
                currentAirlinseticketsell._id,
                currentAirlinseticketsell
            );
            message.success("Airlines ticket updated successfully!");
            fetchData();
            setOpenEditModal(false);
        } catch (error) {
            message.error("Failed to update Airlines ticket.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentAirlinseticketsell((prev) => ({
            ...prev,
            [name]: name === "isActive" ? value === "Active" : value,
        }));
    };

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="TICKETING AIRLINES" subtitle="Ticketing Airlines List" />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                    }}
                >
                       <Link to="/createticketsales" className="text-decoration-none text-white">
                            + Add New Ticket Sale
                        </Link>
                </Button>
            </Box>

            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <div className="table-responsive">
                    <table className="table accordion table-dark">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Invoice No.</th>
                                <th scope="col">Sales Date</th>
                                <th scope="col">Flight Date</th>
                                <th scope="col">Passenger Name</th>
                                <th scope="col">Agent Name</th>
                                <th scope="col">Airlines Name</th>
                                <th scope="col">Ticket No.</th>
                                <th scope="col">Unit Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataCountries.map((Airlinseticketsell) => (
                                <React.Fragment key={Airlinseticketsell._id}>
                                <tr data-bs-toggle="collapse" data-bs-target={`#collapse-${Airlinseticketsell._id}`}>
                                  <th scope="row">+</th>
                                  <td>{Airlinseticketsell.invoice}</td>
                                  <td>{new Date(Airlinseticketsell.flightDate || Date.now()).toLocaleDateString()}</td>
                                  <td>{new Date(Airlinseticketsell.saleDate || Date.now()).toLocaleDateString()}</td>
                                  <td>{Airlinseticketsell.passengerName}</td>
                                  <td>{Airlinseticketsell.agentId}</td>
                                  <td>{Airlinseticketsell.airlinseId}</td>
                                  <td>{Airlinseticketsell.ticketNumber}</td>
                                  <td>{Airlinseticketsell.unitPrice}</td>
                                </tr>
                                <tr className="collapse accordion-collapse" id={`collapse-${Airlinseticketsell._id}`}>
                                  <td colSpan="9">
                                    <b>Qty:</b> {Airlinseticketsell.ticketQty || "N/A"}<br />
                                    <b>Total Payable Amount:</b> {Airlinseticketsell.payableAmount || "N/A"}<br />
                                    <b>Paid Amount:</b> {Airlinseticketsell.paidAmount || "N/A"}<br />
                                    <b>Due Amount:</b>{Airlinseticketsell.dueAmount || "N/A"}<br />
                                    <b>Remark:</b> {Airlinseticketsell.remark || "N/A"}<br />
                                    <b>Sales By:</b> {Airlinseticketsell.salesBy || "N/A"}<br />
                                    <b>Action:</b>    
                                    <EditIcon
                                            onClick={() => {
                                                setCurrentAirlinseticketsell(Airlinseticketsell);
                                                setOpenEditModal(true);
                                            }}
                                        />
                                        <DeleteIcon
                                            onClick={() => {
                                                setCurrentAirlinseticketsell(Airlinseticketsell);
                                                setOpenDeleteModal(true);
                                            }}
                                        />
                                  </td>
                                </tr>
                              </React.Fragment>
                                
                            ))}
                        </tbody>
                    </table>
                </div>
            </Box>

            {/* Edit Modal */}
            <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
                <Box sx={modalStyle}>
                    <DialogContent>
                        <Typography variant="h6">Edit Airlines Purchage</Typography>

                        <TextField
                            label="Invoice"
                            name="invoice"
                            value={currentAirlinseticketsell?.invoice || ""}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />

                        <TextField
                            label="Airlines Code"
                            name="airlinesCode"
                            value={currentAirlinseticketsell?.airlinesCode || ""}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />

                        <TextField
                            label="Ticket Series"
                            name="ticketsSeries"
                            value={currentAirlinseticketsell?.ticketsSeries || ""}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />

                        <TextField
                            label="Ticket Qty"
                            name="ticketQty"
                            value={currentAirlinseticketsell?.ticketQty || ""}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />

                        {/* Ticket Class Selection */}
                        <Box
                            display="grid"
                            gap="30px"
                            mt="20px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: "span 4" },
                            }}
                        >
                            <FormControl
                                fullWidth
                                variant="filled"
                                sx={{ gridColumn: "span 2", mb: 2 }}
                            >
                                <InputLabel id="ticket-class-select-label">Select Ticket Class</InputLabel>
                                <Select
                                    labelId="ticket-class-select-label"
                                    value={currentAirlinseticketsell?.ticketClass || ""}
                                    onChange={handleChange}
                                    name="ticketClass"
                                >
                                    <MenuItem value="Economy">Economy</MenuItem>
                                    <MenuItem value="Business">Business</MenuItem>
                                    <MenuItem value="First">First Class</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                        <Typography variant="subtitle1">Status</Typography>
                        <RadioGroup
                            row
                            name="isActive"
                            value={currentAirlinseticketsell?.isActive ? "Active" : "Inactive"}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="Active" control={<Radio />} label="Active" />
                            <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
                        </RadioGroup>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleUpdateAirlinseticketsell} color="primary">Save</Button>
                        <Button onClick={() => setOpenEditModal(false)} color="secondary">Cancel</Button>
                    </DialogActions>
                </Box>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <DialogContent>
                    <Typography>Are you sure you want to delete this Airlines Ticket?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDeleteAirlinseticketsell(currentAirlinseticketsell._id)} color="primary">Yes</Button>
                    <Button onClick={() => setOpenDeleteModal(false)} color="secondary">No</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default Team;
