import React, { useEffect, useState } from "react";
import { Box, Button, MenuItem, InputLabel, Select, FormControl, FormHelperText, RadioGroup, Typography, useTheme, Modal, TextField, Radio, FormControlLabel } from "@mui/material";
import { tokens } from "../../theme";
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { getPackageDetailsList, deletePackageDetails, updatePackageDetails } from "../../apicalls/packegedetails";
import { useSelector } from "react-redux";
import { message } from "antd";
import Header from "../../components/Header";
import { useFormik } from "formik"; 

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataPackege, setPackegeData] = useState([]);
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [selectedPackageId, setSelectedPackegeId] = useState(null);

    const fetchData = async () => {
        try {
            const typeOf = 'Hajj';
            const response = await getPackageDetailsList(vendorId, typeOf);
            setPackegeData(response.data || []);
        } catch (error) {
            console.error("Error fetching package data:", error);
            message.error("Failed to load package data.");
        }
    };

    const handleDelete = async () => {
        try {
            await deletePackageDetails(selectedPackageId);
            message.success("Package deleted successfully!");
            fetchData();
            setDeleteModalOpen(false);
        } catch (error) {
            console.error("Error deleting package:", error);
            message.error("Failed to delete package.");
        }
    };

    const openDeleteConfirmation = (packageId) => {
        setSelectedPackegeId(packageId);
        setDeleteModalOpen(true);
    };

    const openUpdateDialogHandler = (packageDetails) => {
        formik.setValues(packageDetails);
        setSelectedPackegeId(packageDetails._id);
        setUpdateModalOpen(true);
    };

    const handleUpdate = async (values) => {
        const { PackageName, PackageDuration, PackagePurpose, PackageDate, PackageYear, PackageAmount } = values;

        if (!PackageName || !PackagePurpose || !PackageDate || !PackageYear || !PackageAmount || !PackageDuration) {
            message.error("All fields are required!");
            return;
        }

        if (isNaN(PackageYear) || isNaN(PackageAmount) || isNaN(PackageDuration)) {
            message.error("Years and Amount must be valid numbers!");
            return;
        }

        if (Number(PackageYear) <= 0 || Number(PackageAmount) <= 0 || Number(PackageDuration) <= 0) {
            message.error("Years and Amount must be positive numbers!");
            return;
        }

        if (!/^\d{1,4}$/.test(PackageYear)) {
            message.error("Years must be a maximum of four digits!");
            return;
        }

        try {
            await updatePackageDetails(selectedPackageId, values);
            message.success("Package updated successfully!");
            fetchData();
            setUpdateModalOpen(false);
        } catch (error) {
            console.error("Error updating package:", error);
            message.error("Failed to update package.");
        }
    };

    useEffect(() => {
        fetchData();
    }, [vendorId]);

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 5 }, (_, i) => currentYear + i);

    const formik = useFormik({
        initialValues: {
            PackageName: '',
            PackageDuration: '',
            PackagePurpose: '',
            PackageDate: '',
            PackageYear: '',
            PackageAmount: '',
            isActive: 'Inactive',
        },
        onSubmit: handleUpdate,
    });

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="HAJJ PACKAGE" subtitle="Managing the Hajj Package List" />
                <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }} 
                    >
                        <Link to="/hajjcreatepackage" style={{ textDecoration: 'none', color: 'white' }}>
                            + Create Hajj Package
                        </Link>
                    </Button>
                </Box>
            </Box>

            <Box m="40px 0 0 0" height="75vh">
                <div className="table-responsive">
                    <table className="table accordion table-dark">
                        <thead>
                            <tr>
                                <th scope="col">S. No.</th>
                                <th scope="col">Package Name</th>
                                <th scope="col">Package Duration</th>
                                <th scope="col">Package Type</th>
                                <th scope="col">Date</th>
                                <th scope="col">Years</th>
                                <th scope="col">Total Amount</th>
                                <th scope="col">Remarks</th>
                                <th scope="col">Status</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataPackege.length>0 ? dataPackege.map((pack, index) => (
                                <tr key={pack.id}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{pack.PackageName}</td>
                                    <td>{pack.PackageDuration} Days</td>
                                    <td>{pack.PackagePurpose}</td>
                                    <td>{pack.PackageDate?.substring(0, 10) || ''}</td>
                                    <td>{pack.PackageYear}</td>
                                    <td>Rs {parseFloat(pack.PackageAmount).toFixed(2)}</td>
                                    <td>{pack.Remarks}</td>
                                    <td>{pack.isActive}</td>
                                    <td>
                                        <EditIcon onClick={() => openUpdateDialogHandler(pack)} style={{ cursor: 'pointer' }} />
                                        <Button onClick={() => openDeleteConfirmation(pack._id)} style={{ color: 'red' }}>Delete</Button>
                                    </td>
                                </tr>
                          )) : (
                            <tr>
                                <td colSpan="9" className="text-center">No  Hajj Package data available</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </Box>

            {/* Delete Confirmation Modal */}
            <Modal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                aria-labelledby="delete-confirmation-modal"
                aria-describedby="delete-confirmation-description"
            >
                <Box sx={{ p: 4, backgroundColor: 'white', borderRadius: 2, boxShadow: 3, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '300px' }}>
                    <Typography id="delete-confirmation-description" variant="h6" component="h2">
                        Are you sure you want to delete this package?
                    </Typography>
                    <Box mt={2}>
                        <Button onClick={handleDelete} color="error">Delete</Button>
                        <Button onClick={() => setDeleteModalOpen(false)} color="primary" sx={{ ml: 2 }}>Cancel</Button>
                    </Box>
                </Box>
            </Modal>

            {/* Update Modal */}
            <Modal
                open={updateModalOpen}
                onClose={() => setUpdateModalOpen(false)}
                aria-labelledby="update-modal"
                aria-describedby="update-description"
            >
                <Box
                    sx={{
                        p: 4,
                        backgroundColor: 'white',
                        borderRadius: 2,
                        boxShadow: 3,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '400px'
                    }}
                >
                    <Typography id="update-description" variant="h6" component="h2">
                        Update Package Details
                    </Typography>

                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            label="Package Name"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={formik.values.PackageName}
                            onChange={formik.handleChange}
                            name="PackageName"
                        />

                        <TextField
                            label="Package Duration"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={formik.values.PackageDuration}
                            onChange={formik.handleChange}
                            name="PackageDuration"
                        />

                        <FormControl fullWidth variant="filled" error={!!formik.touched.PackagePurpose && !!formik.errors.PackagePurpose}>
                            <InputLabel id="package-purpose-label">Type of Package</InputLabel>
                            <Select
                                labelId="package-purpose-label"
                                value={formik.values.PackagePurpose}
                                onChange={formik.handleChange}
                                name="PackagePurpose"
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem value="visa">Visa</MenuItem>
                                <MenuItem value="embassy">Embassy</MenuItem>
                                <MenuItem value="hajj">Hajj</MenuItem>
                                <MenuItem value="umrah">Umrah</MenuItem>
                                <MenuItem value="passport">Passport</MenuItem>
                                <MenuItem value="marketing">Marketing</MenuItem>
                            </Select>
                            {formik.touched.PackagePurpose && formik.errors.PackagePurpose && (
                                <FormHelperText>{formik.errors.PackagePurpose}</FormHelperText>
                            )}
                        </FormControl>

                        <TextField
                            label="Date"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            type="date"
                            value={formik.values.PackageDate}
                            onChange={formik.handleChange}
                            name="PackageDate"
                        />

                        <FormControl fullWidth variant="filled" error={!!formik.touched.PackageYear && !!formik.errors.PackageYear}>
                            <InputLabel id="package-year-label">Package Year</InputLabel>
                            <Select
                                labelId="package-year-label"
                                value={formik.values.PackageYear}
                                onChange={formik.handleChange}
                                name="PackageYear"
                                onBlur={formik.handleBlur}
                            >
                                {years.map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.touched.PackageYear && formik.errors.PackageYear && (
                                <FormHelperText>{formik.errors.PackageYear}</FormHelperText>
                            )}
                        </FormControl>

                        <TextField
                            label="Total Amount"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={formik.values.PackageAmount}
                            onChange={formik.handleChange}
                            name="PackageAmount"
                        />

                        <Typography variant="subtitle1" sx={{ mt: 2 }}>Status</Typography>
                        <RadioGroup
                            row
                            value={formik.values.isActive}
                            onChange={formik.handleChange}
                            name="isActive"
                        >
                            <FormControlLabel value="Active" control={<Radio />} label="Active" />
                            <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
                        </RadioGroup>

                        <Box mt={2}>
                            <Button type="submit" color="primary">Update</Button>
                            <Button onClick={() => setUpdateModalOpen(false)} color="secondary" sx={{ ml: 2 }}>Cancel</Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
};

export default Team;
