
import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import PaymentsIcon from '@mui/icons-material/Payments';
import InfoIcon from '@mui/icons-material/Info';
import PrintIcon from '@mui/icons-material/Print';
import { message } from 'antd';
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import { getUmrahDetailsList } from "../../apicalls/umrahDetails";

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";
    const [prehajjData, setprehajjData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [openInfoDialog, setOpenInfoDialog] = useState(false); 
    const [selectedPrehajj, setSelectedPrehajj] = useState(null); 

    const fetchData = async () => {
        setLoading(true);
        try {
            const type = "Umrah";
            const response = await getUmrahDetailsList(vendorId, type);
            if (response.success) {
                setprehajjData(response.data);
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error("Failed to fetch pre hajj data");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Action Handlers
    const handlePrint = (prehajj) => {
        const printContent = `
            <div>
                <h3>Pre Hajj Details</h3>
                <p><strong>Name:</strong> ${prehajj.umrahName}</p>
                <p><strong>Phone Number:</strong> ${prehajj.mobileNumber}</p>
                <p><strong>Bill Amount:</strong> ${prehajj.billAmount}</p>
                <p><strong>Paid Amount:</strong> ${prehajj.paidAmount}</p>
                <p><strong>Due Amount:</strong> ${prehajj.billAmount - prehajj.paidAmount}</p>
            </div>
        `;
    
        const newWindow = window.open("", "_blank", "width=600,height=400");
        newWindow.document.write(printContent);
        newWindow.document.close();
        newWindow.print();
        console.log("Print triggered for:", prehajj);
    };

    const handlePayment = (prehajj) => {
        console.log("Payment clicked for:", prehajj);
    };
    const handleInfo = (prehajj) => {
        setSelectedPrehajj(prehajj); 
        setOpenInfoDialog(true); 
    };

    const handleCloseInfoDialog = () => {
        setOpenInfoDialog(false); 
    };

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="UMRAH HAJI PAYMENT" subtitle="Managing the Umrah Haji Payment List" />
            </Box>

            <Box mt="20px">
                <div className="table-responsive">
                    <table className="table table-dark">
                        <thead>
                            <tr>
                                <th>S No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Phone No.</th>
                                <th scope="col">Payable Amount</th>
                                <th scope="col">Paid Amount</th>
                                <th scope="col">Due Amount</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {prehajjData.length>0 ? prehajjData.map((prehajj, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{prehajj.umrahName}</td>
                                        <td>{prehajj.mobileNumber}</td>
                                        <td>{prehajj.billAmount}</td>
                                        <td>{prehajj.paidAmount}</td>
                                        <td>{prehajj.billAmount - prehajj.paidAmount}</td>
                                        <td>
                                            <Button onClick={() => handlePrint(prehajj)}><PrintIcon /></Button>
                                            <Button onClick={() => handlePayment(prehajj)}><PaymentsIcon /></Button>
                                            <Button onClick={() => handleInfo(prehajj)}><InfoIcon /></Button>
                                        </td>
                                    </tr>
                                </React.Fragment>
                          )) : (
                            <tr>
                                <td colSpan="9" className="text-center">No Managing the Umrah Haji Payment data available</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </Box>

            {/* Info Modal */}
            <Dialog
                open={openInfoDialog}
                onClose={handleCloseInfoDialog}
                aria-labelledby="info-dialog-title"
                aria-describedby="info-dialog-description"
            >
                <DialogTitle id="info-dialog-title">
                    {selectedPrehajj ? `Details for ${selectedPrehajj.umrahName}` : "Details"}
                </DialogTitle>
                <DialogContent>
                    {selectedPrehajj && (
                        <Box>
                            <Typography variant="body1"><strong>Name:</strong> {selectedPrehajj.umrahName}</Typography>
                            <Typography variant="body1"><strong>Phone Number:</strong> {selectedPrehajj.mobileNumber}</Typography>
                            <Typography variant="body1"><strong>Bill Amount:</strong> {selectedPrehajj.billAmount}</Typography>
                            <Typography variant="body1"><strong>Paid Amount:</strong> {selectedPrehajj.paidAmount}</Typography>
                            <Typography variant="body1"><strong>Due Amount:</strong> {selectedPrehajj.billAmount - selectedPrehajj.paidAmount}</Typography>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseInfoDialog} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Team;
