import { createSlice } from "@reduxjs/toolkit";
import { getCountryDetailsList } from "../apicalls/countrydetails";

const countrySlice = createSlice({
    name: "countrys", 
    initialState: {
        countries: [], 
    },
    reducers: {
        setCountries: (state, action) => {
            state.countries = action.payload; 
        },
        addPackage: (state, action) => {
            state.countries.push(action.payload);
        },
        removePackage: (state, action) => {
            state.countries = state.countries.filter(pkg => pkg._id !== action.payload._id);
        },
        updatePackage: (state, action) => {
            const index = state.countries.findIndex(pkg => pkg._id === action.payload._id); 
            if (index !== -1) {
                state.countries[index] = action.payload; 
            }
        },
    },
});

export const { setCountries, addPackage, removePackage, updatePackage } = countrySlice.actions;

export const fetchCountries = (vendorId) => async (dispatch) => {
    try {
        const response = await getCountryDetailsList(vendorId);
        dispatch(setCountries(response.data)); 
    } catch (error) {
        console.error("Failed to fetch countries:", error); 
    }
};

export default countrySlice.reducer;
