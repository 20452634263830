import { axiosInstance } from "."; // Import the configured Axios instance

// Base URL for API calls
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';

/** 
 * Added by Dilip Yadav 19-10-2024
 * Saves new Embassy details.
 * @param {Object} payload - The Embassy details to be saved.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const saveEmbassyDetails = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-embassy`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error saving Embassy details:", error); 
        throw error; 
    }
}

/** 
 * Added by Dilip Yadav 19-10-2024
 * Fetches the list of Embassy details for a specific user by ID.
 * @param {string} id - The ID of the user.
 * @returns {Promise<Object>} - The Embassy details list.
 */
export const getEmbassyDetailsList = async (id) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-embassy-data/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        console.error("Error fetching Embassy details list:", error); // Log the error
        throw error; // Rethrow the error
    }
}

/**
 * Added by Dilip Yadav 19-10-2024
 * Soft deletes Embassy details by ID.
 * @param {string} id - The ID of the Embassy to delete.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const deleteEmbassyDetails = async (id) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/delete-embassy/${id}`);
        return response.data; 
    } catch (error) {
        console.error("Error deleting Embassy details:", error); 
        throw error; 
    }
};

/**
 * Added by Dilip Yadav 19-10-2024
 * Updates Embassy details by ID.
 * @param {string} id - The ID of the Embassy to update.
 * @param {Object} payload - The updated Embassy details.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const updateEmbassyDetails = async (id, payload) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-embassy/${id}`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error updating Embassy details:", error);
        throw error;
    }
};
