import { axiosInstance } from "."; // Import the configured Axios instance

// Base URL for API calls
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';

/** 
 * Added by Dilip Yadav 14-10-2024
 * Saves new Umrah details.
 * @param {Object} payload - The Umrah details to be saved.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const saveUmrahDetails = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-umrah`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error saving Umrah details:", error); 
        throw error; 
    }
};

/** 
 * Added by Dilip Yadav 14-10-2024
 * Fetches the list of Umrah details for a specific user by ID.
 * @param {string} id - The ID of the user.
 * @returns {Promise<Object>} - The Umrah details list.
 */
 export const getUmrahDetailsList = async (id, typeOf) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-umrah-data/${id}/${typeOf}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Umrah details list:", error);
        throw error;
    }
};

/**
 * Added by Dilip Yadav 14-10-2024
 * Soft deletes Umrah details by ID.
 * @param {string} id - The ID of the Umrah to delete.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const deleteUmrahDetails = async (id) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/delete-umrah/${id}`);
        return response.data; 
    } catch (error) {
        console.error("Error deleting Umrah details:", error); 
        throw error; 
    }
};

/**
 * Added by Dilip Yadav 14-10-2024
 * Updates Umrah details by ID.
 * @param {string} id - The ID of the Umrah to update.
 * @param {Object} payload - The updated Umrah details.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const updateUmrahDetails = async (id, payload) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-umrah/${id}`, payload);
        return response.data; 
    } catch (error) {
        console.error("Error updating Umrah details:", error);
        throw error;
    }
};
