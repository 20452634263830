import { Box, Button, MenuItem, Typography, useTheme,IconButton } from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../../components/admincomponents/Header";
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect } from "react";
import { feachUserList, deleteUser, updateUser } from "../../../apicalls/users";
import { message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { fetchSubscriptions } from "../../../redux/subscriptionSlice"; 

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const [userData, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { subscriptions } = useSelector((state) => state.subscriptions || []);

  useEffect(() => {
    dispatch(fetchSubscriptions());
    fetchUserList();
  }, [dispatch]);

  const fetchUserList = async () => {
    setLoading(true);
    try {
      const response = await feachUserList();
      if (response.success) {
        setUser(response.data);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error("Failed to fetch user data");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewDetails = (user) => {
    setSelectedUser(user);
    setShowViewModal(true);
  };

  const closeViewModal = () => {
    setShowViewModal(false);
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
       <Header title="REPORT" subtitle="Report List" />
        <Box>
        <IconButton>
                        <Typography
                            variant="h6"
                            fontWeight="400"
                            color={colors.grey[100]}
                        >
                            Week
                        </Typography>
                    </IconButton>
                    <IconButton>
                        {/* <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                /> */}
                        <Typography
                            variant="h6"
                            fontWeight="400"
                            color={colors.grey[100]}
                        >
                            Month
                        </Typography>
                    </IconButton>
        </Box>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .name-column--cell": { color: colors.greenAccent[300] },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <div className="table-responsive">
          <table className="table accordion table-dark">
            <thead>
              <tr>
                <th scope="col">S. No.</th>
                <th scope="col">Date</th>
                <th scope="col">Company Name</th>
                <th scope="col">Name</th>
                <th scope="col">Subscription</th>
                <th scope="col">Package Amount</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {userData.map((user, index) => (
                <tr key={user.id || index}>
                  <th scope="row">{index + 1}</th>
                  <td>{user.name}</td>
                  {/* <td>{user.companyName}</td> */}
                  <td>{user.email}</td>
                  <td>{user.email}</td>
                  <td>{user.subscriptionPackage}</td>
                  <td>{user.subscriptionPackage}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => handleViewDetails(user)}
                    >
                      <RemoveRedEyeIcon />
                    </button>
                    &nbsp;
                  
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* View Details Modal */}
        {showViewModal && (
          <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">User Details</h5>
                  <button type="button" className="btn-close" onClick={closeViewModal} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  {selectedUser && (
                    <>
                      <p>Company Name: {selectedUser.name}</p>
                      <p>Email: {selectedUser.email}</p>
                      <p>Subscription Package: {selectedUser.subscriptionPackage}</p>
                    </>
                  )}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={closeViewModal}>Close</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Edit User Modal */}
       

      </Box>
    </Box>
  );
};

export default Team;
