import { axiosInstance } from ".";

/*** 
 * Added by Dilip Yadav
 * Used for saving new country details 
 * Date: 10-08-2024
 */
 //const BASE_URL = 'http://localhost:8000/api/users';
 const BASE_URL = 'http://65.1.94.207:8000/api/users';
export const saveCountryDetails = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-countrydetails`, payload);
        return response.data;
    } catch (error) {
        console.error("Error saving country details:", error); // Updated message
        throw error; 
    }
}

/** 
 * Added by Dilip Yadav
 * Used for fetching the list of country details 
 * Date: 10-08-2024
 */
export const getCountryDetailsList = async (id) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-country-data/${id}`); // Fixed extra slash
        return response.data;
    } catch (error) {
        console.error("Error fetching country details list:", error); // Updated message
        throw error; 
    }
}

/**
 * Added by Dilip Yadav 
 * Used for soft deleting country details
 * @param {string} id 
 * @returns {Promise<Object>}
 */

export const deleteCountryDetails = async (id) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/delete-countrydetails/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting country details:", error); // Updated message
        throw error; 
    }
};

/**
 * Added by Dilip Yadav 
 * Used for updating country details
 * @param {string} id 
 * @param {Object} payload 
 * @returns {Promise<Object>}
 */
export const updateCountryDetails = async (id, payload) => { // Fixed function name
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-countrydetails/${id}`, payload); // Corrected the endpoint
        return response.data;
    } catch (error) {
        console.error("Error updating country details:", error); // Updated message
        throw error; 
    }
};
