import React from 'react';
import { Link } from "react-router-dom";
import Button from "../../components/Button";

const LandingPage = () => {
  return (
    <div className="landing-page">
      {/* Navbar */}
      <header className="navbar navbar-expand-lg navbar-light" style={{ backgroundColor: '#e7f4e7' }}>
        <div className="container">
          <Link className="navbar-brand fs-3 fw-bold text-success" to="/">Airbook</Link>
          <div className="d-flex ms-auto">
            <Link to="/login" className="btn btn-outline-success me-2">Login</Link>
            <Link to="/signup" className="btn btn-success">Signup</Link>
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <section className="hero-section text-center text-dark py-5" style={{ backgroundColor: '#e7f4e7', minHeight: '60vh' }}>
        <div className="container">
          <h1 className="display-4 fw-bold">Welcome to Airbook</h1>
          <p className="lead">Your gateway to personalized travel experiences</p>
          <Link to="/login" className="btn btn-outline-success me-2"  style={{ backgroundColor: '#28a745', color: 'white' }}>Get Started</Link>
        </div>
      </section>
      {/* Features Section */}
      <section className="features-section text-center py-5">
        <div className="container">
          <h2 className="fw-bold mb-4 text-success">Explore Our Features</h2>
          <p className="text-muted mb-5">Discover the unique benefits Airbook offers to make your travel memorable and efficient.</p>
          <div className="row">
            <div className="col-md-4 mb-4">
              <h4 className="fw-semibold">Feature One</h4>
              <p>Brief description of feature one that captures user interest.</p>
            </div>
            <div className="col-md-4 mb-4">
              <h4 className="fw-semibold">Feature Two</h4>
              <p>Explanation of feature two with an emphasis on benefits.</p>
            </div>
            <div className="col-md-4 mb-4">
              <h4 className="fw-semibold">Feature Three</h4>
              <p>Insight into feature three to highlight the app's uniqueness.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="text-center py-4" style={{ backgroundColor: '#e7f4e7' }}>
        <div className="container">
          <p className="text-muted">&copy; 2024 Airbook. All rights reserved.</p>
          <div className="d-flex justify-content-center">
            <Link to="#" className="text-muted mx-2">Privacy Policy</Link>
            <Link to="#" className="text-muted mx-2">Terms of Service</Link>
            <Link to="#" className="text-muted mx-2">Contact Us</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
