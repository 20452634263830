import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { message } from "antd";
import { saveUmrahDetails } from "../../apicalls/umrahDetails"; 
import { useSelector, useDispatch } from "react-redux";
import { fetchPackages } from "../../redux/packegSlice"; 
import { fetchGroups } from "../../redux/groupSlice"; 
import { fetchAgents } from "../../redux/agentsSlice"; 

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { user } = useSelector((state) => state.users);
  const vendorId = user ? user._id : ""; 

  const { packages } = useSelector((state) => state.packages  || []);
  const { groups } = useSelector((state) => state.groups  || []);
  const { agents } = useSelector((state) => state.agents || [] ); 
  const dispatch = useDispatch();
  const typeOf = 'Hajj'; 
  useEffect(() => {
      if (vendorId) {
          dispatch(fetchPackages(vendorId, typeOf));
          dispatch(fetchGroups(vendorId, typeOf));
          dispatch(fetchAgents(vendorId));
      }
  }, [dispatch, vendorId, typeOf]); 

  const phoneRegExp = /^[0-9]{10}$/; 
  const amountRegExp = /^[0-9]+(\.[0-9]{1,2})?$/; 

  const checkoutSchema = yup.object().shape({
    umrahName: yup
      .string()
      .required("Umrah name is required")
      .min(2, "Umrah name must be at least 2 characters")
      .max(50, "Umrah name must be at most 50 characters"),
    fatherName: yup
      .string()
      .required("Father name is required")
      .min(2, "Father name must be at least 2 characters")
      .max(50, "Father name must be at most 50 characters"),
    motherName: yup
      .string()
      .required("Mother name is required")
      .min(2, "Mother name must be at least 2 characters")
      .max(50, "Mother name must be at most 50 characters"),
    paymentDate: yup
      .date()
      .required("Payment date is required")
      .nullable(),
    dateOfBirth: yup
      .date()
      .required("Date of birth is required")
      .nullable(),
    registrationDate: yup
      .date()
      .required("Registration date is required")
      .nullable(),
    packegeId: yup.string().required("Package is required"),
    groupId: yup.string().required("Group type is required"),
    mobileNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid (must be 10 digits)")
      .required("Mobile Number is required"),
    paidAmount: yup
      .string()
      .matches(amountRegExp, "Paid amount is not valid")
      .required("Paid amount is required"),
   
    billAmount: yup
      .string()
      .matches(amountRegExp, "Bill amount is not valid")
      .required("Bill amount is required"),
    passportNumber: yup
      .string()
      .required("Passport number is required")
      .min(8, "Passport number must be at least 8 characters")
      .max(20, "Passport number must be at most 20 characters"),
  });

  const initialValues = {
    umrahName: "",
    paymentDate: null,
    dateOfBirth: null,
    registrationDate: null,
    fatherName: "",
    passportNumber: "",
    paidAmount: "",
    motherName: "",
    packegeId: "", 
    groupId: "", 
    typeOf: "Hajj", 
    mobileNumber: "",
    vendorId: vendorId,
    isActive: "Active",
  };

  const handleFormSubmit = async (values, { resetForm, setSubmitting }) => {
    const formData = new FormData();
    Object.keys(values).forEach(key => {
      const value = values[key] instanceof Date ? values[key].toISOString() : values[key];
      formData.append(key, value);
    });

    try {
      await saveUmrahDetails(formData);
      message.success("Umrah details saved successfully!");
      resetForm();
    } catch (err) { 
      console.error("Error saving umrah details:", err); 
      message.error("Error saving umrah details. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box m="20px">
      <Header title="HAJJ PRE-REGISTER" subtitle="Create a New Hajj Pre-Register " />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                label="Hajj Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.umrahName}
                name="umrahName"
                error={!!touched.umrahName && !!errors.umrahName}
                helperText={touched.umrahName && errors.umrahName}
                sx={{ gridColumn: "span 2" }}
              />
              <Box sx={{ gridColumn: "span 2", display: 'flex', gap: '20px' }}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Payment Date"
                  InputLabelProps={{ shrink: true }} 
                  onBlur={handleBlur}
                  onChange={(e) => {
                    const dateValue = new Date(e.target.value);
                    setFieldValue('paymentDate', dateValue);
                  }}
                  value={values.paymentDate ? values.paymentDate.toISOString().split('T')[0] : ''}
                  name="paymentDate"
                  error={!!touched.paymentDate && !!errors.paymentDate}
                  helperText={touched.paymentDate && errors.paymentDate}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Pre Registration Date"
                  InputLabelProps={{ shrink: true }} 
                  onBlur={handleBlur}
                  onChange={(e) => {
                    const dateValue = new Date(e.target.value);
                    setFieldValue('registrationDate', dateValue);
                  }}
                  value={values.registrationDate ? values.registrationDate.toISOString().split('T')[0] : ''}
                  name="registrationDate"
                  error={!!touched.registrationDate && !!errors.registrationDate}
                  helperText={touched.registrationDate && errors.registrationDate}
                />

               <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Date of Birth"
                  InputLabelProps={{ shrink: true }} 
                  onBlur={handleBlur}
                  onChange={(e) => {
                    const dateValue = new Date(e.target.value);
                    setFieldValue('dateOfBirth', dateValue);
                  }}
                  value={values.dateOfBirth ? values.dateOfBirth.toISOString().split('T')[0] : ''}
                  name="dateOfBirth"
                  error={!!touched.dateOfBirth && !!errors.dateOfBirth}
                  helperText={touched.dateOfBirth && errors.dateOfBirth}
                />
              </Box>
              <FormControl fullWidth variant="filled" error={!!touched.Agent_id && !!errors.Agent_id} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="assign-user-label">Assign Agent</InputLabel>
                <Select
                  labelId="assign-user-label"
                  value={values.Agent_id}
                  onChange={handleChange}
                  name="Agent_id"
                  onBlur={handleBlur}
                >
                   {Array.isArray(agents) && agents.length > 0 ? (
                          agents.map(agent => (
                              <MenuItem key={agent._id} value={agent._id}>
                                  {agent.AgentName}
                              </MenuItem>
                          ))
                      ) : (
                          <MenuItem disabled>No agents available</MenuItem>
                      )}
                </Select>
                {touched.Agent_id && errors.Agent_id && (
                  <FormHelperText>{errors.Agent_id}</FormHelperText>
                )}
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                label="Father Name"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fatherName}
                name="fatherName"
                error={!!touched.fatherName && !!errors.fatherName}
                helperText={touched.fatherName && errors.fatherName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Mother Name"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.motherName}
                name="motherName"
                error={!!touched.motherName && !!errors.motherName}
                helperText={touched.motherName && errors.motherName}
                sx={{ gridColumn: "span 2" }}
              />
             <FormControl
                fullWidth
                variant="filled"
                error={!!touched.groupId && !!errors.groupId}
                sx={{ gridColumn: "span 2" }}
              >
                <InputLabel id="groupId-label">Group Type</InputLabel>
                <Select
                  labelId="groupId-label"
                  value={values.groupId}
                  onChange={handleChange}
                  name="groupId"
                  onBlur={handleBlur}
                >
                    {Array.isArray(groups) && groups.length > 0 ? (
                        groups.map(group => (
                            <MenuItem key={group._id} value={group._id}>
                                  {group.GroupName} 
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>No group available</MenuItem>
                    )}
                </Select>
                {touched.groupId && errors.groupId && (
                  <FormHelperText>{errors.groupId}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                fullWidth
                variant="filled"
                error={!!touched.packegeId && !!errors.packegeId}
                sx={{ gridColumn: "span 2" }}
              >
                <InputLabel id="package-label">Type of Package</InputLabel>
                <Select
                  labelId="package-label"
                  value={values.packegeId}
                  onChange={handleChange}
                  name="packegeId"
                  onBlur={handleBlur}
                >
                   {Array.isArray(packages) && packages.length > 0 ? (
                        packages.map(packege => (
                            <MenuItem key={packege._id} value={packege._id}>
                                 {packege.PackageName} 
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>No packege available</MenuItem>
                    )}
                </Select>
                {touched.packegeId && errors.packegeId && (
                  <FormHelperText>{errors.packegeId}</FormHelperText>
                )}
              </FormControl>

         

              <TextField
                fullWidth
                variant="filled"
                label="Mobile Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.mobileNumber}
                name="mobileNumber"
                error={!!touched.mobileNumber && !!errors.mobileNumber}
                helperText={touched.mobileNumber && errors.mobileNumber}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Passport Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.passportNumber}
                name="passportNumber"
                error={!!touched.passportNumber && !!errors.passportNumber}
                helperText={touched.passportNumber && errors.passportNumber}
                sx={{ gridColumn: "span 2" }}
              />


              <TextField
                fullWidth
                variant="filled"
                label="Paid Amount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.paidAmount}
                name="paidAmount"
                error={!!touched.paidAmount && !!errors.paidAmount}
                helperText={touched.paidAmount && errors.paidAmount}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Payable Amount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.billAmount}
                name="billAmount"
                error={!!touched.billAmount && !!errors.billAmount}
                helperText={touched.billAmount && errors.billAmount}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Remark"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.remark}
                name="remark"
                error={!!touched.remark && !!errors.remark}
                helperText={touched.remark && errors.remark}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>

            

            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create New Hajj Pre-Register
              </Button>
              &nbsp;&nbsp;
              <Button type="button" color="primary" variant="contained" onClick={resetForm}>
                Reset
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
