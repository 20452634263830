import { Box, Button, TextField, Select, MenuItem, InputLabel, FormControl, FormHelperText } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { saveVisaDetails } from "../../apicalls/visaDetails"; 
import { useSelector,useDispatch } from "react-redux";
import { message } from "antd";
import { fetchCountries } from "../../redux/countrySlice"; 
import { fetchAgents } from "../../redux/agentsSlice"; 
import { useEffect } from "react";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { user } = useSelector((state) => state.users); 
  const vendorId = user ? user._id : ""; 
  const agents = useSelector((state) => state.agents.agents || []);
  const countries = useSelector((state) => state.countries.countries || []);
  const dispatch = useDispatch();
  useEffect(() => {
    if (vendorId) {
      dispatch(fetchAgents(vendorId));
      dispatch(fetchCountries(vendorId));
    }
   
  }, [dispatch, vendorId]);
  const handleFormSubmit = (values, { resetForm }) => {
    saveVisaDetails(values)
      .then(response => {
        message.success("Visa details saved successfully!");
        resetForm();
      })
      .catch(error => {
        message.error(`Error saving visa details: ${error.message || error}`);
      });
  };
  
  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const checkoutSchema = yup.object().shape({
    fullName: yup.string().required("Full Name is required"),
    reference: yup.string().required("Reference is required"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required"),
    agent_id: yup.string().required("Agent is required"),
    fatherName: yup.string().required("Father's Name is required"),
    motherName: yup.string().required("Mother's Name is required"),
    dateOfBirth: yup.date().required("Date of Birth is required"),
    passportNumber: yup.string().required("Passport Number is required"),
    issueDate: yup.date().required("Issue Date is required"),
    expiredDate: yup.date().required("Expired Date is required"),
    visaType: yup.string().required("Visa Type is required"),
    visaFee: yup.number().required("Visa Fee is required").min(0),
    serviceCharge: yup.number().required("Service Charge is required").min(0),
    country_id: yup.string().required("Country is required"),
    paidAmount: yup.number().required("Paid amount is required").min(0),
    dueAmount: yup.number().required("Due amount is required").min(0),
    remarks: yup.string(),
  });

  const initialValues = {
    fullName: "",
    dueAmount: "",
    paidAmount: "",
    reference: "",
    phoneNumber: "",
    agent_id: "",
    fatherName: "",
    motherName: "",
    dateOfBirth: "",
    passportNumber: "",
    issueDate: "",
    expiredDate: "",
    visaType: "",
    visaFee: "",
    serviceCharge: "",
    country_id: "",
    remarks: "",
    vendor_id: vendorId,
    isActive: "Active", 
  };

  return (
    <Box m="20px">
      <Header title="VISA MANAGEMENT" subtitle="Create Visa Management" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {/* Agent (Dropdown) */}
              <FormControl fullWidth variant="filled" error={!!touched.agent_id && !!errors.agent_id} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="agent-purpose-label">Agent</InputLabel>
                <Select
                  labelId="agent-purpose-label"
                  value={values.agent_id}
                  onChange={handleChange}
                  name="agent_id"
                  onBlur={handleBlur}
                >
                  {Array.isArray(agents) && agents.length > 0 ? (
                                agents.map(agent => (
                                    <MenuItem key={agent._id} value={agent._id}>
                                        {agent.AgentName}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem disabled>No agents available</MenuItem>
                            )}
                </Select>
                {touched.agent_id && errors.agent_id && (
                  <FormHelperText>{errors.agent_id}</FormHelperText>
                )}
              </FormControl>

              {/* Full Name */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Full Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fullName}
                name="fullName"
                error={!!touched.fullName && !!errors.fullName}
                helperText={touched.fullName && errors.fullName}
                sx={{ gridColumn: "span 2" }}
              />

              {/* Reference */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Reference"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.reference}
                name="reference"
                error={!!touched.reference && !!errors.reference}
                helperText={touched.reference && errors.reference}
                sx={{ gridColumn: "span 2" }}
              />

              {/* Phone Number */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phoneNumber}
                name="phoneNumber"
                error={!!touched.phoneNumber && !!errors.phoneNumber}
                helperText={touched.phoneNumber && errors.phoneNumber}
                sx={{ gridColumn: "span 2" }}
              />

              {/* Father's Name */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Father's Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fatherName}
                name="fatherName"
                error={!!touched.fatherName && !!errors.fatherName}
                helperText={touched.fatherName && errors.fatherName}
                sx={{ gridColumn: "span 2" }}
              />

              {/* Mother's Name */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Mother's Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.motherName}
                name="motherName"
                error={!!touched.motherName && !!errors.motherName}
                helperText={touched.motherName && errors.motherName}
                sx={{ gridColumn: "span 2" }}
              />

              {/* Date of Birth */}
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="Date of Birth"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.dateOfBirth}
                name="dateOfBirth"
                error={!!touched.dateOfBirth && !!errors.dateOfBirth}
                helperText={touched.dateOfBirth && errors.dateOfBirth}
                sx={{ gridColumn: "span 2" }}
              />

              {/* Passport Number */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Passport Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.passportNumber}
                name="passportNumber"
                error={!!touched.passportNumber && !!errors.passportNumber}
                helperText={touched.passportNumber && errors.passportNumber}
                sx={{ gridColumn: "span 2" }}
              />

              {/* Issue Date */}
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="Issue Date"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.issueDate}
                name="issueDate"
                error={!!touched.issueDate && !!errors.issueDate}
                helperText={touched.issueDate && errors.issueDate}
                sx={{ gridColumn: "span 2" }}
              />

              {/* Expired Date */}
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="Expired Date"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.expiredDate}
                name="expiredDate"
                error={!!touched.expiredDate && !!errors.expiredDate}
                helperText={touched.expiredDate && errors.expiredDate}
                sx={{ gridColumn: "span 2" }}
              />

              {/* Visa Type */}
              <FormControl fullWidth variant="filled" error={!!touched.visaType && !!errors.visaType} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="visa-purpose-label">Visa Type</InputLabel>
                <Select
                  labelId="visa-purpose-label"
                  value={values.visaType}
                  onChange={handleChange}
                  name="visaType"
                  onBlur={handleBlur}
                >
                  <MenuItem value="tourist">Tourist Visa</MenuItem>
                  <MenuItem value="student">Student Visa</MenuItem>
                  <MenuItem value="work">Work Visa</MenuItem>
                  <MenuItem value="business">Business Visa</MenuItem>
                  <MenuItem value="transit">Transit Visa</MenuItem>
                </Select>
                {touched.visaType && errors.visaType && (
                  <FormHelperText>{errors.visaType}</FormHelperText>
                )}
              </FormControl>

              {/* Visa Fee */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Visa Fee"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.visaFee}
                name="visaFee"
                error={!!touched.visaFee && !!errors.visaFee}
                helperText={touched.visaFee && errors.visaFee}
                sx={{ gridColumn: "span 2" }}
              />

              {/* Service Charge */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Service Charge"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.serviceCharge}
                name="serviceCharge"
                error={!!touched.serviceCharge && !!errors.serviceCharge}
                helperText={touched.serviceCharge && errors.serviceCharge}
                sx={{ gridColumn: "span 2" }}
              />

               {/* Service Charge */}
               <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Due Amount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.dueAmount}
                name="dueAmount"
                error={!!touched.dueAmount && !!errors.dueAmount}
                helperText={touched.dueAmount && errors.dueAmount}
                sx={{ gridColumn: "span 2" }}
              />

               {/* Service Charge */}
               <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Paid Amount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.paidAmount}
                name="paidAmount"
                error={!!touched.paidAmount && !!errors.paidAmount}
                helperText={touched.paidAmount && errors.paidAmount}
                sx={{ gridColumn: "span 2" }}
              />

              {/* Address */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address}
                name="address"
                error={!!touched.address && !!errors.address}
                helperText={touched.address && errors.address}
                sx={{ gridColumn: "span 2" }}
              />

              {/* Country */}
              <FormControl fullWidth variant="filled" error={!!touched.country_id && !!errors.country_id} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="country-purpose-label">Country</InputLabel>
                <Select
                  labelId="country_id-purpose-label"
                  value={values.country_id}
                  onChange={handleChange}
                  name="country_id"
                  onBlur={handleBlur}
                >
               
                  {Array.isArray(countries) && countries.length > 0 ? (
                            countries.map(country => (
                                <MenuItem key={country._id} value={country._id}>
                                    {country.CountryName}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem disabled>No countries available</MenuItem>
                        )}
                </Select>
                {touched.country_id && errors.country_id && (
                  <FormHelperText>{errors.country_id}</FormHelperText>
                )}
              </FormControl>

              {/* Remarks */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Remarks"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.remarks}
                name="remarks"
                error={!!touched.remarks && !!errors.remarks}
                helperText={touched.remarks && errors.remarks}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Submit
              </Button>&nbsp;&nbsp;
              <Button type="button" color="primary" variant="contained" onClick={resetForm}>
                Reset
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
