import axios from 'axios'

export const axiosInstance = axios.create({
    headers : {
        credentials : 'include', 
        method : 'post',
        'Content-Type' : 'application/json',
         authorization : `Bearer ${localStorage.getItem('token')} `
}
})

export const saveAgent = async (formData) => {
    return await axios.post('/api/save-agent', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
