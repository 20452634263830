import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/admincomponents/Header";
import { Subscription } from '../../../apicalls/subscription';
import { message } from 'antd'; 
const SubscriptionForm = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      const response = await Subscription(values);
      if (response.success) {
        message.success(response.message);
      } else {
        message.error(response.message); 
      }
    } catch (error) {
      message.error("An error occurred. Please try again.");
      console.log(error);
    }
    resetForm(); 
  };

  return (
    <Box m="20px">
      <Header title="CREATE SUBSCRIPTION" subtitle="Create a New Subscription" />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={subscriptionSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Subscription Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Subtitle"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.subtitle}
                name="subtitle"
                error={!!touched.subtitle && !!errors.subtitle}
                helperText={touched.subtitle && errors.subtitle}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Price"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.price}
                name="price"
                error={!!touched.price && !!errors.price}
                helperText={touched.price && errors.price}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Submit
              </Button>&nbsp;&nbsp;
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={() => resetForm()} 
              >
                Reset
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

// Validation schema for the form using Yup
const subscriptionSchema = yup.object().shape({
  name: yup.string().required("Subscription name is required"),
  subtitle: yup.string().required("Subtitle is required"),
  price: yup.number().positive("Price must be a positive number").required("Price is required"),
  description: yup.string().required("Description is required"),
});

// Initial values

// Initial values for the form
const initialValues = {
  name: "",
  subtitle: "",
  price: "",
  description: "",
};

export default SubscriptionForm;
