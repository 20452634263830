import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { saveCountryDetails } from "../../apicalls/countrydetails";
import { message } from "antd";
import { useSelector } from "react-redux";

const Form = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const { user } = useSelector((state) => state.users); 
    const vendorId = user ? user._id : ""; 

    const checkoutSchema = yup.object().shape({
        CountryName: yup.string().required("Country Name is required"),
        CountryCode: yup.string().required("Country Code is required"),
        Description: yup.string().required("Description is required"),
    });

    const initialValues = {
        CountryName: "",
        Description: "", 
        CountryCode: "", 
        vendor_id: vendorId,
        status: "active", 
    };

    const handleFormSubmit = async (values, { resetForm, setSubmitting }) => {
        const formData = new FormData();
        Object.keys(values).forEach(key => {
            formData.append(key, values[key]);
        });

        try {
            await saveCountryDetails(formData); 
            message.success("Country details saved successfully!");
            resetForm(); 
        } catch (error) {
            message.error("Error saving country details. Please try again.");
        } finally {
            setSubmitting(false); 
        }
    };

    return (
        <Box m="20px">
            <Header title="ADD COUNTRY" subtitle="Add a New Country" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting, 
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Country Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.CountryName}
                                name="CountryName"
                                error={touched.CountryName && !!errors.CountryName}
                                helperText={touched.CountryName && errors.CountryName}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Country Code"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.CountryCode}
                                name="CountryCode"
                                error={touched.CountryCode && !!errors.CountryCode}
                                helperText={touched.CountryCode && errors.CountryCode}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Description"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.Description}
                                name="Description"
                                error={touched.Description && !!errors.Description}
                                helperText={touched.Description && errors.Description}
                                sx={{ gridColumn: "span 4" }}
                            />
                        </Box>

                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained" disabled={isSubmitting}>
                                Submit
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default Form;
