import React, { useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { message } from "antd";
import { saveUmrahGroupDetails } from "../../apicalls/umrahGroupgedetails"; 
import { useSelector, useDispatch } from "react-redux";
import { fetchPackages } from "../../redux/packegSlice"; 

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { user } = useSelector((state) => state.users);
  const vendorId = user ? user._id : ""; 
  const { packages } = useSelector((state) => state.packages  || []);
  const dispatch = useDispatch();
  const typeOf = 'Hajj'; 
  useEffect(() => {
      if (vendorId) {
          dispatch(fetchPackages(vendorId, typeOf));
      }
  }, [dispatch, vendorId, typeOf]); 
  

  const checkoutSchema = yup.object().shape({
    GroupName: yup.string().required("Group name is required"),
    FlightDate: yup.date().required("Flight date is required").nullable()
      .test("is-greater", "Flight date must be before return date", function (value) {
        const { ReturnDate } = this.parent;
        return value && ReturnDate ? value <= ReturnDate : true;
      }),
    ReturnDate: yup.date().required("Return date is required").nullable(),
    NumberOfAssign: yup.number().positive("Amount must be a positive number").required("Package amount is required"),
    Packege_id: yup.string().required("Package purpose is required"),
    AirlinesNumber: yup.string().required("Airlines Number is required"),
    HotelName: yup.string().required("Hotel Name is required"),
  });

  const initialValues = {
    GroupName: "",
    FlightDate: null,
    NumberOfAssign: "",
    Packege_id: "", 
    TypeOfGroup: "Hajj", 
    AirlinesNumber: "",
    vendor_id: vendorId,
    isActive: "Active",
    HotelName: "", 
  };

  const handleFormSubmit = async (values, { resetForm, setSubmitting }) => {
    const formData = new FormData();
    Object.keys(values).forEach(key => {
      formData.append(key, values[key]);
    });

    try {
      await saveUmrahGroupDetails(formData);
      message.success("Package details saved successfully!");
      resetForm();
    } catch (err) { 
      console.error("Error saving package details:", err); 
      message.error("Error saving package details. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box m="20px">
      <Header title="CREATE HAJJ GROUP" subtitle="Create a New HAJJ Group" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                label="Group Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.GroupName}
                name="GroupName"
                error={!!touched.GroupName && !!errors.GroupName}
                helperText={touched.GroupName && errors.GroupName}
                sx={{ gridColumn: "span 2" }}
              />

              <Box sx={{ gridColumn: "span 2", display: 'flex', gap: '20px' }}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Flight Date"
                  InputLabelProps={{ shrink: true }} 
                  onBlur={handleBlur}
                  onChange={(e) => {
                    const dateValue = new Date(e.target.value);
                    setFieldValue('FlightDate', dateValue);
                  }}
                  value={values.FlightDate ? values.FlightDate.toISOString().split('T')[0] : ''}
                  name="FlightDate"
                  error={!!touched.FlightDate && !!errors.FlightDate}
                  helperText={touched.FlightDate && errors.FlightDate}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Return Date"
                  InputLabelProps={{ shrink: true }} 
                  onBlur={handleBlur}
                  onChange={(e) => {
                    const dateValue = new Date(e.target.value);
                    setFieldValue('ReturnDate', dateValue);
                  }}
                  value={values.ReturnDate ? values.ReturnDate.toISOString().split('T')[0] : ''}
                  name="ReturnDate"
                  error={!!touched.ReturnDate && !!errors.ReturnDate}
                  helperText={touched.ReturnDate && errors.ReturnDate}
                />
              </Box>

              <TextField
                fullWidth
                variant="filled"
                label="No. of Assign Main Haji"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.NumberOfAssign}
                name="NumberOfAssign"
                error={!!touched.NumberOfAssign && !!errors.NumberOfAssign}
                helperText={touched.NumberOfAssign && errors.NumberOfAssign}
                sx={{ gridColumn: "span 2" }}
              />

              <FormControl fullWidth variant="filled" error={!!touched.Packege_id && !!errors.Packege_id} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="package-label">Type of Package</InputLabel>
                <Select
                  labelId="package-label"
                  value={values.Packege_id}
                  onChange={handleChange}
                  name="Packege_id"
                  onBlur={handleBlur}
                >
                 {Array.isArray(packages) && packages.length > 0 ? (
                            packages.map(packege => (
                                <MenuItem key={packege._id} value={packege._id}>
                                     {packege.PackageName} 
                                </MenuItem>
                          ))
                      ) : (
                          <MenuItem disabled>No packege available</MenuItem>
                      )}
                </Select>
                {touched.Packege_id && errors.Packege_id && (
                  <FormHelperText>{errors.Packege_id}</FormHelperText>
                )}
              </FormControl>

              <TextField
                fullWidth
                variant="filled"
                label="Airlines Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.AirlinesNumber}
                name="AirlinesNumber"
                error={!!touched.AirlinesNumber && !!errors.AirlinesNumber}
                helperText={touched.AirlinesNumber && errors.AirlinesNumber}
                multiline
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Hotel Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.HotelName}
                name="HotelName"
                error={!!touched.HotelName && !!errors.HotelName}
                helperText={touched.HotelName && errors.HotelName}
                multiline
                sx={{ gridColumn: "span 2" }}
              />
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create New Group
              </Button>
              &nbsp;&nbsp;
              <Button type="button" color="primary" variant="contained" onClick={resetForm}>
                Reset
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
